import 'react-perfect-scrollbar/dist/css/styles.css';
import React, { useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import 'src/mixins/chartjs';
import theme from 'src/theme';
import routes from 'src/routes';
import * as sessions from 'src/utils/sessions';

const App = () => {
  const [initialized, setInitialized] = React.useState(false);
  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(()=> {
    if (!initialized) {
      setInitialized(true);
      sessions.refreshToken();
      sessions.getEmployee(true);
      sessions.getUser(true);
      sessions.redirectLogin();
      sessions.getConfiguration(true);
      sessions.getConfigurationTS(true);
    }
  })
  /* eslint-enable react-hooks/exhaustive-deps*/

  const routing = useRoutes(routes);

  window.getCookie = name => {
    const value = `; ${window.document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2)
      return parts
        .pop()
        .split(';')
        .shift();
  };

  window.setCookie = (name, value, days) => {
    var expires = '';
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toUTCString();
    }
    window.document.cookie = name + '=' + (value || '') + expires + '; path=/';
  };

  if (
    !window.localStorage.getItem('directus_data') &&
    !window.location.pathname.startsWith('/sign-in') &&
    !window.location.pathname.startsWith('/passwordreset')
    // !window.location.pathname.startsWith('/sign-up')
  ) {
    window.location.href = `//${window.location.hostname}:${window.location.port}/sign-in`;
    return;
  } else {
    sessions.getEmployee(true);
    sessions.getUser(true);
    sessions.getConfiguration(true);
  }

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      {routing}
    </ThemeProvider>
  );
};

export default App;
