import { withStyles, Button, makeStyles, Tooltip, Grid, Select, TextField } from '@material-ui/core';

export const TextFieldCustom = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
      fontFamily: "Manrope",
    },
  },
})(TextField);

export const RejectButton = withStyles({
  root: {
    borderRadius: '8px',
    backgroundColor: '#FF5F58',
    color: '#ffffff',
    height: '25px',
    width: '50px',
    minWidth: '50px',
    '&:hover': {
      backgroundColor: '#c34c46',
    },
  },
})(Button);

export const ApproveButton = withStyles({
  root: {
    marginRight: '10px',
    borderRadius: '8px',
    backgroundColor: '#2AC940',
    color: '#ffffff',
    height: '25px',
    width: '50px',
    minWidth: '50px',
    '&:hover': {
      backgroundColor: '#22a033',
    },
  },
})(Button);

export const UnapproveButton = withStyles({
  root: {
    borderRadius: '8px',
    backgroundColor: '#2AC940',
    color: '#ffffff',
    height: '25px',
    width: '150px',
    minWidth: '150px',
    '&:hover': {
      backgroundColor: '#22a033',
    },
  },
})(Button);

export const CancelButtonDialog = withStyles({
  root: {
    borderRadius: '8px',
    backgroundColor: '#e0e0e0de',
    height: '30px',
    width: '120px',
    '&:hover': {
      backgroundColor: '#bdbdbdde',
    },
  },
})(Button);

export const RejectButtonDialog = withStyles({
  root: {
    borderRadius: '8px',
    backgroundColor: '#FF5F58',
    color: '#ffffff',
    height: '30px',
    width: '120px',
    '&:hover': {
      backgroundColor: '#c34c46',
    },
  },
})(Button);

export const CustomTooltip = withStyles({
  tooltip: {
    fontSize: "16px",
    maxWidth: 500,
    backgroundColor: "grey"
  }
})(Tooltip);

export const CustomGridApproval = withStyles(() => ({
  root: {
    height:'35px',
    paddingTop:'10px',
    marginBottom:'10px'
  },
}))(Grid);


export const CustomSelect = withStyles(() => ({
  root: {
    height:'35px',
    width:'200px',
    borderRadius:'8px'
  },
}))(Select);



export const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: '20px',
    paddingTop: '20px',  
  },
  circular: {
    color: '#FA9917',
    float:'right'
  },
  circularMain: {
    color: '#FA9917',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  circularLoading: {
    color: '#FA9917',
  },
  filter: {
    float: 'right',
  },
  card: {
    borderRadius: '8px',
    backgroundColor: '#f2f2f6',
  },
  cardContent: {
    paddingTop: '0px',
    paddingBottom: '0px !important'
  },
  position: {
    display:'inline-block',
    backgroundColor:'white',
    height:'30px',
    paddingTop: '3px',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  employees: {
    maxHeight: 30, 
    minHeight: 10, 
    paddingTop:'10px', 
    fontFamily: 'Manrope', 
    fontWeight: 'bold'
  },
}));

export const useStylesList = makeStyles({
  details: {
    display: 'block'
  },
  card: {
    borderRadius: '8px',
  },
  cardContent: {
    paddingTop: '10px',
    paddingBottom: '10px !important'
  },
  chip: {
    height: '25px !important',
    float: 'right',
    backgroundColor: '#FA9917',
    color: 'white'
  },
  chipContainer: {
    paddingRight: '2%',
  },
  chipButton: {
    height: '25px !important',
    float: 'right',
    lineHeight: '0px'
  },
  accordion: {
    minHeight:'48px',
    margin: '0px'
  },
  circularLoading: {
    color: '#FA9917',
  },
});

export const useStylesApprovalTimesheetList = makeStyles({
  details: {
    display: 'block'
  },
  card: {
    borderRadius: '8px',
  },
  cardContent: {
    paddingTop: '10px',
    paddingBottom: '10px !important'
  },
  chip: {
    height: '25px !important',
    float: 'right',
    backgroundColor: '#FA9917',
    color: 'white'
  },
  chipContainer: {
    paddingRight: '2%',
  },
  chipButton: {
    height: '25px !important',
    float: 'right',
    lineHeight: '0px'
  },
  accordion: {
    minHeight:'48px',
    margin: '0px'
  }
});