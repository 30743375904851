import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Typography,
  Card,
  Grid,
  Box,
} from '@material-ui/core';
import {
  CustomChip,
  useStylesList,
  LeaveSupervisorChip,
  ReviewButton,
  TooltipCustom,
  CustomCardContent,
  CustomGrid,
  CodeCheckbox
} from './style.js';
import HailIcon from '@mui/icons-material/Hail';
import { AdminPermission } from 'src/utils/Permission';
import EventEmitter from 'src/utils/EventEmitter';

const ApprovalTimesheetList = (props) => {
  /* eslint-disable no-unused-vars*/
  const navigate = useNavigate();
  const classes = useStylesList();
  let filteredData = props.timesheets;

  /* eslint-enable no-unused-vars*/
  var configuration = JSON.parse(window.localStorage.getItem('configuration'));

  function time_convert(num) {
    num = Math.abs(num);
    var hours = Math.floor(num / 60);
    var minutes = num % 60;

    return (<span>{hours}h {minutes}m</span>);
  }

  const ReviewApprovalsListView = (timesheetId, index, timesheets) => {
    navigate(`/approvals/${timesheetId}`, { state: { id: index, arrow: timesheets } });
    EventEmitter.emit('topBar', { text: 'ApprovalsReview' })
  }

  if (props.filters[0]) {
    filteredData = filteredData.filter((timesheet) => timesheet.employee.leave_approver ? timesheet.employee.parent_leave ? timesheet.employee.parent_leave.length === 0 && timesheet.employee.leave_approver.length === 0 ? timesheet.employee.type === 'Full-Time' : '' : '' : '');
  }

  if (props.filters[1]) {
    filteredData = filteredData.filter((timesheet) => (timesheet.totalCountTimesheet - timesheet.timesheet_review.length) > 0);
  }

  if (props.filters[2]) {
    filteredData = filteredData.filter((timesheet) => timesheet.employee.status === 'published');
  }

  if (props.timesheetStatus.length > 0) {
    var timesheetStatus = [];

    props.timesheetStatus.map((timesheet) => {
      timesheetStatus = [...timesheetStatus, timesheet.value];
    })

    filteredData = filteredData.filter(timesheet => timesheetStatus.includes(timesheet.status));
  }

  if(props.type){
    if (props.type.value) {
      filteredData = filteredData.filter(data => props.type.value?data.employee.type === props.type.value:'')    
    }
  }

  if(props.role){
    if (props.role.name) {
      filteredData = filteredData.filter(data => props.role.id?data.employee.user.role === props.role.id:'')    
    }
  }

  const timesheetlist = (timesheet, index, status, statusName) => {
    return (
      <div key={index}>
        <Card id={`timesheet_${index}`} elevation={0} className={classes.card} onClick={() => ReviewApprovalsListView(timesheet.id, index, props.timesheets)}>
          <CustomCardContent 
            className={classes.cardContent} 
            style={{color:timesheet.employee.status==='deleted'?'rgba(255, 0, 0, 0.77)':'#00000f',cursor:'pointer'}}>
            <CustomGrid container spacing={0}>
              <Grid item xs={3} sm={3} md={3} lg={2}>
                <Grid container spacing={0}>
                  <Grid item xs={9}>
                    <Typography id={`employee_code_${index}`} className={classes.font} style={{ paddingLeft: '20px', display: 'flex', alignItems: 'center' }}>
                      {AdminPermission() && props.configuration ?
                        props.configuration && timesheet.employee.status !== 'deleted' ?
                          <CodeCheckbox
                            sx={{
                              "&.Mui-checked": {
                                color: "#2AC940"
                              },
                              "& .css-bdd4ge-MuiButtonBase-root-MuiCheckbox-root": {
                                padding: '0px'
                              }
                            }}
                            checked={timesheet.select ? timesheet.select : false}
                            onClick={(event) => event.stopPropagation()}
                            onChange={event => { props.checkTimesheet(event.target.checked, timesheet.id) }}
                          />
                          :
                          <CodeCheckbox
                            sx={{
                              "&.Mui-checked": {
                                color: "#2AC940"
                              },
                              "& .css-bdd4ge-MuiButtonBase-root-MuiCheckbox-root": {
                                padding: '0px'
                              }
                            }}
                            disabled
                          />
                        : ''
                      }
                      {timesheet.employee.code}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    {timesheet.employee.leave_approver ?
                      timesheet.employee.parent_leave ?
                        timesheet.employee.parent_leave.length === 0 && timesheet.employee.leave_approver.length === 0 ?
                          timesheet.employee.type === 'Full-Time' ?
                            configuration.enable_leave_supervisor ?
                              <TooltipCustom placement="right" arrow title={
                                <Typography className={classes.font}>{timesheet.employee.user ? timesheet.employee.user.first_name : ''} {timesheet.employee.user ? timesheet.employee.user.last_name : ''} doesn't have a leave supervisor </Typography>}>
                                <LeaveSupervisorChip
                                  variant="outlined"
                                  size="small"
                                  icon={<HailIcon fontSize="small" sx={{ color: '#FFFFFF' }} />}
                                />
                              </TooltipCustom>
                              : ''
                            : ''
                          : ''
                        : ''
                      : ''
                    }
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={2} sm={2} md={2} lg={3}>
                <Typography noWrap id={`employee_user_${index}`} className={classes.font}>
                  {timesheet.employee.user ? timesheet.employee.user.first_name : ''} {timesheet.employee.user ? timesheet.employee.user.last_name : ''}
                </Typography>
              </Grid>
              <Grid item xs={2} sm={2} md={2} lg={2}>
                <Typography noWrap id={`employee_phone_${index}`} className={classes.font}>
                  {timesheet.employee.mobile_number}
                </Typography>
              </Grid>
              <Grid item xs={2} sm={2} md={2} lg={2}>
                <Box
                  sx={{
                    width: '165px',
                    height: '25px',
                    borderRadius: '8px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    maxWidth: '-webkit-fill-available',
                    backgroundColor: 
                    statusName === 'Approved'? '#DAF0DC':
                    statusName === 'Rejected'? '#FFDBD9':
                    statusName === 'Not Yet Submitted'? '#D4E3F2':
                    statusName === 'Waiting Approval'? '#FFF0DD':
                    statusName === 'Sent to Payroll'? 'deepskyblue':
                    statusName === 'Paid'? '#acebac': 'white'
                  }}
                  // style={status}
                >
                  <Typography noWrap id={`employee_status_${index}`} className={classes.font}>
                    {statusName}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={1} sm={1} md={1} lg={1}>
                <Typography noWrap id={`employee_total_time_${index}`} className={classes.font}>
                  {time_convert(timesheet.total_minutes)}
                </Typography>
              </Grid>
              <Grid item xs={2} sm={2} md={2} lg={2}>
                <ReviewButton
                  id={`review_btn_${index}`}
                  variant="contained"
                  className={classes.chipButton}
                  onClick={() => ReviewApprovalsListView(timesheet.id, index, props.timesheets)}
                >
                  {timesheet.totalCountTimesheet - timesheet.timesheet_review.length !== 0 ?
                    <TooltipCustom placement="left" arrow title={<Typography variant="h6">
                      You have {(timesheet.timesheet_review_unread_count)} unread messages for this timesheet</Typography>}>
                      <CustomChip
                        size="small"
                        // label={(timesheet.timesheet_review_unread_count)}
                        label={<Typography className={classes.font2}>{timesheet.timesheet_review_unread_count}</Typography>}
                      />
                    </TooltipCustom>
                    : ''}
                  <Typography className={classes.font1}>Review</Typography>
                </ReviewButton>
              </Grid>
            </CustomGrid>
          </CustomCardContent>
        </Card>
        <div style={{ height: 10 + 'px' }}></div>
      </div>
    );
  }

  return (
    <div>
      {/* eslint-disable-next-line array-callback-return */}
      {filteredData.map((timesheet, index) => {
        let status = '';
        let statusName = '';
        let match_status = props.timesheetStatusType.find(o => o.value === timesheet.status);
      
        if (match_status) {
          status = { backgroundColor: match_status.background_color, color:timesheet.employee.status==='deleted'?'rgba(255, 0, 0, 0.77)':'#00000f' };
          statusName = match_status.name;
        }
        return timesheetlist(timesheet, index, status, statusName);
      })}
    </div>
  );
};

export default ApprovalTimesheetList;