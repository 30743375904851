import React, { useState, useImperativeHandle, useEffect } from 'react';
import {
  Typography,
  Grid,
  Card,
  Checkbox,
  IconButton,
  Chip,
  Tooltip,
  Drawer,
  Divider,
  CircularProgress,
} from '@material-ui/core';
import {
  useStylesSessionList,
  MapButton,
} from './style.js';
import { AdminPermission } from 'src/utils/Permission.js';
// import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import directus from '../../services/directus';
import InfoIcon from '@material-ui/icons/Info';
import ApproveRejectButton from './ApproveRejectButton';
import LocationMap from './LocationMap';
import TimesheetEvent from './TimesheetEvent';
import SessionListDetails from './SessionListDetails.js';
import ApproveRejectButtonTimesheet from '../Timesheet/ApproveRejectButton.js';
import moment from 'moment';
import useStateRef from "react-usestateref";
import { useSnackbar } from 'notistack';
import ErrorMessage from '../Components/ErrorMessage';
import EventEmitter from 'src/utils/EventEmitter';
import MapIcon from '@mui/icons-material/Map';
import { useLoadScript } from "@react-google-maps/api";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { styled } from '@mui/material/styles';

const CustomAccordion = styled(Accordion)(({ theme }) => {
  return {
    borderRadius: '8px',
    boxShadow: 'none',
    '.MuiAccordionSummary-root': {
      minHeight: '35px',
      height: '35px',
      padding: 0,
      '.MuiButtonBase-root': {
        pointerEvents: 'auto'
      },
      '.MuiAccordionSummary-expandIconWrapper': {
        position: 'absolute',
        right: '20px'
      }
    },
    '.MuiAccordionDetails-root': {},
  };
});

const SessionList = React.forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    getAlert(data) {
      changeSessionData(data);
    }
  }));

  /* eslint-disable */
  let code = 0;
  let disableButton = false;
  let enableEdit = false;
  let enableCheckbox = false;
  let sessionGroup = [];
  let sessionGroupTime = 0;
  let materialId = [];
  let equipmentId = [];
  let path = window.location.pathname.split('/')[1];
  let Total = 0;

  const classes = useStylesSessionList();
  // const childRef = useRef();
  const [checkboxValueList] = React.useState([]);
  const [buttonLoading, setButtonLoading] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [activity] = React.useState([]);
  const [customer] = React.useState([]);
  const [loadingEdit, setLoadingEdit] = React.useState(false);
  const [sessionData, setSessionData] = useState([]);
  const [sessionDataGroup, setSessionDataGroup] = useState([]);
  const [equipmentExist, setEquipmentExist] = useState([]);
  const [materialExist, setMaterialExist] = useState([]);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [expandedSession, setExpandedSession] = useState({});
  const [openDrawer, setOpenDrawer] = React.useState(0);
  const [anchor, setAnchor] = React.useState(false);
  const [initialized, setInitialized] = useState(false);

  var enableGPS = JSON.parse(window.localStorage.getItem('config_ts')).enable_gps;
  var enableTimesheetBankHours = JSON.parse(window.localStorage.getItem('config_ts')).enable_timesheet_bank_hours;
  var total_duration_with_break_time = JSON.parse(window.localStorage.getItem('configuration')).total_duration_with_break_time;
  var expand_detail_session = JSON.parse(window.localStorage.getItem('configuration')).expand_detail_session;

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
    }

    return () => {
      setInitialized(false);
    }
  }, []);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: " AIzaSyBc4uOSNaOlMw8RHTDIT-aVdlRXHQaA39I" // Add your API key
  });

  function changeSessionData(DataValue) {
    setSessionData(
      DataValue.map((d) => {
        if (d.status === 'pending' || d.status === 'leave_pending') {
          return {
            readonly: false,
            select: false,
            ...d
          };
        }
        else {
          let readonly = false;
          let checkedStatus;
          if (d.status === 'approved' || d.status === 'leave_approved' || d.status === 'rejected' || d.status === 'leave_rejected' || d.status === 'sent') {
            readonly = true;
            if (d.status === 'approved' || d.status === 'leave_approved' || d.status === 'sent') {
              checkedStatus = true;
            }
            else {
              checkedStatus = false;
            }
          }
          return {
            checkedStatus: checkedStatus,
            readonly: readonly,
            ...d
          };
        }
      })
    );

    let newExpandedSessionValue = {};
    let count = 0;
    DataValue.map((d, index) => {
      // apply the expand action to sessions that have details
      if (d.resources.length > 0 || d.notes || d.options.length > 0 || d.attachments.length > 0) {
        if (expand_detail_session) {
          newExpandedSessionValue[index] = true;
        }
        else {
          newExpandedSessionValue[index] = false;
        }
      }

      if (index < 2 && index > 0) {
        if (moment(d.session_date).format('L') !== moment(DataValue[index - 1].session_date).format('L')) {
          sessionGroupTime = 0;
        }
      }

      if (d.duration === 0) {
        if (d.session_type.include_in_total === true) {
          if (d.session_end_time !== null) {
            if (total_duration_with_break_time) {
              sessionGroupTime = sessionGroupTime + durationTimeDay(d.session_start_time, d.session_end_time);
            }
            else {
              if (d.break_time === 0 && (d.break_end_time !== null && d.break_start_time !== null)) {
                sessionGroupTime = sessionGroupTime + durationTimeDay(d.session_start_time, d.session_end_time) - durationTimeDay(d.break_start_time, d.break_end_time);
              }
              else {
                sessionGroupTime = sessionGroupTime + durationTimeDay(d.session_start_time, d.session_end_time) - d.break_time;
              }
            }
          }
        }
      }
      else {
        if (d.session_type.include_in_total === true) {
          sessionGroupTime = sessionGroupTime + Math.floor(d.duration);
        }
      }

      if (index === 0) {
        sessionGroup[count] = {
          title: moment(d.session_date).format('dddd') + ", " + moment(d.session_date).format('DD MMMM'),
          time: durationWords(sessionGroupTime),
          ...d
        }
      }
      else if (index !== 0) {
        if (moment(d.session_date).format('L') !== moment(DataValue[index - 1].session_date).format('L')) {
          count = count + 1;
          sessionGroup[count] = {
            title: moment(d.session_date).format('dddd') + ", " + moment(d.session_date).format('DD MMMM'),
            time: durationWords(sessionGroupTime),
            ...d
          }
        }
        else {
          sessionGroup[count] = {
            title: moment(d.session_date).format('dddd') + ", " + moment(d.session_date).format('DD MMMM'),
            time: durationWords(sessionGroupTime),
            ...d
          }
        }

        if (index + 1 < DataValue.length) {
          if (moment(d.session_date).format('L') !== moment(DataValue[index + 1].session_date).format('L')) {
            sessionGroupTime = 0;
          }
        }
      }

      d.resources.map(resourceData => {
        if (resourceData.resource) {
          if (resourceData.resource.type === 'chemical') {
            materialId[index] = 1;
          }
          else if (resourceData.resource.type === 'equipment') {
            equipmentId[index] = 1;
          }
        }
        return null;
      });
      return null;
    });

    
    setMaterialExist(materialId);
    setEquipmentExist(equipmentId);
    setSessionDataGroup(sessionGroup);
    setExpandedSession(newExpandedSessionValue);
  }

  function changeButtonLoading(value) {
    setButtonLoading(value);
  }

  function durationTimeDay(started, ended) {
    if (ended != null) {
      var dif;
      if (started > ended) {
        dif = moment.duration(moment(moment('1991-01-02T' + ended).format('YYYY-MM-DDTHH:mm:00')).diff(moment('1991-01-01T' + started).format('YYYY-MM-DDTHH:mm:00')));
      }
      else {
        dif = moment.duration(moment(moment('1991-01-01T' + ended).format('YYYY-MM-DDTHH:mm:00')).diff(moment('1991-01-01T' + started).format('YYYY-MM-DDTHH:mm:00')));
      }

      if (dif.hours() === 0) {
        return (parseInt(dif.minutes()));
      }
      else {
        return ((parseInt(dif.hours()) * 60) + parseInt(dif.minutes()));
      }

    }
  }

  function durationTime(started, ended) {
    if (ended != null) {
      var diff;
      if (started > ended) {
        diff = moment.duration(moment(moment('1991-01-02T' + ended).format('YYYY-MM-DDTHH:mm:00')).diff(moment('1991-01-01T' + started).format('YYYY-MM-DDTHH:mm:00')));
      }
      else {
        diff = moment.duration(moment(moment('1991-01-01T' + ended).format('YYYY-MM-DDTHH:mm:00')).diff(moment('1991-01-01T' + started).format('YYYY-MM-DDTHH:mm:00')));
      }
      return diff.hours() * 60 + diff.minutes();
    }
    return 0;
  }

  function durationWords(mins, force_hours = false) {
    mins = Math.abs(mins);
    var hours = Math.floor(mins / 60);
    var minutes = Math.floor(mins) % 60; // remove seconds

    if (hours === 0 && !force_hours) {
      return (<span><b>{minutes}</b> min</span>);
    }
    else {
      return (<span><b>{hours}</b> hr <b>{minutes}</b> min</span>);
    }
  }

  if (path === 'approvals') {
    if (props.timesheets.status !== 'pending' && props.timesheets.status !== 'rejected') {
      enableEdit = true;
    }
    if (props.timesheets.status === 'awaiting_approval') {
      enableCheckbox = true;
    }
  }
  else if (path === 'timesheet') {
    if (props.timesheets.status !== 'awaiting_approval' && props.timesheets.status !== 'rejected') {
      enableEdit = true;
    }

    disableButton = true;
  }

  const dashboardDrawer = (event, data, session, timesheets) => {
    event.stopPropagation();
    EventEmitter.emit('dashboardDrawer', {
      text: data,
      session: session,
      timesheets: timesheets,
      page: props.page
    })

    EventEmitter.emit('dashboardDrawerDisableEdit', {
      text: "read"
    })
  }

  const handleChangeSession = (panel, id) => (event, newExpanded) => {
    let newArrExpandedSession = { ...expandedSession };
    newArrExpandedSession[id] = newExpanded;

    setExpandedSession(newArrExpandedSession);
  };

  const handleChangeAll = () => {
    let expandAllStatus = Object.values(expandedSession).some(val => val === true);

    setExpandedSession(prevState => {
      const nextState = {}
      Object.keys(prevState).forEach(key => {
        nextState[key] = !expandAllStatus
      })
      return nextState
    })
  };

  return (
    <>
      

      <Grid container spacing={0} className={classes.rootGrid} style={{ position: 'relative' }}>
        <Grid item xs={2}>
          <Typography className={classes.font1} noWrap><b>Customer</b></Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className={classes.font1} noWrap><b>Activity</b></Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography className={classes.font1} noWrap ><b>Start</b></Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography className={classes.font1} noWrap><b>End</b></Typography>
        </Grid>
        <Grid item xs={2} style={{ paddingLeft: '11px' }}>
          <Typography className={classes.font1} noWrap><b>Break</b></Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className={classes.font1} noWrap><b>Duration</b></Typography>
        </Grid>
        <Grid item xs={1}>
          <span style={{ paddingLeft: '30px' }}></span>
          {disableButton ?
            '' :
            enableCheckbox ?
              <Checkbox
                style={{
                  padding: '0px',
                  '@media print': {
                    display: 'none'
                  },
                }}
                onChange={e => {
                  let checked = e.target.checked;
                  setSessionData(
                    sessionData.map(d => {
                      d.select = checked;
                      return d;
                    })
                  );
                }}
                disabled={props.status === 'deleted'}
              />
              : ''}
        </Grid>
        <Grid item xs={1}>
          <div style={{ position: 'absolute', right: '20px' }}>
            {Object.keys(expandedSession).length > 0 ?
              Object.values(expandedSession).some(val => val === true) ?
                <IconButton style={{ padding: '3px' }} onClick={handleChangeAll}>
                  <ExpandLessIcon style={{ color: 'black', cursor: 'pointer' }} />
                </IconButton>
                :
                <IconButton style={{ padding: '3px' }} onClick={handleChangeAll}>
                  <ExpandMoreIcon style={{ color: 'black', cursor: 'pointer' }} />
                </IconButton>
              : ''}
          </div>
        </Grid>
      </Grid>

      {sessionDataGroup ?
        sessionDataGroup.map((sectionId, index) => {
          return (
            <React.Fragment key={index}>
              <Card
                className={classes.cardHeader}
                elevation={0}
              >
                <Grid container spacing={0} justifyContent="space-around">
                  <Grid item xs={2}>
                    <Typography className={classes.font3} noWrap>{sectionId.title}</Typography>
                  </Grid>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={4}>
                    <Typography className={classes.font3} noWrap>{sectionId.time}</Typography>
                  </Grid>
                </Grid>
              </Card>

              {sessionData.map((session, index) => {
                if (moment(session.session_date).format('L') === moment(sectionId.session_date).format('L') && (session.session_type.include_in_total || session.session_type.internal)) {

                  let duration;
                  if (total_duration_with_break_time) {
                    if (session.duration === 0) {
                      duration = durationTime(session.session_start_time, session.session_end_time);
                    }
                    else {
                      duration = session.duration;
                    }
                  }
                  else {
                    if (session.duration === 0) {
                      if (session.break_time === 0 && (session.break_end_time !== null && session.break_start_time !== null)) {
                        duration = durationTime(session.session_start_time, session.session_end_time) - durationTime(session.break_start_time, session.break_end_time);
                      }
                      else {
                        duration = durationTime(session.session_start_time, session.session_end_time) - session.break_time;
                      }
                    }
                    else {
                      if (session.break_time === 0 && (session.break_end_time !== null || session.break_start_time !== null)) {
                        duration = session.duration - durationTime(session.break_start_time, session.break_end_time);
                      }
                      else {
                        duration = session.duration - session.break_time;
                      }
                    }
                  }

                  if (session.session_type.include_in_total) Total += duration;

                  function line() {
                    if (index + 1 < sessionData.length) {
                      if (session.session_date === sessionData[index + 1].session_date) {
                        if (path === 'timesheet') {
                          if (moment(moment(sessionData[index + 1].session_date + ' ' + sessionData[index + 1].session_end_time).format('YYYY-MM-DD HH:mm')).diff(moment(session.session_date + ' ' + session.session_start_time).format('YYYY-MM-DD HH:mm')) < 0) {
                            return <Divider className={classes.dividerStyleRed} />
                          }
                          else {
                            return <Divider style={{ display: 'none' }} />
                          }
                        }
                        else {
                          if (moment(moment(sessionData[index + 1].session_date + ' ' + sessionData[index + 1].session_start_time).format('YYYY-MM-DD HH:mm')).diff(moment(session.session_date + ' ' + session.session_end_time).format('YYYY-MM-DD HH:mm')) > 0) {
                            return <Divider className={classes.dividerStyleRed} />
                          }
                          else {
                            return <Divider style={{ display: 'none' }} />
                          }
                        }
                      }

                    }
                  }

                  const backgroundColor = session.extra ? 'bisque' : '';

                  const textColor = session.session_type.name === 'Lunch' ? 'rosybrown' : session.session_type.name !== 'Manual Entry' && session.session_type.name !== 'Clock In' && session.session_type.name !== 'Ordinary Hours' ? '#F79256' : '';

                  const isHaveDetails = session.resources.length > 0 || session.notes || session.options.length > 0 || session.attachments.length > 0;

                  return (
                    <div key={index}>
                      <Card
                        className={classes.card}
                        elevation={0}
                      >
                        <CustomAccordion
                          key={index}
                          expanded={expandedSession[index] ? expandedSession[index] : false}
                          onChange={handleChangeSession(`panel_${index}`, index)}
                          style={{ backgroundColor: backgroundColor }}
                        >
                          <AccordionSummary
                            id={`session_list_${index}`}
                            aria-controls="panel1-content"
                            sx={{ pointerEvents: "none", minHeight: '35px !important' }}
                            expandIcon={isHaveDetails ? (
                              <IconButton style={{ pointerEvents: "auto", padding: '3px' }}>
                                <ExpandMoreIcon />
                              </IconButton>
                            ) : ''
                            }
                          >
                            <Grid container spacing={0} justifyContent="space-between" style={{ paddingLeft: '20px', color: textColor, pointerEvents: 'auto' }} onClick={(event) => dashboardDrawer(event, 5, session, props.timesheets)}>
                              <Grid item xs={2} className={classes.gridCustom}>
                                <Typography className={classes.font2} id={`session_type_${index}`} noWrap >{
                                  session.session_type ?
                                    session.session_type.show_customer ?
                                      session.customer || customer[index] ? customer[index] ? customer[index].name : session.customer.name
                                        :
                                        'No Customer'
                                      : session.session_type.name
                                    :
                                    session.type === 'session' ?
                                      session.customer || customer[index] ? customer[index] ? customer[index].name : session.customer.name
                                        :
                                        'No Customer'
                                      : session.type
                                }
                                </Typography>
                              </Grid>
                              <Grid item xs={2} className={classes.gridCustom}>
                                <Typography className={classes.font2} id={`activity_name_${index}`} noWrap>
                                  {
                                    session.session_type ?
                                      session.session_type.show_customer ?
                                        session.activity || activity[index] ?
                                          activity[index] ? activity[index].name : session.activity.name
                                          :
                                          'No Activity'
                                        :
                                        ''
                                      :
                                      session.type === 'session' ?
                                        session.activity || activity[index] ?
                                          activity[index] ? activity[index].name : session.activity.name
                                          :
                                          'No Activity'
                                        :
                                        ''
                                  }
                                </Typography>
                              </Grid>
                              <Grid item xs={1} className={classes.gridCustom}>
                                <Typography className={classes.font2} id={`session_start_time_${index}`} noWrap>{session.session_start_time ? moment(session.session_start_time, "HH:mm:ss").format('LT') : ''}</Typography>
                              </Grid>
                              <Grid item xs={1} className={classes.gridCustom}>
                                <Typography className={classes.font2} id={`session_end_time_${index}`} noWrap>
                                  {session.session_end_time ? moment(session.session_end_time, "HH:mm:ss").format('LT') : ''}

                                  {
                                    moment(moment(session.session_end_time, "HH:mm:ss").format()).diff(moment(session.session_start_time, "HH:mm:ss").format(), 'minutes') < 0 ?
                                      <Tooltip title={moment(moment(session.session_date).add(1, 'd')).format("DD MMM YYYY")} arrow>
                                        <InfoIcon style={{ height: '15px', marginBottom: '-2px' }} />
                                      </Tooltip>
                                      : ''
                                  }
                                </Typography>
                              </Grid>
                              <Grid item xs={2} className={classes.gridCustom}>
                                {session.session_type.show_break_start_end_time ?
                                  session.break_start_time !== null && session.break_end_time !== null ?
                                    session.break_start_time !== 0 && session.break_end_time !== 0 ?
                                      <Chip id={`break_chip_${index}`}
                                        className={classes.Chip}
                                        label={<Typography className={classes.font2} noWrap >{'Smoko - ' + moment(moment(session.session_date + ' ' + session.break_end_time).format('YYYY-MM-DD HH:mm:00')).diff(moment(session.session_date + ' ' + session.break_start_time).format('YYYY-MM-DD HH:mm:00'), "minutes") + ' mins'}</Typography>}
                                      />
                                      : ''
                                    : ''
                                  :
                                  session.session_type.show_break_time ?
                                    session.break_time !== null ?
                                      session.break_time !== 0 ?
                                        <Chip id={`break_chip_${index}`}
                                          className={classes.Chip}
                                          label={<Typography>{'Smoko - ' + session.break_time + ' mins'}</Typography>}
                                        />
                                        : ''
                                      : ''
                                    : ''
                                }
                                {session.session_type.name === 'Lunch' ?
                                  <Typography className={classes.font2} style={{ paddingLeft: '11px' }} id={`session_lunch_duration_${index}`} noWrap >
                                    {/* {moment(moment(session.session_date + ' ' + session.session_end_time).format('YYYY-MM-DD HH:mm:00')).diff(moment(session.session_date + ' ' + session.session_start_time).format('YYYY-MM-DD HH:mm:00'), "minutes") + ' min'}
                                      <br/> */}

                                    {durationTime(session.session_start_time, session.session_end_time) + ' min'}
                                  </Typography>
                                  :
                                  ''}
                              </Grid>
                              <Grid item xs={2} className={classes.gridCustom}>
                                <Typography className={classes.font2} noWrap >
                                  {session.session_type.include_in_total ? durationWords(duration, true) : ''}
                                </Typography>
                              </Grid>
                              <Grid item xs={2} className={classes.gridCustom}>
                                {enableGPS && path !== 'timesheet' && AdminPermission() ?
                                  session.session_start_latitude && session.session_start_longitude ?
                                    <IconButton id={`edit_session_${index}`} aria-label="icon"
                                      onClick={(event) => event.stopPropagation()}
                                      onFocus={(event) => event.stopPropagation()}
                                      className={classes.iconButton}
                                    >
                                      <MapIcon
                                        fontSize="small"
                                        onClick={() => window.open('https://www.google.com/maps/place/' + session.session_start_latitude + ',' + session.session_start_longitude)}
                                      />
                                    </IconButton>
                                    : <span style={{ paddingLeft: '30px' }}></span>
                                  : <span style={{ paddingLeft: '30px' }}></span>}
                                {loadingEdit ?
                                  '' :
                                  enableCheckbox && !session.readonly ?
                                    <Checkbox
                                      style={{
                                        padding: '0px',
                                        '@media print': {
                                          display: 'none'
                                        },
                                      }}
                                      onClick={(event) => event.stopPropagation()}
                                      onFocus={(event) => event.stopPropagation()}
                                      id={`Code ${code} ${index}`}
                                      checked={session.select}
                                      name={`${code}`}
                                      onChange={event => {
                                        let checked = event.target.checked;
                                        setSessionData(
                                          sessionData.map(data => {
                                            if (session.id === data.id) {
                                              data.select = checked;
                                            }
                                            return data;
                                          })
                                        );
                                      }}
                                      disabled={props.status === 'deleted'}
                                    />
                                    : ''
                                }
                                {session.readonly && !isEdit[index] ?
                                  session.checkedStatus ?
                                    <Checkbox
                                      style={{
                                        padding: '0px',
                                        '@media print': {
                                          display: 'none'
                                        },
                                      }}
                                      onClick={(event) => event.stopPropagation()}
                                      onFocus={(event) => event.stopPropagation()}
                                      className={classes.checkedStatus}
                                      checked={session.readonly}
                                      disabled={props.status === 'deleted'}
                                    />
                                    :
                                    <Checkbox
                                      style={{
                                        padding: '0px',
                                        '@media print': {
                                          display: 'none'
                                        },
                                      }}
                                      onClick={(event) => event.stopPropagation()}
                                      onFocus={(event) => event.stopPropagation()}
                                      className={classes.checkedStatusFailed}
                                      checked={session.readonly}
                                      disabled={props.status === 'deleted'}
                                    />
                                  : ''}

                              {props.saveLoad?
                                <><CircularProgress size={20} style={{ color: '#FA9917',}} /></>
                              :''}
                              </Grid>
                            </Grid>
                          </AccordionSummary>
                          <AccordionDetails onClick={(event) => dashboardDrawer(event, 5, session, props.timesheets)} sx={{ padding: '0 !important', paddingLeft: '20px !important' }}>
                            <Grid container spacing={0} justifyContent="space-between">
                              <Grid item xs={12}>
                                <SessionListDetails
                                  session={session}
                                  loadSession={props.loadSession}
                                  sessionIdentify={index}
                                  materialExist={materialExist}
                                  equipmentExist={equipmentExist}
                                  backgroundColor={backgroundColor}
                                />
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </CustomAccordion>
                      </Card>
                      {line()}

                    </div>
                  );
                }
                return null;
              })
              }
            </React.Fragment>
          );
        })
        : ''}

      <>
        <Card className={classes.cardFooter} elevation={0}>
          <Grid container spacing={0} className={classes.rootGrid}>
            <Grid item xs={2}>
              <Typography className={classes.font1} noWrap>Total</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className={classes.font1} noWrap></Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className={classes.font1} noWrap></Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className={classes.font1} noWrap></Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography className={classes.font1} noWrap>{durationWords(Total, true)}</Typography>
            </Grid>
          </Grid>
        </Card>


        {path !== 'timesheet' ?
          <Grid container spacing={0}>
            <Grid item xs={3} style={{ padding: '10px 0px', display: '-webkit-inline-box' }}>
              <TimesheetEvent />
              {isLoaded && enableGPS && props.totalCountLatLong !== 0 && props.marker.length > 0 && AdminPermission() ?
                <>
                  <MapButton onClick={() => setAnchor(true)} variant="contained">Map</MapButton>
                  <Drawer
                    anchor="bottom"
                    open={anchor}
                    onClose={() => setAnchor(false)}
                  >
                    <LocationMap
                      markerData={props.marker}
                    />
                  </Drawer>
                </>
                : ''}
              <Typography style={{ fontSize: '14px', fontFamily: 'Manrope' }}>
                {props.timesheets.employee.award !== null ? props.timesheets.employee.award.name : ''}
              </Typography>
            </Grid>

            <Grid item xs={9}>
              <ApproveRejectButton
                setLoading={props.setLoading}
                timesheets={props.timesheets}
                checkboxValueList={checkboxValueList}
                employeeIdChosen={props.employeeIdChosen}
                changeButtonLoading={changeButtonLoading}
                sessionData={sessionData}
                changeSessionData={changeSessionData}
                loadTimesheets={props.loadTimesheets}
                right={props.right}
                enableTimesheetBankHours={enableTimesheetBankHours}
                totalDuration={Total}
              />
            </Grid>
          </Grid>
          :
          <Grid container spacing={0}>
            <Grid item xs={6}></Grid>
            <Grid item xs={6}>
              <div style={{ float: 'right', paddingTop: '10px', paddingBottom: '10px' }}>
                <ApproveRejectButtonTimesheet
                  setLoading={props.setLoading}
                  timesheets={props.timesheets}
                  checkboxValueList={checkboxValueList}
                  employeeIdChosen={props.employeeIdChosen}
                  changeButtonLoading={changeButtonLoading}
                  sessionData={sessionData}
                  changeSessionData={changeSessionData}
                  loadTimesheets={props.loadTimesheets}
                  right={props.right}
                  totalDuration={Total}
                  enableTimesheetBankHours={enableTimesheetBankHours}
                />
              </div>
            </Grid>
          </Grid>
        }
      </>
    </>
  );
});

export default SessionList;