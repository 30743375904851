import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import ApprovalListView from 'src/views/History/ApprovalListView';
import ApprovalListViewEmp from 'src/views/Approvals/ApprovalListView';
import LeaveRequests from 'src/views/LeaveRequests/ApprovalListView';
import TimeSheetView from 'src/views/Timesheet';
import ApprovalSession from 'src/views/SessionList/index';
// import Dashboard from 'src/views/Dashboard';
import LoginView from 'src/views/auth/LoginView';
import NotFoundView from 'src/views/errors/NotFoundView';
import RegisterView from 'src/views/auth/RegisterView';
import PasswordReset from 'src/views/auth/ResetPasswordPage';
import Reporting from 'src/views/Reporting/index';
import Settings from 'src/views/Settings';
import * as permission from 'src/utils/Permission';

const routes = [
  {
    path: '/',
    element: <DashboardLayout />,
    children: [
      { path: '/', element: permission.SupervisorPermission()?<ApprovalListViewEmp />:<Navigate to="/timesheet" /> },
      { path: 'history', element: permission.SupervisorPermission()?<ApprovalListView />:<Navigate to="/timesheet" /> },
      { path: 'approvals', element: permission.SupervisorPermission() || permission.CustomerEmployeePermission() || permission.EmployeeEmployeePermission()?<ApprovalListViewEmp />:<Navigate to="/timesheet" /> },
      { path: 'approvals/:id', element: permission.SupervisorPermission() || permission.CustomerEmployeePermission() || permission.EmployeeEmployeePermission()?<ApprovalSession />:<Navigate to="/timesheet" /> },
      { path: 'timesheet', element: <TimeSheetView /> },
      { path: 'timesheet/:timesheetId', element: <TimeSheetView /> },
      { path: 'leave_requests', element: <LeaveRequests /> },
      { path: 'reporting', element: permission.SupervisorPermission() || permission.EmployeeLeavePermission()?<Reporting />:<Navigate to="/timesheet" /> },
      { path: 'settings', element: permission.SupervisorPermission() || permission.EmployeeLeavePermission()?<Settings />:<Navigate to="/timesheet" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'passwordreset', element: <PasswordReset /> },
      { path: 'sign-in', element: <LoginView /> },
      { path: 'sign-up', element: <RegisterView /> },
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: <Navigate to="/approvals" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
