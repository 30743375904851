import React, { useState, useEffect } from 'react';
import { IntegrationController, IntegrationListController} from '../../Controller/Settings/Integration.js';
import directus from '../../services/directus';
import EventEmitter from 'src/utils/EventEmitter';
import useStateRef from "react-usestateref";
import { useSnackbar } from 'notistack';
import ErrorMessage from '../../views/Components/ErrorMessage';

let page = 1;

export const IntegrationViewModel = () => {
  const [initialized, setInitialized] = useState(false);
  const [Integration, setIntegration] = useState(null);
  const [IntegrationList, setIntegrationList] = useState(null);

  const [searchInt, setSearchInt] = useState('');
  const [type, setType] = useState('');
  const [environment, setEnvironment] = useState('');

  const [tenant_id, setTenantID] = useState('');
  const [client_id, setClientID] = useState('');
  const [client_secret, setClientSecret] = useState('');

  const [integrationData, setintegrationData] = useState(null);
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');

  const [mode, setMode] = useState('');
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingProcess, setLoadingProcess, loadingProcessRef] = useStateRef(false);

  const [totalPage, setTotalPage] = useState("");
  const [pageNumber, setPageNumber,pageNumberRef] = useStateRef(localStorage.getItem("integration_page")?localStorage.getItem("integration_page"):50);

  const { enqueueSnackbar } = useSnackbar();

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      loadIntegration();
    }

    const searchIntegration = (eventData) => {
      setLoadingProcess(true);
      page = 1;
      setSearchInt(eventData.text);
      loadIntegration(eventData.text);
    }

    const listener = EventEmitter.addListener('searchIntegration', searchIntegration);

    return () => {
      listener.remove();
    }
  });
  /* eslint-enable react-hooks/exhaustive-deps*/

  let disable = type === '' || tenant_id=== '' || client_secret === '';

  const loadIntegration = async (data) => {
    var IntegrationResults = await IntegrationController(data,page);
    setIntegrationList(await IntegrationListController());
    setIntegration(IntegrationResults.data);
    setTotalPage(IntegrationResults.meta.filter_count);
    setIntegration(IntegrationResults.data);
    setLoadingProcess(false);
  };

  const pageChange = (value) => {
    setLoadingProcess(true);
    page = page + value;
    loadIntegration(searchInt);
  }

  const handleChange = (event) => {
    setLoadingProcess(true);
    setPageNumber(event.target.value);
    localStorage.setItem("integration_page", JSON.parse(event.target.value));
    page = 1;
    loadIntegration(searchInt);
  };

  const handleClickOpen = (integration, mode) => {
    setOpen(true);

    if (mode === 'edit') {
      setEnvironment(integration.environment);
      setTenantID(integration.tenant_id);
      setClientID(integration.client_id);
      setClientSecret(integration.client_secret);
      setintegrationData(integration);
      
      if (IntegrationList.length > 0) {
        let result = [];
        IntegrationList.map(data => {
          if(data.id === integration.type )
          {
            result = {id:data.id,name:data.name,value:data.value};
          }
        })
        setType(result);
      }
      else{
        setType('');
      }
  
      setMode('edit')
      setTitle('Edit Integration');
      setText('');
    }
    else if (mode === 'add') {
      setType({ id: 0, name: 'Business Central 365', value: 'bc365' });
      setEnvironment('');
      setTenantID('');
      setClientID('');
      setClientSecret('');
      setMode('add');
      setTitle('Add Integration');
      setText('');
    }
    else {
      setintegrationData(integration);
      setMode('delete');
      setTitle('Delete Integration');
      setText('Are you Sure?');
    }

  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeInput = (e) => {
      const { name, value } = e.target;
      if (name === 'type') {
        setType(value);
      }
      else if (name === 'environment') {
        setEnvironment(value);
      }
      else if (name === 'tenant_id') {
        setTenantID(value);
      }
      else if (name === 'client_id') {
        setClientID(value);
      }
      else {
        setClientSecret(value);
      }
    }

  const SaveIntegration = async () => {
    setLoading(true);
    setLoadingProcess(true);
    if (mode === 'edit') {
      try {
        await directus.updateItem('integration', integrationData.id, {
          type: type?type.value:'',          
          environment: environment ? environment : '',
          tenant_id:tenant_id ? tenant_id: '',
          client_id:client_id ? client_id: '',
          client_secret:client_secret ? client_secret: ''
        });
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }
    }
    else if (mode === 'add') {
      try {
        await directus.createItem('integration', {
          type: type?type.value:'',        
          environment: environment ? environment : '',
          tenant_id:tenant_id ? tenant_id: '',
          client_id:client_id ? client_id: '',
          client_secret:client_secret ? client_secret: ''
        });
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }
    }
    else {
      try {
        await directus.deleteItem('integration', integrationData.id);
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }
    }

    loadIntegration();
    setLoading(false);
    handleClose();
  }

  return[
    //states
    {
      open, title, Integration,
      mode,  loading, disable,
      pageNumberRef, totalPage, page,
      pageNumber, text,
      IntegrationList,
      type, environment, tenant_id, client_id,
      client_secret, loadingProcessRef
    },
     //setStates
    {
      setType,
    },
    //function
    {
      handleClickOpen, handleClose, SaveIntegration,
      handleChange, handleChangeInput, pageChange
    }
  ];
}

export default IntegrationViewModel;