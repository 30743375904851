import React from 'react';
import { Link } from 'react-router-dom';
import { 
  Typography, 
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
  Card,
  CardContent,
  Grid,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useStylesApprovalTimesheetList, ActionButton } from './style.js';


const ApprovalTimesheetList = (props) => {
  let month = ['','January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  const classes = useStylesApprovalTimesheetList();

  function countTimesheet(employeeId){
    let countTimes = 0;

    // eslint-disable-next-line array-callback-return
    props.timesheets.map((timesheet) => {
      if(timesheet.employee === employeeId && timesheet.status === 'awaiting_approval')
      {
        countTimes++
      }
    })

    return countTimes;
  }

  function countFullTimesheet(employeeId){
    let countTimes = 0;
    // eslint-disable-next-line array-callback-return
    props.timesheets.map((timesheet) => {
      countTimes++
    })

    return countTimes;
  }

  return (
    <div>
      {props.employees.map((data, index) => {
        let employeeId = data.id;
        let count = countTimesheet(employeeId);
        let fullCount = countFullTimesheet(employeeId);

        return(
            <div key={index}>
              <Accordion 
                elevation={0} 
                className={classes.accordion} 
                disabled={count=== 0 && fullCount === 0}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Grid container spacing={0} justify="space-between">
                    <Grid item xs={1}>
                      <Typography>{data.title}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography>{data.first_name}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography>{data.last_name}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography>{data.email}</Typography>
                    </Grid>
                    <Grid item xs={3} className={classes.chipContainer}>
                      <Chip className={classes.chip} label={<span>{count}</span>} />
                      {/* <Fab size="small" color="secondary" aria-label="add">
                        <AddIcon />
                      </Fab>   */}
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                  {/* eslint-disable-next-line array-callback-return */}
                  {props.timesheets.map((timesheet, index) => {
                    let startDate = timesheet.start_time;
                    let subStrStartDate = startDate.substring(0,10);
                    let StartDateResult = subStrStartDate.split("-");

                    let endDate = timesheet.end_time;
                    let subStrEndDate = endDate.substring(0,10);
                    let EndDateResult = subStrEndDate.split("-");

                    let status;
                    let statusName;
                    if(timesheet.status === 'approved')
                    {
                      status = {backgroundColor: '#DAF0DC'};
                      statusName = 'Approved';
                    }
                    else if(timesheet.status === 'rejected')
                    {
                      status = {backgroundColor: '#FFDBD9'};
                      statusName = 'Rejected';
                    }
                    else if(timesheet.status === 'pending')
                    {
                      status = {backgroundColor: '#D4E3F2'};
                      statusName = 'Not Yet Submitted';
                    }
                    else if(timesheet.status === 'awaiting_approval')
                    {
                      status = {backgroundColor: '#FFF0DD'};
                      statusName = 'Waiting Approval';
                    }

                    if(timesheet.employee === employeeId)
                    {
                      return(
                        <div key={index}>
                          <Card elevation={0} className={classes.card} style={status}>
                            <CardContent className={classes.cardContent}>
                              <Grid container spacing={3}>
                                <Grid item xs={8}>
                                  <Typography>
                                    {StartDateResult[2]} {month[parseInt(StartDateResult[1])]} {StartDateResult[0]} - {EndDateResult[2]} {month[parseInt(EndDateResult[1])]} {EndDateResult[0]}
                                  </Typography>
                                </Grid>
                                <Grid item xs={2} style={{float: 'right'}}>
                                  <Typography>
                                    {statusName}
                                  </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                  <Link to={`/approvals/${timesheet.id}`}>
                                    <ActionButton variant="contained" className={classes.chipButton}>
                                      REVIEW
                                    </ActionButton>
                                  </Link>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                          <div style={{height:10+'px'}}></div>
                        </div>
                      );
                    }
                  })}
                </AccordionDetails>
              </Accordion>
              <div style={{height:10+'px'}}></div>
            </div>
          );
        })
      }
    </div>
  );
};

export default ApprovalTimesheetList;
