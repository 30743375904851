import { 
  makeStyles,
  Button,
  Tooltip,
  withStyles,
  Chip,
  List,
  TextField,
  AccordionSummary,
  ListItem,
  Grid,
} from '@material-ui/core';
import { IconButton } from "@material-ui/core";
import MuiAccordion from '@material-ui/core/Accordion';
import { DatePicker } from "@material-ui/pickers";

export const TextFieldNotes = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
      fontFamily: "Manrope",
    },
  },
})(TextField);

export const TextFieldCustom = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
      height: '34px',
      fontFamily: "Manrope",
    },
    '& .MuiFormLabel-root': {
      top:'-3px',
    },
    '& input': {
      paddingTop: '0 !important',
      paddingBottom: '0 !important',
    }
  },
})(TextField);

export const DatePickerCustom = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
      height: '34px',
      fontFamily: "Manrope",
    },
    '& .MuiFormLabel-root': {
      top:'-3px',
    },
    '& input': {
      paddingTop: '0 !important',
      paddingBottom: '0 !important',
    }
  },
})(DatePicker);

export const CustomGridApproval = withStyles(() => ({
  root: {
    height:'35px',
    paddingTop:'10px',
    marginBottom:'10px'
  },
}))(Grid);

export const AccordionSummaryCustom = withStyles({
  root: {
    padding:'0px !important'
  }
})(AccordionSummary);

export const Accordion = withStyles({
  root: {
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

export const CustomListItem = withStyles({
  root: {
    paddingTop:'2px !important',
    paddingBottom:'8px !important',
  },
})(ListItem);

export const CustomTooltip = withStyles({
  tooltip: {
    fontSize: "16px",
    maxWidth: 500,
    backgroundColor: "grey"
  }
})(Tooltip);

export const FlagTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius:'16px'
    },
  },
})(TextField);

export const FlagTextFieldDisabled = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius:'16px',
      backgroundColor:'#dadada'
    },
  },
})(TextField);

export const DuplicateButton = withStyles({
  root: {
    borderRadius: '8px',
    backgroundColor: '#2b0ac7',
    color: '#ffffff',
    height: '30px',
    width: '120px',
    '&:hover': {
      backgroundColor: '#2b0ac7',
    },
  },
})(Button);

export const SubmitButton = withStyles({
  root: {
    borderRadius: '8px',
    backgroundColor: '#2AC940',
    color: '#ffffff',
    height: '30px',
    paddingLeft: '25px',
    paddingRight: '25px',
    '&:hover': {
      backgroundColor: '#22a033',
    },
    '@media print': {
      display:'none'
    },
  },
})(Button);


export const CancelButton = withStyles({
  root: {
    borderRadius: '8px',
    backgroundColor: '#e0e0e0de',
    height: '30px',
    width: '120px',
    marginRight:'8px',
    '&:hover': {
      backgroundColor: '#bdbdbdde',
    },
  },
})(Button);


export const NoButton = withStyles({
  root: {
    borderRadius: '8px',
    backgroundColor: '#a02235',
    color: '#ffffff',
    height: '30px',
    width: '120px',
    marginRight:'8px',
    '&:hover': {
      backgroundColor: '#c92a75',
    },
  },
})(Button);

export const MapButton = withStyles({
  root: {
    marginBottom: '10px',
    borderRadius: '8px',
    backgroundColor: '#c13aac',
    color: '#ffffff',
    height: '30px',
    width: '120px',
    '&:hover': {
      backgroundColor: '#ad1695',
    },
    '@media print': {
      display:'none'
    },
  },
})(Button);


export const InfoChip = withStyles(() => ({
  root: {
    backgroundColor:'red',
    color:'white',
    marginRight:'3px',
    '& .MuiSvgIcon-root':{
      color:'white'
    }
    
  },
}))(Chip);


export const DuplicateChip = withStyles(() => ({
  root: {
    backgroundColor:'#2b0ac7',
    color:'white',
    marginRight:'3px',
    height:'25px',
    borderRadius: '8px',
    '& .MuiSvgIcon-root':{
      fontSize:'1.0rem',
      color:'white'
    },
    '&:hover':{
      backgroundColor:'#2b0ac7',
    },
    '&:focus':{
      backgroundColor:'#2b0ac7',
    },
    '@media print': {
      display:'none'
    },

  },
}))(Chip);


export const DeleteButton = withStyles(() => ({
  root: {
    color:'#009688',
    float:'Right',
    padding:'0px',
    marginRight:'10px',
    '& .MuiSvgIcon-root':{
      color:'#009688'
    },
  },
}))(IconButton);


export const FlagButton = withStyles(() => ({
  root: {
    backgroundColor:'#f2b50f',
    color:'white',
    height:'25px',
    marginRight:'3px',
    borderRadius:'8px',
    textTransform:'none',
    '& .MuiSvgIcon-root':{
      color:'white',
      fontSize:'1.0rem'
    },
    '&:hover':{
      backgroundColor:'#f2b50f',
    },
    '&:focus':{
      backgroundColor:'#f2b50f',
    },
    '@media print': {
      display:'none'
    },
  },
}))(Button);

export const FlagChip = withStyles(() => ({
  root: {
    marginLeft: '5px', 
    backgroundColor: 'white', 
    color: 'black',
    height:'20px'
  },
}))(Chip);

export const ApproveButton = withStyles({
  root: {
    borderRadius: '8px',
    backgroundColor: '#2AC940',
    color: '#ffffff',
    height: '30px',
    width: '120px',
    '&:hover': {
      backgroundColor: '#22a033',
    },
    '@media print': {
      display:'none'
    },
  },
})(Button);


export const RejectButton = withStyles({
  root: {
    borderRadius: '8px',
    backgroundColor: '#FF5F58',
    color: '#ffffff',
    height: '30px',
    width: '120px',
    '&:hover': {
      backgroundColor: '#c34c46',
    },
    '@media print': {
      display:'none'
    },
  },
})(Button);

export const DescChip = withStyles(() => ({
  root: {
    backgroundColor:'black',
    color:'white',
    marginRight:'3px',
    '& .MuiSvgIcon-root':{
      color:'white'
    }
  },
}))(Chip);

export const FileChip = withStyles(() => ({
  root: {
    backgroundColor:'#8bc1e0',
    color:'white',
    marginRight:'3px',
    '& .MuiSvgIcon-root':{
      color:'white'
    },
    '&:hover':{
      backgroundColor:'#8bc1e0',
    },
    '&:focus':{
      backgroundColor:'#8bc1e0',
    }
  },
}))(Chip);

export const FirstList = withStyles({
  root: {
   '& .MuiListItem-dense': {
      paddingTop:'0px',
      paddingBottom:'0px',
  },
}
})(List);

export const ListCustom = withStyles(() => ({
  root:{
    '& .MuiListItem-gutters':{
      paddingTop:'0px',
      paddingBottom:'0px',
    },
  }
}))(List);

export const CustomGrid = withStyles(() => ({
  root: {
    paddingLeft:'20px'
  },
}))(Grid);

export const CustomAccordion = withStyles({
  root:{
    borderRadius:'8px',
    marginBottom:'10px',
    '&.MuiAccordion-root:before': {
      position:'fixed'
    },
    '&.MuiAccordion-root.Mui-expanded':{
      marginTop:'0px',
      marginBottom:'10px'
    }
  }
})(Accordion);

export const CustomAccordionSummary = withStyles({
  root: {
    minHeight:'35px',
    maxHeight:'35px',
    padding:'0px 20px 0px 20px !important',

    '&.Mui-expanded': {
      maxHeight:'35px',
    },
    '& .MuiAccordionSummary-content':{
      margin: '0px 0px',
      height: '36px',
      paddingTop: '6px',
    },
    '& .MuiAccordionSummary-content.Mui-expanded':{
      margin: '0px 0px !important',
    },
    '& .MuiIconButton-root':{
      padding:'8px',
    }
  },
})(AccordionSummary);

export const GridCustom = withStyles({
  root: {
    paddingTop:'5px',
  },
})(Grid);

export const AddChip = withStyles(() => ({
  root: {
    backgroundColor:'#3399FF',
    color:'white',
    height:'25px',
    borderRadius:'8px',
    '& .MuiSvgIcon-root':{
      fontSize:'1.0rem',
      color:'white'
    },
    '&:hover':{
      backgroundColor:'#3399FF',
    },
    '&:focus':{
      backgroundColor:'#3399FF',
    },
    '@media print': {
      display:'none'
    },
  },
}))(Chip);

export const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  daterangepickerControlSection: {
    maxWidth: '246px',
    margin: '30px auto',
    paddingTop: '50px'
  },
  circular: {
    color: '#FA9917',
    marginLeft: '45%'
  },
  circularsession: {
    color: '#FA9917',
    marginRight: '50px'
  },
  select: {
    width: '120px'
  },
  buttonGroup: {
    height: '30px',
    borderRadius: '8px',
    width: '120px',
    boxShadow: '0 0 black',
  },
  disableButtonLeft:{
    backgroundColor: 'white',
    minWidth: '45px',
    borderRadius: '24px 0 0 24px',
    borderRight: 0,
    border: '0 !important',
    height: 24,
    '& .MuiSvgIcon-root':{
      fontSize:'1.0rem'
    },
    '@media print': {
      display:'none'
    },
  },
  buttonLeft: {
    backgroundColor: 'white',
    padding: 0,
    minWidth: '45px',
    borderRadius: '24px 0 0 24px',
    borderRight: 0,
    boxShadow: '0 0 black',
    border: 0,
    height: 24,
    '& .MuiSvgIcon-root':{
      fontSize:'1.0rem'
    },
    '@media print': {
      display:'none'
    },
  },
  disableButtonRight:{
    backgroundColor: 'white',
    minWidth: '45px',
    borderRadius: '0 24px 24px 0',
    border: '0 !important',
    height: 24,
    '& .MuiSvgIcon-root':{
      fontSize:'1.0rem'
    },
    '@media print': {
      display:'none'
    },
  },
  buttonRight: {
    backgroundColor: 'white',
    padding: 0,
    minWidth: '45px',
    borderRadius: '0 24px 24px 0',
    boxShadow: '0 0 black',
    border: 0,
    height: 24,
    '& .MuiSvgIcon-root':{
      fontSize:'1.0rem'
    },
    '@media print': {
      display:'none'
    },
  },
  addButtonRight: {
    backgroundColor: 'white',
    width: '60px',
    borderRadius: '0 24px 24px 0',
  },
  gridContainer: {
    paddingLeft: '14px',
    paddingBottom: '5px'
  },
  titleContainer: {
    paddingTop: '13px',
    paddingLeft: '26px',
    paddingBottom: '0px',
  },
  title: {
    padding: '12px 12px 0px 12px',
  },
  navigation: {
    float:'right',  },
  font1:{
    fontSize: '15px',
    fontFamily: 'Manrope',
    fontWeight: 700,
    lineHeight: '20.49px',
  },
  font2:{
    fontSize: '15px',
    fontFamily: 'Manrope',
    fontWeight: 400,
    lineHeight: '20.49px',
  },
  font3:{
    fontSize: '13px',
    fontFamily: 'Manrope',
    fontWeight: 500,
    lineHeight: '20.49px',
  },
}));

export const useStylesSessionList = makeStyles((theme) => ({
  font1:{
    fontSize: '15px',
    fontFamily: 'Manrope',
    fontWeight: 700,
    lineHeight: '20.49px',
  },
  font2:{
    fontSize: '15px',
    fontFamily: 'Manrope',
    fontWeight: 400,
    lineHeight: '20.49px',
  },
  font3:{
    fontSize: '16px',
    fontFamily: 'Manrope',
    fontWeight: 700,
    lineHeight: '20.49px',
  },

  rootGrid:{
    paddingLeft:'20px',
    padding:'8px 0px 0px 0px'
  },
  
  accordion:{
    '& .MuiAccordionSummary-root': {
      padding:'0px !important',
    },
  },
  accordionSummary: {
    paddingTop:'10px',
    backgroundColor: '#D4E3F2',
    zIndex: '2 !important',
    fontFamily: 'Manrope !important',
    height:'25px',
    minHeight:'25px',
    "&:hover": { bgcolor: "transparent" }
  },
  expandCustom:{
    '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
      marginTop:'5px !important',
    },
    '& .MuiAccordionSummary-expandIcon': {
      marginBottom:'5px !important',
    },
  },
  settingsIconCustom:
  {
    marginTop:'-5px !important',
    textAlign:'end'
  },
  details: {
    display: 'block'
  },
  card:{
    borderRadius: '8px',
    backgroundColor:'#FFFFFF',
    minHeight:'35px',
    height:'auto',
    display: 'flow',
    alignItems: 'center',
    marginBottom:'7px',
    paddingLeft:'0',
    cursor:'pointer',
    paddingTop:'0',
  },
  cardFooter: {
    borderRadius: '8px',
    backgroundColor:'rgb(93 182 255)',
    color:'#FFFFFF',
    height:'35px',
    paddingTop:'0px',
    marginBottom:'10px',
    cursor:'pointer'
  },
  cardHeader: {
    backgroundColor: '#579bd382',
    color:'#FFFFFF',
    padding: '7px 0px',
    borderRadius: '8px',
    height:'35px',
    display: 'flex',
    alignItems: 'center',
    paddingLeft:'20px',
    margin:'15px 0 10px 0'
  },
  accordionHeader: {
    backgroundColor: '#8AA9CA',
    padding: '0px 0px 0px 0px',
  },
  cardContent: {
    padding: '0px'
  },
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'hidden',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
  subHeader: {
    backgroundColor: '#D4E3F2',
    zIndex: '2 !important',
    fontFamily: 'Manrope !important',
  },
  
  accordionSubHeader: {
    backgroundColor: '#D4E3F2',
    zIndex: '2 !important',
    Height: '30px',
    fontFamily: 'Manrope !important',
  },
  subGrid: {
      maxWidth:'70.5%',
  },
  subGridTimesheet: {
    maxWidth:'72%'
  },
  totalGrid: {
    maxWidth:'70%',
  },
  totalGridTimesheet: {
  maxWidth:'70%'
  },
  
  cardActions: {
    width: '100%',
    backgroundColor: '#39F',
    color: '#fff'
  },
  circular: {
    color: '#FA9917',
  },
  cardDetailContent:
  {
    padding: '0px !important',
    borderRadius: '8px',
    backgroundColor: 'antiquewhite',
  },
  cardDetail:
  {
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingBottom: '20px',
  },
  cardDetailFileContent:
  {
    padding: '0px !important',
    borderRadius: '8px',
    backgroundColor: '#e6eaff',
  },
  cardSubDetail:
  {
    padding: '0px !important',
    borderRadius: '8px',
    backgroundColor: '#FFF',
    height: '90%'
  },
  cardSubDetailContent:
  {
    padding: '0px !important',
  },
  testInput:
  {
    backgroundColor: '#FFF',
    width: '100%',
    height: '90%',
  },
  checkedCustom:
  {
    marginTop:'-10px',
  },
  checkedStatus:
  {
    color: 'green !important'
  },
  checkedStatusFailed:
  {
    color: 'red !important'
  },
  iconButton:
  {
    padding:'5px'
  },
  Chip: {
    backgroundColor: '#D0F2D4 !important',
    color: 'black !important',
    position: 'sticky',
    height:'22px',
    fontFamily: 'Manrope !important'
  },
  text: {
    fontSize: '14px',
    marginLeft: '-20px',
  },
  textTimesheet: {
    fontSize: '14px',
    color:' #333942'
  },
  dividerStyleRed: {
    height: '2px',
    backgroundColor: '#f1b4b4'
  },
  gridCustom: {
    display: 'flex',
    alignItems: 'center'
  },
  gridSpace:{
    padding:'5px'
  },
  grid3: {
    flexBasis: '24.9%',
    maxWidth: '24.9%',
  },
  grid1: {
    flexBasis: '8.4%',
    maxWidth: '8.4%',
  },
  grid1custom: {
    flexBasis: '10.7%',
    maxWidth: '10.7%',
  },
  grid2: {
    flexBasis: '16.5%',
    maxWidth: '16.5%',
  },
  grid2customdate: {
    flexBasis: '17.1%',
    maxWidth: '17.1%',
  },
  grid2customcustomer: {
    flexBasis: '15.9%',
    maxWidth: '15.9%',
  },
  grid2customactivity: {
    flexBasis: '16%',
    maxWidth: '16%',
  },
  grid1customtime: {
    flexBasis: '8%',
    maxWidth: '8%',
  },
  grid2custombreak: {
    flexBasis: '15.9%',
    maxWidth: '15.9%',
  },

  gridbutton: {
    flexBasis: '9%',
    maxWidth: '12%',
  },

}));

export const useStylesSessionListDetails = makeStyles((theme) => ({
  font1:{
    fontSize: '15px',
    fontFamily: 'Manrope',
    fontWeight: 700,
    lineHeight: '20.49px',
  },
  font2:{
    fontSize: '15px',
    fontFamily: 'Manrope',
    fontWeight: 400,
    lineHeight: '20.49px',
  },
  font3:{
    fontSize: '13px',
    fontFamily: 'Manrope',
    fontWeight: 500,
    lineHeight: '20.49px',
  },
  details: {
    display: 'block'
  },
  card: {
    borderRadius: '8px',
    backgroundColor: '#FFF0DD',
  },
  cardHeader: {
    backgroundColor: '#8AA9CA',
    color: '#FFF',
    padding: '0px 16px 0px 0px'
  },
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
    
  },
  subHeader: {
    backgroundColor: '#D4E3F2',
  },
  cardActions: {
    width: '100%',
    backgroundColor: '#39F',
    color: '#fff'
  },
  circular: {
    color: '#FA9917',
  },
  cardDetailContent:
  {
    padding: '0 !important',
    borderRadius: '8px',
    backgroundColor: '#FFF4E6',
  },
  cardDetailFileContent:
  {
    padding: '0px !important',
    borderRadius: '8px',
    backgroundColor: '#e6eaff',
  },
  cardDetailSessionOptionsContent:
  {
    padding: '0px !important',
    borderRadius: '8px',
    backgroundColor: '#e6fffc',
  },
  cardDetail:
  {
    borderBottomLeftRadius:'8px',
    borderBottomRightRadius:'8px',
    paddingRight: '20px',
    paddingLeft: '0px',
    paddingTop: '4px',
    paddingBottom: '10px',
  },
  cardSubDetail:
  {
    padding:'0',
    borderRadius: '8px',
    backgroundColor: '#FFF',
    boxShadow: '0 0 black'
  },
  cardNotesDetail:
  {
    padding:'0',
    borderRadius: '8px',
    backgroundColor: '#FFF',
    boxShadow: '0 0 black'
  },
  cardSubDetailContent:
  {
    padding: '0px !important',
    boxShadow: '0 0 black'
  },
  cardSubDetailSmoko:
  {
    padding: '0px !important',
    borderRadius: '8px',
    backgroundColor: '#D0F2D4',
  },
  cardSubDetailContentSmoko:
  {
    padding: '0px !important',
  },
  testInput:
  {
    backgroundColor: '#FFF',
    width: '100%',
    height: '90%',
  },
  checkedStatus:
  {
    color: 'green !important'
  },
  checkedStatusFailed:
  {
    color: 'red !important'
  },
  Chip: {
    backgroundColor: '#D0F2D4 !important',
    color: 'black !important',
  },
  gridContainer: {
    padding: '0 10px 0px 15px',
  },
}));

  export const styles = makeStyles(theme => ({
    dayWrapper: {
      position: "relative",
    },
    day: {
      width: 36,
      height: 36,
      fontSize: theme.typography.caption.fontSize,
      margin: "0 2px",
      color: "inherit",
    },
    customDayHighlight: {
      position: "absolute",
      top: 0,
      bottom: 0,
      left: "2px",
      right: "2px",
      border: `1px solid ${theme.palette.secondary.main}`,
      borderRadius: "50%",
    },
    nonCurrentMonthDay: {
      color: theme.palette.text.disabled,
    },
    highlightNonCurrentMonthDay: {
      color: "#676767",
    },
    highlight: {
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    firstHighlight: {
      extend: "highlight",
      borderTopLeftRadius: "50%",
      borderBottomLeftRadius: "50%",
    },
    endHighlight: {
      extend: "highlight",
      borderTopRightRadius: "50%",
      borderBottomRightRadius: "50%",
    },
  })
);
