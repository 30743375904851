import React, { useState, useEffect, useRef } from 'react';
import { 
  Typography, 
  Container, 
  Grid,
  Button,
  Chip,
  Tooltip,
  withStyles,
} from '@material-ui/core';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import AddIcon from '@material-ui/icons/Add';
import Page from 'src/components/Page';
import directus from '../../services/directus';
import InfoIcon from '@material-ui/icons/Info';
import SessionList from '../SessionList/SessionList';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { getEmployee, getConfiguration } from 'src/utils/sessions';
import { useStyles, CustomChip, AddChip, CustomGridApproval } from './style.js';
import linq from "linq";
import { useSnackbar } from 'notistack';
import ErrorMessage from '../Components/ErrorMessage';
import EventEmitter from 'src/utils/EventEmitter';
import Skeleton from '@mui/material/Skeleton';
import { DirectusEmployee } from 'src/views/Components/LocalstorageData.js';

const directusEmployeeData = DirectusEmployee();

const TimesheetView = () => {
  /* eslint-disable no-unused-vars*/
  const navigate = useNavigate();
  const childRef = useRef(); 
  const classes = useStyles();
  const [EndDateState, setEndDateState] = useState(null);
  const [StartDateState, setStartDateState] = useState(null);
  const [items, setItems] = useState({ employees: null, timesheets: null, sessions: null, select: null });
  const [left, setLeft] = useState(null);
  const [right, setRight] = useState(null);
  const [employeeIdChosen, setEmployeeIdChosen] = useState(null);
  const [timesheetIdChosen, setTimesheetIdChosen] = useState(null);
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  const [bankHours, setBankHours] = useState([]);
  const [claimBankHours, setClaimBankHours] = useState([]);
  const [saveSessionLoading, setSaveSessionLoading] = React.useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const CustomTooltip = withStyles({
    tooltip: {
      fontSize: "16px",
      maxWidth: 500,
      backgroundColor: "gray"
    }
  })(Tooltip);

  let month = ['','January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];


  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    let isSubscribed = true
    if (isSubscribed) {
      loadTimesheets();
    }
    const saveEditSessionDrawer = async () => {
      setSaveLoading(true);
      loadTimesheets();
    }
    const listenersaveEditSessionDrawer = EventEmitter.addListener('saveEditSessionDrawer', saveEditSessionDrawer);


    const saveAddSessionDrawer = async (eventData) => {
      setSaveLoading(true);
      setSaveSessionLoading(eventData.text);
      loadTimesheets();
    }
    const listenersaveAddSessionDrawer = EventEmitter.addListener('saveAddSessionDrawer', saveAddSessionDrawer);

    return () =>{
      listenersaveEditSessionDrawer.remove();
      listenersaveAddSessionDrawer.remove();
      isSubscribed = false
    }
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps*/

  const loadTimesheets = async (dataId) => {
    setLoading(true);

    let timesheetId = dataId?dataId:window.location.pathname.split('/').pop();
    let filterTimesheet;
    let timesheetData;

    var employee = await getEmployee();
    if(timesheetId !== 'timesheet')
    {
      if(dataId)
      {
        filterTimesheet = {id: { eq: dataId }}
      }
      else
      {
        filterTimesheet = {id: { eq: timesheetId }}
      }

      var TimesheetsResult;
      try
      {
        TimesheetsResult = await directus.getItems('timesheets', {
          fields: '*,employee.id,employee.user.*,',
          filter: filterTimesheet,
          single: 1
        });
      }
      catch(e)
      {
        enqueueSnackbar(ErrorMessage(e), {anchorOrigin:{vertical: 'top',horizontal: 'right'}, variant:'error',autoHideDuration:3200});
      }
    }
    else
    {
      var ConfigurationResult = await getConfiguration();
      let config_day = moment(moment().day(ConfigurationResult.first_day_of_week)._d);
      if(moment(moment().format('YYYY-MM-DD')).isBefore(moment(moment().day(ConfigurationResult.first_day_of_week)._d).format('YYYY-MM-DD')))
      {
        filterTimesheet = {
          end_time: { lte : moment(moment().day(ConfigurationResult.first_day_of_week)._d).format('YYYY-MM-DD HH:mm:ss') },
          employee: { eq: employee.id}
        }
      }
      else
      {
        filterTimesheet = {
          start_time: { lte : moment(moment().day(ConfigurationResult.first_day_of_week)._d).format('YYYY-MM-DD HH:mm:ss') },
          employee: { eq: employee.id}
        }
      }

      try{
        TimesheetsResult = await directus.getItems('timesheets', {
          fields: '*,employee.id,employee.user.*',
          filter: filterTimesheet,
          single: 1,
          sort: "-start_time"
        });

      } catch (e)
      {
        console.log(e.message);
        if(e.message === 'Item not found')
        {
          try
          {
            var addTimesheets = await directus.createItem('timesheets', {
              employee: employee.id,
              start_time: moment(config_day).format('YYYY-MM-DD 00:00:00'),
              end_time: moment(moment(config_day).add(6,'d')).format('YYYY-MM-DD 23:59:59'),
              total_minutes: 0,
              status:'pending'
            });
          }
          catch(e)
          {
            enqueueSnackbar(ErrorMessage(e), {anchorOrigin:{vertical: 'top',horizontal: 'right'}, variant:'error',autoHideDuration:3200});
          }

          try
          {
            TimesheetsResult = await directus.getItems('timesheets', {
              fields: '*,employee.id,employee.user.*',
              filter: {id: addTimesheets.data.id},
              single: 1,
              sort: "-start_time"
            });
          }
          catch(e)
          {
            enqueueSnackbar(ErrorMessage(e), {anchorOrigin:{vertical: 'top',horizontal: 'right'}, variant:'error',autoHideDuration:3200});
          }
        }
      }
    }
    timesheetId = TimesheetsResult.data.id;
    timesheetData = TimesheetsResult.data;
    window.timesheetId = TimesheetsResult.data.id;

    try{
      var LeftData = await directus.getItems('timesheets', {
        fields: '*',
        filter: {
          end_time: { lte: timesheetData.start_time},
          employee: { eq: timesheetData.employee.id },
        },
        single: 1,
        sort: "-start_time"
      });
    } catch (e)
    {
      console.log(e.message);
    }
    
    try{
      var RightData = await directus.getItems('timesheets', {
        fields: '*',
        filter: {
          start_time: { gte: timesheetData.end_time},
          employee: { eq: timesheetData.employee.id },
        },
        single: 1,
        sort: "start_time"
      });
    } catch (e)
    {
      console.log(e.message);
    }

    LeftData?setLeft(LeftData.data):setLeft(null);
    RightData?setRight(RightData.data):setRight(null);
    setItems(prevItems => ({
      ...prevItems,
      timesheets: timesheetData
    }));

    let startDate = timesheetData.start_time;
    let subStrStartDate = startDate.substring(0,10);
    let StartDateResult = subStrStartDate.split("-");

    setStartDateState(StartDateResult[2] +" "+ month[parseInt(StartDateResult[1])] +" "+ StartDateResult[0]);

    let endDate = timesheetData.end_time;
    let subStrEndDate = endDate.substring(0,10);
    let EndDateResult = subStrEndDate.split("-");
    setEndDateState(EndDateResult[2] +" "+ month[parseInt(EndDateResult[1])] +" "+ EndDateResult[0]);
    setEmployeeIdChosen(timesheetData.employee.id);
    setTimesheetIdChosen(timesheetId);

    if(dataId)
    {
      EventEmitter.emit('moveTimesheets', { text: 'endLoading',timesheets:timesheetData })
    }

    let isSubscribed = true
    if (isSubscribed || saveSessionLoading) {
      loadSession(timesheetData.employee.id, timesheetId);
    }
    return () => isSubscribed = false
  };

  const loadSession = async (employeeId, timesheetId) => {

    let filData= {
        employee: { eq: employeeId },
        timesheet: { eq: timesheetId}
    }
    try
    {
      var SessionResult = await directus.getItems('sessions', {
        fields: '*,customer.*,activity.*,resources.*.*,attachments.*.*,session_type.*.*,options.session_option.*,options.value,options.id',
        filter: filData,
        sort: "-session_date, -session_start_time"
      });
    }
    catch(e)
    {
      enqueueSnackbar(ErrorMessage(e), {anchorOrigin:{vertical: 'top',horizontal: 'right'}, variant:'error',autoHideDuration:3200});
    }

    /* eslint-disable array-callback-return*/
    SessionResult.data.map((data,i) => {
      data.resources.map((resource, index) => {
        if(resource.resource === null)
        {
          SessionResult.data[i].resources.splice(index,1);
        }
      })
    })
    /* eslint-enable array-callback-return*/

    setItems(prevItems => ({
      ...prevItems,
      sessions: SessionResult.data
    }));

    var bank_hours = linq.from(SessionResult.data)
    .where(w => w.session_type.name === 'Bank Hours')
    .toArray();

    var claim_bank_hours = linq.from(SessionResult.data)
    .where(w => w.session_type.name === 'Claim Banked Hours')
    .toArray();

    setBankHours(bank_hours);
    setClaimBankHours(claim_bank_hours);

    setLoading(false);
    setSaveLoading(false);
    callChild(SessionResult.data)
  };

  function callChild(data)
  {
    if(childRef.current)
    {
      childRef.current.getAlert(data);
    }
  }

  const moveTimesheet = async (status) =>
  {
    if(status === 3)
    {
      setLoading(true);

      try
      {
        var addTimesheets = await directus.createItem('timesheets', {
          employee: employeeIdChosen,
          start_time: moment(items.timesheets.end_time).add(1, 'd').format("YYYY-MM-DD 00:00:00"),
          end_time: moment(items.timesheets.end_time).add(7, 'd').format("YYYY-MM-DD 23:59:59"),
          total_minutes: 0,
          status:'pending'
        });
        navigate('/timesheet/'+addTimesheets.data.id)
        loadTimesheets(addTimesheets.data.id)
      }
      catch(e)
      {
        enqueueSnackbar(ErrorMessage(e), {anchorOrigin:{vertical: 'top',horizontal: 'right'}, variant:'error',autoHideDuration:3200});
      }  
    }
    else if(status === 1)
    {
      loadTimesheets(left.id)
    }
    else
    {
      loadTimesheets(right.id)
    }

    EventEmitter.emit('moveTimesheets', { text: 'startLoading' })

  }

  function time_convert(num)
  {
    num = Math.abs(num);
    var hours = Math.floor(num / 60);  
    var minutes = num % 60;

    return (<span><b>{!isNaN(hours) ? Math.abs(hours) : ''}</b> hr <b>{!isNaN(minutes)  ? minutes : ''}</b> min</span>);
  }

  const dashboardDrawer = (data,timesheets) => {
    window.DashboardGlobal = true;
    EventEmitter.emit('contentWidth', {
      data: window.DashboardGlobal
    })

    EventEmitter.emit('dashboardDrawer', {
      text: data,
      timesheets:timesheets,
    })
  }

  return (
    <Page className={classes.root} title="My Timesheet">
      <Container maxWidth={false} style={{paddingLeft:'20px',paddingRight:'20px'}}>
        {loading  && !saveLoading ?
          <>
            <Grid container spacing={0}>
              <CustomGridApproval style={{ whiteSpace: 'nowrap' }} item xs={2}>
                <Skeleton animation="wave" variant="rounded" height={25} style={{borderRadius:'8px',margin:'2px 2px'}}/>
              </CustomGridApproval>
              <CustomGridApproval style={{ whiteSpace: 'nowrap' }} item xs={1}>
                <Skeleton animation="wave" variant="rounded" height={25} style={{borderRadius:'8px',margin:'2px 2px'}}/>
              </CustomGridApproval>
            </Grid>
            <Grid container spacing={0}>
              <CustomGridApproval style={{ whiteSpace: 'nowrap' }} item xs={1}>
                <Skeleton animation="wave" variant="rounded" height={25} style={{borderRadius:'8px',margin:'2px 2px'}}/>
              </CustomGridApproval>
              <CustomGridApproval style={{ whiteSpace: 'nowrap' }} item xs={2}>
                <Skeleton animation="wave" variant="rounded" height={25} style={{borderRadius:'8px',margin:'2px 2px'}}/>
              </CustomGridApproval>
              <CustomGridApproval style={{ whiteSpace: 'nowrap' }} item xs={2}>
                <Skeleton animation="wave" variant="rounded" height={25} style={{borderRadius:'8px',margin:'2px 2px'}}/>
              </CustomGridApproval>
              <CustomGridApproval style={{ whiteSpace: 'nowrap' }} item xs={1}>
                <Skeleton animation="wave" variant="rounded" height={25} style={{borderRadius:'8px',margin:'2px 2px'}}/>
              </CustomGridApproval>
              <CustomGridApproval style={{ whiteSpace: 'nowrap' }} item xs={1}>
                <Skeleton animation="wave" variant="rounded" height={25} style={{borderRadius:'8px',margin:'2px 2px'}}/>
              </CustomGridApproval>
              <CustomGridApproval style={{ whiteSpace: 'nowrap' }} item xs={2}>
                <Skeleton animation="wave" variant="rounded" height={25} style={{borderRadius:'8px',margin:'2px 2px'}}/>
              </CustomGridApproval>
              <CustomGridApproval style={{ whiteSpace: 'nowrap' }} item xs={3}>
                <Skeleton animation="wave" variant="rounded" height={25} style={{borderRadius:'8px',margin:'2px 2px'}}/>
              </CustomGridApproval>
            </Grid>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <Skeleton animation="wave" variant="rounded" height={45} style={{borderRadius:'8px',marginBottom:'10px'}}/>
              </Grid>
              <Grid item xs={12}>
                <Skeleton animation="wave" variant="rounded" height={45} style={{borderRadius:'8px',marginBottom:'10px'}}/>
              </Grid>
              <Grid item xs={12}>
                <Skeleton animation="wave" variant="rounded" height={45} style={{borderRadius:'8px',marginBottom:'10px'}}/>
              </Grid>
              <Grid item xs={12}>
                <Skeleton animation="wave" variant="rounded" height={45} style={{borderRadius:'8px',marginBottom:'10px'}}/>
              </Grid>
              <Grid item xs={12}>
                <Skeleton animation="wave" variant="rounded" height={45} style={{borderRadius:'8px',marginBottom:'10px'}}/>
              </Grid>
              <Grid item xs={12}>
                <Skeleton animation="wave" variant="rounded" height={45} style={{borderRadius:'8px',marginBottom:'10px'}}/>
              </Grid>
            </Grid>
          </>
        :
        items.timesheets?
          <div className="control-pane">
            <div className="control-section">
              <Grid container spacing={3} justifyContent="space-between" className={classes.gridContainer}>
                <Grid item xs={10}>
                  <span>
                    {left?
                      <Link to={`/timesheet/${left.id}`}>
                        <Button id="timesheet_date_range_prev" variant="outlined" size="small" onClick={()=>moveTimesheet(1)} className={classes.buttonLeft}>
                          <ArrowBackIosRoundedIcon />
                        </Button>
                      </Link>
                    :
                      <Button id="timesheet_date_range_prev" variant="outlined" size="small" disabled={true} className={classes.disableButtonLeft}>
                        <ArrowBackIosRoundedIcon />
                      </Button>
                    }
                  </span>
                  <Button id="timesheet_date_range" variant="outlined" size="small" className={classes.buttonText}>
                    <Typography id="timesheet_date_range_text" variant="body2" align="center">
                      {StartDateState} - {EndDateState} 
                    </Typography>
                  </Button>
                                    
                  <span>
                    {right?
                      <Link to={`/timesheet/${right.id}`}>
                        <Button id="timesheet_date_range_next" variant="outlined" size="small" onClick={()=>moveTimesheet(2)} className={classes.buttonRight}>
                          <ArrowForwardIosRoundedIcon />
                        </Button>
                      </Link>
                    :
                      <Button id="timesheet_date_range_next" variant="outlined" size="small" onClick={()=>moveTimesheet(3)} className={classes.buttonRight} disabled={loading}>
                        <AddIcon />
                      </Button>
                    }
                  </span>
                  
                  {items.timesheets.submission_notes?
                    <span>
                      <CustomTooltip title={<Typography variant="h6">{items.timesheets.submission_notes}</Typography>}>
                        <InfoIcon  
                          fontSize='small' 
                        />
                      </CustomTooltip>
                    </span>
                  :''}

                  {items.timesheets.reviewer_notes?
                    <span>
                      <CustomTooltip title={<Typography variant="h6">{items.timesheets.reviewer_notes}</Typography>}>
                        <InfoIcon 
                          color='error' 
                          fontSize='small' 
                        />
                      </CustomTooltip>
                    </span>
                  :''}

                  {items.timesheets.status?
                    <span>
                      <CustomChip 
                        id="timesheet_status_chip"
                        style={{backgroundColor: 
                          items.timesheets.status==='pending'?
                            "#d4e3f2":items.timesheets.status==='awaiting_approval'?
                              "#fff0dd":items.timesheets.status==='rejected'?
                                "#ffdbd9":items.timesheets.status==='approved'?
                                  "#daf0dc":items.timesheets.status==='sent'?
                                  "deepskyblue":''
                        }}
                        className={classes.Chip} 
                        label={ 
                          <Typography id="timesheet_status_text" variant="h5">
                            {items.timesheets.status==='pending'?'Pending':''}
                            {items.timesheets.status==='awaiting_approval'?'Awaiting Approval':''}
                            {items.timesheets.status==='approved'?'Approved':''}
                            {items.timesheets.status==='rejected'?'Rejected':''}
                            {items.timesheets.status==='sent'?'Sent to Payroll':''}
                          </Typography>  
                        } 
                      />

                      {bankHours.length > 0?
                        <Chip 
                          size="small"
                          style={{backgroundColor: "#d4e3f2"}}
                          label={ 
                            <Typography variant="h6">
                              {directusEmployeeData.rdo_hours_enable?"RDO Time Accrued: ":"Banked Hours:"} {time_convert(bankHours[0].duration)}
                            </Typography>  
                          } 
                        />
                      :''}

                      {claimBankHours.length > 0?
                        <Chip 
                          size="small"
                          style={{backgroundColor: "#FFF4E6"}}
                          label={ 
                            <Typography variant="h6">
                              {directusEmployeeData.rdo_hours_enable?"RDO Time Taken: ":"Claim Banked Hours:"} {time_convert(claimBankHours[0].duration)}
                            </Typography>  
                          } 
                        />
                      :''}
                    </span>
                  :''}
                </Grid>
                <Grid item xs={2} style={{display: 'flex', justifyContent: 'right'}}>
                  {items.timesheets.status==='pending'?
                    <AddChip
                      id="add_session_btn"
                      size="medium"
                      style={{marginTop: '20px'}}
                      icon={<AddIcon />}
                      label="Add Timesheet Entry" 
                      onClick={()=>dashboardDrawer(6,items.timesheets)}
                    />
                  :''}
                </Grid>
              </Grid>

              {items.sessions && employeeIdChosen && items.timesheets?
                <SessionList
                  setLoading={setLoading} 
                  saveLoad={saveLoading}
                  timesheets={items.timesheets}
                  sessions={items.sessions}
                  loadTimesheets={loadTimesheets}
                  ref={childRef}
                  loadSession={loadSession}
                  employeeIdChosen={employeeIdChosen}
                  timesheetIdChosen={timesheetIdChosen}
                  right={right}
                  page='timesheet'
                  totalCountLatLong={0}
                  marker={null}
                />
              :''}
            </div>
          </div>
          :
        ''}
      </Container>
    </Page>
  );
};

export default TimesheetView;