import directus from '../../services/directus';
import linq from "linq";

export const controlLoadEmployee = async () => {
      var result = await directus.getItems('employees', {
        fields: 'customers.customer.*,user.*, *',
        filter:{
          status : { eq: 'published' }
        },
        limit: -1,
        sort: "code"
      
      });
    return result;
  };

export const controlLoadCustomers = async (data,page) => {
  let filterData;
  if(data)
  {
    filterData = {
      fields: '*, managers.employee.user.*, managers.employee.*, managers.*',
      meta: "filter_count",
      limit : localStorage.getItem("customers_page")?localStorage.getItem("customers_page"):50,
      page: page,
      filter: {
        'name': {like: data}, 
        'code': {'logical':'or', like: data},
        status : { eq: 'published' }
      },
      sort:'name',
    }
  }
  else
  {
    filterData = {
      fields: '*, managers.employee.user.*, managers.employee.*, managers.*',
      filter: { 
        status : { eq: 'published' },
      },
      meta: "filter_count",
      limit : localStorage.getItem("customers_page")?localStorage.getItem("customers_page"):50,
      page: page,
      sort:'name',
    }; 
  }
  
    var CustomersResults = await directus.getItems('customers', filterData);
    
    CustomersResults.data.map((customerResult)=>{
      customerResult.managers = linq.from(customerResult.managers).where(x => x.employee !== null).toArray();

      return customerResult.managers.sort((a, b) => 
        a.employee !== null && b.employee !== null?
        a.employee.user !==null &&  b.employee.user !==null? 
        a.employee.user.first_name && b.employee.user.first_name? 
        a.employee.user.first_name > b.employee.user.first_name ? 1 : -1 : '': '' :'');
    })

    return CustomersResults;
};
