import directus from '../../services/directus';
import linq from "linq";

export const CustomerController = async() => {
  const result = await directus.getItems('customers', {
    fields: '*',
    limit: -1,
    filter: { status: {eq: 'published'} },
    sort: "code"
  });
  return result;
};

export const controlLoadActivities = async (data,page) => {
  let filterData;

  if(data)
  {
    filterData = {
      fields: '*.*.*',
      meta: "filter_count",
      limit: localStorage.getItem("activities_page")?localStorage.getItem("activities_page"):50,
      page: page,
      filter: {
        status : {eq: 'published' },
        'name': {like: data}, 
         status : { eq: 'published' },
         'selectable': {'nempty': ''},
      },
      sort:'name',
    }
  }
  else
  {
    filterData = {
      fields: '*.*.*',
      filter: { 
        status : { eq: 'published' },
        'selectable': {'nempty':''},
      },
      meta: "filter_count",
      limit: localStorage.getItem("activities_page")?localStorage.getItem("activities_page"):50,
      page: page,
      sort:'name'
    }; 
  }
    var result = await directus.getItems('activities', filterData);

     // sort code for activities
     result.data.map((activitieslist)=>{
      activitieslist.customers = linq.from(activitieslist.customers).where(x => x.customers_id !== null).toArray();
      activitieslist.customers.sort((a, b) => a.customers_id.code > b.customers_id.code ? 1 : -1);
    })    
    return result;
};