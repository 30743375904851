import directus from '../../services/directus';
import linq from "linq";

export const SessionOptionController = async (data,page) => {
  let filterData;

  if (data) {
    filterData = {
      fields: '*.*.*',
      meta: "filter_count",
      limit: localStorage.getItem("session_options_page")?localStorage.getItem("session_options_page"):50,
      page: page,
      filter: {
        'code': { like: data },
        'name': { 'logical': 'or', like: data },
         status : { eq: 'published' },
      },
      sort: "name"
    }
  }
  else {
    filterData = {
      fields: '*.*,session_types.*.*,customers.*.*,awards.*.*',
      meta: "filter_count",
      limit: localStorage.getItem("session_options_page")?localStorage.getItem("session_options_page"):50,
      page: page,
      filter: { 
        status : { eq: 'published' },
      },
      sort: "name"
    };
  }

  var result = await directus.getItems('session_options',filterData);

  // sort award code
  result.data.map((award)=>{
    award.awards = linq.from(award.awards).where(x => x.awards_id !== null).toArray();
    award.awards.sort((a, b) => a.awards_id.code > b.awards_id.code ? 1 : -1);
  })

  // sort customer code
  result.data.map((customers)=>{
    customers.customers = linq.from(customers.customers).where(x => x.customers_id !== null).toArray();
    customers.customers.sort((a, b) => a.customers_id.code > b.customers_id.code ? 1 : -1);
  })

  // sort session type name
  result.data.map((session_type)=>{
    session_type.session_types.sort((a, b) => a.session_types_id.name > b.session_types_id.name ? 1 : -1);
  })

  return result;
};

export const SessionOptionListController = async () => {

  var result = await directus.getItems('session_options', {
    fields: '*.*,session_types.*.*,customers.*.*,awards.*.*',
    filter: { 
      status : { eq: 'published' },
    },
    sort: "name"
  });
  return result;
  
};


export const CustomersController = async () => {
  var result = await directus.getItems('customers', {
    fields: '*',
    limit: -1,
    sort: "code"
  });
return result;
};

export const AwardsController = async () => {
  var result = await directus.getItems('awards', {
    fields: '*',
    limit: -1,
    sort: "code"
  });
return result;
};

export const SessionTypesController = async () => {
  var result = await directus.getItems('session_types', {
    fields: '*',
    limit: -1,
    sort: "code"
  });
return result;
};