import React, { useState, useEffect } from 'react';
import { 
  Box,
  Typography, 
  Container, 
  CircularProgress,
  Grid,
  Button,
  Menu,
  MenuItem,
} from '@material-ui/core';
import Page from 'src/components/Page';
import { enableRipple } from '@syncfusion/ej2-base';
import FilterListIcon from '@material-ui/icons/FilterList';
import SortIcon from '@material-ui/icons/Sort';
import directus from '../../services/directus';
import { getEmployee } from 'src/utils/sessions';
import ApprovalTimesheetList from './ApprovalTimesheetList';
import linq from "linq";
import { useStyles } from './style.js';
import { useSnackbar } from 'notistack';
import ErrorMessage from '../Components/ErrorMessage';

let sortId='first_name';
let sortType='asc';

const ApprovalListView = () => {
  const classes = useStyles();
  const [initialized, setInitialized] = useState(false);
  const [items, setItems] = useState({ employees: null, timesheets: null, sessions: null });
  const [employeesData, setEmployeesData] = useState(null);
  const [status, setStatus] = useState(1);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorEl2, setAnchorEl2] = React.useState(null);

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  enableRipple(true);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      loadCustomerEmployee();
      // loadEmployees();
    }
  });

  const loadCustomerEmployee = async () => {
    var employee = await getEmployee();
    try
    {
      var CustomerEmployeeResult = await directus.getItems('customers_employees', {
        fields: '*',
        filter: {
          employee: { eq: employee.id }
        }
      });
    }
    catch(e)
    {
      enqueueSnackbar(ErrorMessage(e), {anchorOrigin:{vertical: 'top',horizontal: 'right'}, variant:'error',autoHideDuration:3200});
    }

    if(CustomerEmployeeResult.data.length === 0)
    {
      employee = {
        ...employee,
        id: employee.id,
        title: employee.user.title,
        first_name:employee.user.first_name, 
        last_name:employee.user.last_name, 
        email:employee.user.email
      };

      loadTimeSheets(employee, status);
    }
    loadSession(CustomerEmployeeResult.data, employee);
  };

  const loadSession = async (employeeData, employee) => {
    let customerId=[];
    // eslint-disable-next-line array-callback-return
    employeeData.map((data,index)=>{
      customerId[index] = data.customer;
    })

    try
    {
      var SessionResult = await directus.getItems('sessions', {
        fields: 'employee.*,employee.user.*',
        filter: {
          customer: { in: customerId },
          "employee.position_level" : { eq : '0' }
        }
      });
    }
    catch(e)
    {
      enqueueSnackbar(ErrorMessage(e), {anchorOrigin:{vertical: 'top',horizontal: 'right'}, variant:'error',autoHideDuration:3200});
    }

    loadEmployees(SessionResult.data, employee);
    setItems(prevItems => ({
      ...prevItems,
      sessions: SessionResult.data
    }));
  };

  const loadEmployees = async (sessionData, employee) => {
    let employeesId=[];
    var employeeFiltered = [];
    // eslint-disable-next-line array-callback-return
    sessionData.map((data,index)=>{
      if(data.employee !== null && data.employee.position_level === '0' 
      // && data.employee.parent.id === employee.id 
      )
      {
        employeesId[index] = data.employee;
      }
    })

    employeesId.forEach(function(item){
      var i = employeeFiltered.findIndex(x => x.id === item.id);
      if(i <= -1){
        employeeFiltered.push({...item});
      }
    });
    
    employee = {
        ...employee,
        id: employee.id,
        title: employee.user.title,
        first_name:employee.user.first_name, 
        last_name:employee.user.last_name, 
        email:employee.user.email
      };

    let employeeResultData = [];
    //eslint-disable-next-line array-callback-return
    employeeFiltered.map((data, index) => {
      employeeResultData[index] = {
        ...data,
        id: data.id,
        title: data.user.title,
        first_name:data.user.first_name, 
        last_name:data.user.last_name, 
        email:data.user.email
      }
    })

    let employees = [employee, ...employeeResultData];
    employees.sort(compareValues(sortId,sortType));

    loadTimeSheets(employees, status);
    if (!employeeFiltered.error) {
      setItems(prevItems => ({
        ...prevItems,
        employees: employees
      }));
    }
  };

  const loadTimeSheets = async (employeeData, status) => {
    let employeesId = [];
    let filterData;
    
    if(employeeData.length > 0)
    {
      //eslint-disable-next-line array-callback-return
      employeeData.map((data,index)=>{
        employeesId[index] = data.id;
      })
    }
    
    setEmployeesData(employeeData);

    if (status === 1)
    {
      filterData = {
        employee: { in: employeesId },
        status: { eq: 'awaiting_approval'}
      }
    }
    else if(status === 2)
    {
      filterData = {
        employee: { in: employeesId },
        status: { eq: 'approved'}
      }
    }
    else if(status === 3)
    {
      filterData = {
        employee: { in: employeesId },
        status: { eq: 'rejected'}
      }
    }
    else if(status === 4)
    {
      filterData = {
        employee: { in: employeesId },
        status: { eq: 'pending'}
      }
    }
    else
    {
      filterData = {
        employee: { in: employeesId }
      }
    }

    try
    {
      var TimesheetsResult = await directus.getItems('timesheets', {
        fields: '*',
        filter: filterData,
        sort: "start_time",
        limit: -1
      });
    }
    catch(e)
    {
      enqueueSnackbar(ErrorMessage(e), {anchorOrigin:{vertical: 'top',horizontal: 'right'}, variant:'error',autoHideDuration:3200});
    }

    const filterTimesheet = linq.from(TimesheetsResult.data).orderByDescending(x=>x.status==='awaiting_approval').toArray();
    
    handleClose();

    setItems(prevItems => ({
      ...prevItems,
      timesheets: filterTimesheet
    }));
  };

  function filterStatus(input) {
    setStatus(input);
    loadTimeSheets(employeesData,input);
  }

  function filterSort(input) {
    if(sortId === input)
    {
      if(sortType === 'asc')
      {
        sortType = 'desc';
      }
      else if(sortType === 'desc')
      {
        sortType = 'asc';
      }
    }
    else
    {
      sortId = input;
      sortType = 'asc';
    }

    items.employees.sort(compareValues(input,sortType));
    setItems(prevItems => ({
      ...prevItems,
      employees: items.employees
    }));
    handleClose2();
  }

  function compareValues(key, order = 'asc') {
    return function innerSort(a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        // property doesn't exist on either object
        return 0;
      }
  
      const varA = (typeof a[key] === 'string')
        ? a[key].toUpperCase() : a[key];
      const varB = (typeof b[key] === 'string')
        ? b[key].toUpperCase() : b[key];
  
      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return (
        (order === 'desc') ? (comparison * -1) : comparison
      );
    };
  }

  return (
    <Page className={classes.root} title="History">
      <Container maxWidth={false}>
        <div className="control-pane">
          <div className="control-section">
            <Box mb={2}>
              <Typography color="textPrimary" variant="h3" className={classes.headerWidth}>
                History
              </Typography>
            </Box>
            <Grid container spacing={0} justify="space-between" className={classes.headerWidth}>
              <Grid item xs={1}>
                <Typography variant="h5">Title</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="h5">First Name</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="h5">Last Name</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="h5">Email</Typography>
              </Grid>
              <Grid item xs={3}>
                <div className={classes.filter}>
                  <Button
                    size="small"
                    onClick={handleClick}
                    startIcon={<FilterListIcon />}
                  >
                   <Typography variant="h6">Status</Typography> 
                  </Button>
                  <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={()=>filterStatus(1)}>Awaiting Approval</MenuItem>
                    <MenuItem onClick={()=>filterStatus(2)}>Approved</MenuItem>
                    <MenuItem onClick={()=>filterStatus(3)}>Rejected</MenuItem>
                    <MenuItem onClick={()=>filterStatus(4)}>Not Submitted</MenuItem>
                    <MenuItem onClick={()=>filterStatus(5)}>All</MenuItem>
                  </Menu>
                  &nbsp;&nbsp;&nbsp;
                  <Button
                    size="small"
                    onClick={handleClick2}
                    startIcon={<SortIcon />}
                  >
                  <Typography variant="h6">Sorting</Typography> 
                  </Button>
                  <Menu
                    anchorEl={anchorEl2}
                    keepMounted
                    open={Boolean(anchorEl2)}
                    onClose={handleClose2}
                  >
                    <MenuItem variant="subtitle1"  onClick={()=>filterSort('first_name')}>First Name</MenuItem>
                    <MenuItem variant="subtitle1" onClick={()=>filterSort('last_name')}>Last Name</MenuItem>
                    <MenuItem variant="subtitle1" onClick={()=>filterSort('email')}>Email</MenuItem>
                  </Menu>
                </div>
              </Grid>
            </Grid>
            {items.timesheets && items.employees?
              <ApprovalTimesheetList 
                employees={items.employees}
                sessions={items.sessions}
                timesheets={items.timesheets}
              />
            :
              <CircularProgress className={classes.circular}/>
            }
          </div>
        </div>
      </Container>
    </Page>
  );
};

export default ApprovalListView;
