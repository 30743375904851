import React from 'react';

const Logo = (props) => {
  return(
    <img
      alt="Logo"
      src={window.location.hostname.search('digitime') === -1?"/static/images/interax_logo.png":"/static/images/gt_logo.png"}
      // src={"/static/images/interax_logo.png"}
      {...props}
    />
  );
  
};

export default Logo;
