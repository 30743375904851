import { makeStyles } from '@material-ui/core';
import {  
  withStyles,
  Button,
  Chip,
  Grid,
  TextField
} from '@material-ui/core';

export const TextFieldNotes = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
      fontFamily: "Manrope",
    },
  },
})(TextField);

export const TextFieldCustom = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
      height: '34px',
      fontFamily: "Manrope",
    },
    '& .MuiFormLabel-root': {
      top:'-3px',
    },
    '& input': {
      paddingTop: '0 !important',
      paddingBottom: '0 !important',
    }
  },
})(TextField);

export const CustomGridApproval = withStyles(() => ({
  root: {
    height:'35px',
    paddingTop:'10px',
    marginBottom:'10px'
  },
}))(Grid);

export const SubmitButton = withStyles({
  root: {
    borderRadius: '8px',
    backgroundColor: '#2AC940',
    color: '#ffffff',
    height: '30px',
    paddingLeft: '25px',
    paddingRight: '25px',
    '&:hover': {
      backgroundColor: '#22a033',
    },
  },
})(Button);

export const CancelButton = withStyles({
  root: {
    borderRadius: '8px',
    backgroundColor: '#e0e0e0de',
    height: '30px',
    paddingLeft: '25px',
    paddingRight: '25px',
    '&:hover': {
      backgroundColor: '#bdbdbdde',
    },
  },
})(Button);

export const AddChip = withStyles(() => ({
  root: {
    backgroundColor:'#3399FF',
    color:'white',
    height:'25px',
    borderRadius:'8px',
    
    '& .MuiSvgIcon-root':{
      color:'white'
    },
    '&:hover':{
      backgroundColor:'#3399FF',
    },
    '&:focus':{
      backgroundColor:'#3399FF',
    }
    
  },
}))(Chip);

export const CustomChip = withStyles(() => ({
  root: {
    width:'auto',
    height:'24px',
    borderRadius:'24px',
    color:' #333942',
    margin:'0px 8px',
    fontSize:'16px',
    lineHeight:'21.86px',
    padding:'0px 5px'
   },
}))(Chip);

export const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom:'20px',
    paddingTop: '20px',    
  },
  daterangepickerControlSection: {
    maxWidth: '246px',
    margin: '30px auto',
    paddingTop: '50px'
  },
  circular: {
    color: '#FA9917',
    marginLeft: '45%'
  },
  circularpending: {
    color: '#FA9917',
  },
  select: {
    width: '120px'
  },
  buttonGroup: {
    height: '30px',
    borderRadius: '8px',
    width: '120px',
    boxShadow: '0 0 black',
  },
  disableButtonLeft:{
    backgroundColor: 'white',
    width: '60px',
    borderRadius: '24px 0 0 24px',
    borderRight: 0,
    border: '0 !important',
    marginLeft: '-15px',
    marginRight: '-2px',
  },

  buttonText: {
    backgroundColor: 'white',
    padding: '0px 7px',
    borderRight: 0,
    boxShadow: '0 0 black',
    border: 0,
    height: 35,
    marginTop: '0px',
    borderRadius: '0px',
    borderTopColor: '#E5E5E5',
    borderTopStyle: 'solid',
    borderTopWidth: '1px',
    borderBottomColor: '#E5E5E5',
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
    textTransform:'Capitalize !important',
    // '& .MuiButton-root:hover':{
    '&:hover': {
      backgroundColor:'white',
    }
  },
  buttonLeft: {
    backgroundColor: 'white',
    padding: 0,
    width: '20px !important',
    minWidth:'20px !important',
    borderRadius: '8px 0 0 8px',
    borderLeftColor: '#E5E5E5',
    borderLeftStyle: 'solid',
    borderLeftWidth: '1px',
    borderTopColor: '#E5E5E5',
    borderTopStyle: 'solid',
    borderTopWidth: '1px',
    borderBottomColor: '#E5E5E5',
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
    borderRight: 0,
    boxShadow: '0 0 black',
    border: 0,
    height: 35,
    '& .MuiSvgIcon-root': {
      width: '40px',
      height: '12px',
      color:'#516573',
      paddingLeft:'8px',
    },
    '&:hover': {
      backgroundColor:'white',
    }
  },
  buttonRight: {
    backgroundColor: 'white',
    padding: 0,
    width: '20px !important',
    minWidth:'20px !important',
    borderRadius: '0 8px 8px 0',
    borderRightColor: '#E5E5E5',
    borderRightStyle: 'solid',
    borderRightWidth: '1px',
    borderTopColor: '#E5E5E5',
    borderTopStyle: 'solid',
    borderTopWidth: '1px',
    borderBottomColor: '#E5E5E5',
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
    boxShadow: '0 0 black',
    border: 0,
    height: 35,
    '& .MuiSvgIcon-root': {
      width: '22px',
      height: '25px',
      color:'#516573',
      paddingRight:'5px', 
    },
    '&:hover': {
      backgroundColor:'white',
    }
  },

  disableButtonRight:{
    backgroundColor: 'white',
    width: '60px',
    borderRadius: '0 24px 24px 0',
    border: '0 !important',
  },
 
  addButtonRight: {
    backgroundColor: 'white',
    width: '60px',
    borderRadius: '0 24px 24px 0',
  },
  gridContainer: {
    paddingBottom: '25px',
  },
  navigation: {
    float:'right',
    paddingTop: '25px'
  },
  date: {
    fontWeight: '400',
    paddingRight:'10px',
    display:'inline-block',
    backgroundColor: 'white', 
    lineHeight: '30px',
    paddingLeft: '10px',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },

}));