import directus from '../../services/directus';


export const PublicHolidaysController = async (data, page) => {
    let filterData;

    if (data) {
      filterData = {
        fields: '*,region.*',
        meta: "filter_count",
        limit: localStorage.getItem("publicholiday_page")?localStorage.getItem("publicholiday_page"):50,
        page: page,
        filter: {
          'name': { like: data },
          'date': { 'logical': 'or', like: data },
          'status': "published"
        }
      }
    }
    else {
      filterData = {
        fields: '*,region.*',
        filter: { status: 'published' },
        meta: "filter_count",
        limit: localStorage.getItem("publicholiday_page")?localStorage.getItem("publicholiday_page"):50,
        page: page,
        sort: 'date'
      };
    }
    var result = await directus.getItems('public_holidays', filterData);
    return result;
  };

export default { PublicHolidaysController };