import directus from '../../services/directus';

export const IntegrationController = async (data,page) => {
    let filterData;

    if (data) {
      filterData = {
        fields: '*',
        meta: "filter_count",
        limit: localStorage.getItem("integration_page")?localStorage.getItem("integration_page"):50,
        page: page,
        filter: {
          'environment': { like: data },
          'api_username': { 'logical': 'or', like: data }
        }
      }
    }
    else {
      filterData = {
        fields: '*',
        meta: "filter_count",
        limit: localStorage.getItem("integration_page")?localStorage.getItem("integration_page"):50,
        page: page,
      };
    }
    var result = await directus.getItems('integration',filterData);
    return result
  };
  
  export const IntegrationListController = async () => {
    let integrationResult = [];
    var integration_data =  await directus.getField("integration", "type");
    const integration_list = Object.entries(integration_data.data.options.choices);
    integration_list.map((data, index) => {
      integrationResult[index] = { id: data[0], name: data[1], value: data[0] };
    })  
    return integrationResult;
  };
  
