import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Drawer, Hidden, List, makeStyles, Typography } from '@material-ui/core';
import NavItem from './NavItem';
import EventEmitter from 'src/utils/EventEmitter';
import { items } from 'src/utils/Menu';
import directus from '../../../services/directus';
import Messages from '../../../layouts/DashboardLayout/NavBar/Messages';
import Filters from '../../../layouts/DashboardLayout/NavBar/Filters';
import FiltersReporting from '../../../layouts/DashboardLayout/NavBar/FiltersReporting';
import TimesheetEntry from './EditSession';
import AddSession from './AddSession';
import { loadSessions } from '../../../Controller/LeaveRequests.js';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';
import useStateRef from "react-usestateref";
import { useSnackbar } from 'notistack';
import ErrorMessage from '../../../../src/views/Components/ErrorMessage';
import Skeleton from '@mui/material/Skeleton';
import Card from '@mui/material/Card';
import { SupervisorPermission, AdminPermission, EmployeeLeavePermission } from 'src/utils/Permission';

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: '300px',
    top: '80px',
    backgroundColor: 'white',
    borderRight: '0px',
    height: '90%',
    zIndex:'auto'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  List: {
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '4px'
  },
  box: {
    padding: '0 0 0 26px',
  },
  chipcustom: {
    width: 'fit-content',
    height: '22px',
    borderRadius: '20px'
  },
  cardCustom: {
    margin: '16px 16px 25px 16px',
    backgroundColor: '#F2F2F6 !important',
    borderRadius: '8px !important',
    boxShadow: 'none !important',
    '& .MuiCardContent-root': {
      padding: '6px 11px 0px 10px'
    },
    '& .MuiCardContent-root:last-child': {
      paddingBottom: '8px !important'
    }
  },
  tabstyle: {
    fontSize: '15px',
    fontWeight: '700',
    lineHeight: '20.49px',
    fontFamily: 'Manrope'
  },
}));

var checkPermission = AdminPermission() || EmployeeLeavePermission() || SupervisorPermission()?true:false;
var checkPageAccess= (window.location.pathname === '/'|| window.location.pathname.split('/')[1] === 'approvals')?true:false;

const getApprovalSidebarStatus = () => {
  return localStorage.getItem('approvals_sidebar_open_status') ? JSON.parse(localStorage.getItem('approvals_sidebar_open_status')) : {data: 1, status: true};
}

const checkIfReportFilterOpen = () => {
  return localStorage.getItem('report_filter_open_status') ? localStorage.getItem('report_filter_open_status') === 'true' : true;
}

const initialOpenStatus = () => {
  const sidebarStatus = getApprovalSidebarStatus();
  if(checkPermission) {
    // set the sidebar to be opened or closed based on user selection on the Approvals page
    if(checkPageAccess) {
      return sidebarStatus.status;
    }
    // set the sidebar to be opened or closed based on user selection on the Reporting page
    else if (window.location.pathname === '/reporting') {
      return checkIfReportFilterOpen();
    }
    else {
      return false;
    }
  } else {
    return false;
  }
}

const NavBar = ({ onMobileClose, openMobile }) => {
  /* eslint-disable no-unused-vars*/
  const classes = useStyles();
  const location = useLocation();
  const [initialized, setInitialized] = React.useState(false);

  const [sessions, setSessions] = React.useState(null);
  const [summary, setSummary] = React.useState('');
  const [startDate, setStartDate, startDateRef] = useStateRef('');
  const [endDate, setEndDate, endDateRef] = useStateRef('');
  const { enqueueSnackbar } = useSnackbar();

  const [timesheetStatusType, setTimesheetStatusType] = React.useState([]);
  const [timesheetStatusLoading, setTimesheetStatusLoading] = React.useState(null);

  const [drawerPageInfo, setDrawerPageInfo, drawerPageInfoRef] = useStateRef({ text: window.location.pathname === '/reporting'? 4 : getApprovalSidebarStatus().data });
  const [open, setOpen, openRef] = useStateRef(initialOpenStatus());

  var configuration = JSON.parse(window.localStorage.getItem('configuration'));
  var config_ts = JSON.parse(window.localStorage.getItem('config_ts'));


  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  
    statusSummaryTypeLoad();

    // eslint-disable-next-line array-callback-return
    items().map((item, index) => {
      if (item.href === '/' + window.location.pathname.split('/')[1]) {
        handleListItemClick(index, item.number, item.href, item.title, item.features);
        if(window.location.pathname.split('/')[2] !== undefined){
          setOpen(false);
        }
      }      
    })

    if (!initialized) {
      setInitialized(true);
      if (AdminPermission() || EmployeeLeavePermission()) {
        callSessions();
        statusSummaryLoad();
      }
    }

    const moveTimesheetLoading = async (eventData) => {
      if (eventData.text === 'startLoading') {
        setOpen(false);
      }
    }
    const listenerMoveTimesheetLoading = EventEmitter.addListener('moveTimesheets', moveTimesheetLoading);


    const summaryDate = (eventData) => {
      setStartDate(eventData.startdate);
      setEndDate(eventData.enddate);
      if (checkPermission) {
      statusSummaryLoad();
      statusSummaryTypeLoad();
      }
    }
    const listener = EventEmitter.addListener('summaryDate', summaryDate);

    const ReviewButton = (eventData) => {
      if (eventData.text === 'ApprovalsReview') {
          setOpen(false);

          EventEmitter.emit('contentWidth', {
            data: false
          })
      }
    }
    const listenerReviewButton = EventEmitter.addListener('topBar', ReviewButton);

    const reloadLeaveRequests = async () => {
      callSessions();
    }

    const reloadLeaveRequestsListener = EventEmitter.addListener('reloadMenuLeaveRequests', reloadLeaveRequests);

    const reloadChangeStatus = async () => {
      statusSummaryLoad();
      statusSummaryTypeLoad();
    }

    const listenerReloadChangestatus = EventEmitter.addListener('reloadChangeStatus', reloadChangeStatus);

    const dashboardDrawerFunc = (eventData) => {
      const reportFilterOpenStatus = checkIfReportFilterOpen();
      //Approvals Detail Only
      if (eventData.text === 5) {
        // Close the report filter view to switch to the detail list report view
        if (drawerPageInfoRef.current.text === 4) {
          setOpen(false);
        }
        let status = true;  
        if (drawerPageInfoRef.current.session) {
          if (eventData.session.id === drawerPageInfoRef.current.session.id) {
            openRef.current === true ? status = false : status = true;
            setOpen(status);
          }
          else {
            setOpen(true);
          }
        }
        else {
          setOpen(true);
        }
        
        // display report filter in the sidebar when the report list details is closed, if the report filter was open previously
        if(reportFilterOpenStatus && window.location.pathname === '/reporting' && openRef.current === false) {
          setOpen(true);
          eventData.text = 4;
        } else {
          window.DashboardGlobal = status;
          window.DashboardValue = 5;

          EventEmitter.emit('contentWidth', {
            data: window.DashboardGlobal,
            text: eventData.text
          })
        }

      }
      // close or open the filter report sidebar based on user selection
      else if (eventData.text === 4) {
        reportFilterOpenStatus ? setOpen(true) : setOpen(false);
      }
      // close or open the sidebar in the approvals page based on user selection
      else if (eventData.text && (eventData.text === 1 || eventData.text === 2 || eventData.text === 3)) {
        const sidebarStatus = getApprovalSidebarStatus();
        sidebarStatus.status ? setOpen(true) : setOpen(false);
      }
      //Public
      else if (eventData.text === 7 ){
        setOpen(false);
      }
      else if (window.DashboardGlobal) {
        setOpen(true);
      }
      else {
        setOpen(false);
      }
      
      setDrawerPageInfo(eventData);
    }

    const listenerDashboard = EventEmitter.addListener('dashboardDrawer', dashboardDrawerFunc);

    return () => {
      listener.remove();
      reloadLeaveRequestsListener.remove();
      listenerReloadChangestatus.remove();
      listenerReviewButton.remove();
      listenerDashboard.remove();
      listenerMoveTimesheetLoading.remove();
    }
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps*/

  const [selectedIndex, setSelectedIndex] = React.useState(null);

  const handleListItemClick = (event, index, href, title, features) => {
    setSelectedIndex(index);
    if (AdminPermission() || EmployeeLeavePermission()) {
      callSessions();
    }
  };

  const statusSummaryLoad = async () => {

    try {
      var ApprovalRecord = await directus.api.get('/custom/approvals/summary', {
        fields: '*',
        filter: {
          start_time: { gte: startDateRef.current ? startDateRef.current : null },
          end_time: { lte: endDateRef.current ? endDateRef.current : null }
        },
      });

      setSummary(ApprovalRecord.data);
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }
  }

  const callSessions = async () => {
    setSessions(await loadSessions('null', AdminPermission() ? 'approved' : 'pending'), 1);
  }


  const statusSummaryTypeLoad = async () => {
    setTimesheetStatusLoading(true);
    try {
      var timesheet_data = await directus.getField("timesheets", "status");
      const timesheet_status = Object.entries(timesheet_data.data.options.status_mapping);

      var timesheet_list = [];
      if (timesheet_status.length > 0) {
        //eslint-disable-next-line array-callback-return
        timesheet_status.map((value) => {
          if (value[1].value !== 'deleted') {
            var background_color = "";
            var name = value[1].name;
            var text_color = "#000000de";

            if (value[1].value === "approved") {
              background_color = "#DAF0DC";
              name = "Approved";
            } else if (value[1].value === "rejected") {
              background_color = "#FFDBD9";
              name = "Rejected";
            } else if (value[1].value === "pending") {
              background_color = "#D4E3F2";
              name = "Not Yet Submitted";
            } else if (value[1].value === "awaiting_approval") {
              background_color = "#FFF0DD";
              name = "Awaiting Approvals";
            } else if (value[1].value === "sent") {
              background_color = "deepskyblue";
              name = "Sent to Payroll";
            } else if (value[1].value === "paid") {
              background_color = "#acebac";
              name = "Paid"
            } else {
              background_color = "#ffffff";
              name = '';
            }
            timesheet_list.push({
              value: value[1].value,
              name: name,
              background_color: background_color,
              text_color: text_color
            })
          }
        })
        setTimesheetStatusType(timesheet_list);
      }
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }
    setTimesheetStatusLoading(false);
  }


  const content = (
    <Box height="100%" display="flex" flexDirection="column" id="navitem_mobile" >
      <Box p={2}>
        <List>
          {items().map((item, index) => (
            <NavItem
              id={`mobile_nav_item-${item.title.replace(/\s/g, '')}`}
              href={item.href}
              key={index}
              title={item.title}
              icon={item.icon}
              number={item.number}
              selectedIndex={selectedIndex}
              handleListItemClick={handleListItemClick}
              listStyle={item.listStyle}
              features={item.features}
              leave_Count={sessions ? sessions.length : 0}
            />
          ))}
        </List>
      </Box>
      <Box flexGrow={1} />
    </Box>
  );

  const drawerPage = (
    <Box className={classes.List}>
      {(() => {
        switch (drawerPageInfo.text) {
          case 1:
            return (
              <List>
                <ListItem>
                  <ListItemText>
                    <Typography className={classes.tabstyle}> Dashboard</Typography>
                  </ListItemText>
                </ListItem>
                <Divider style={{ margin: '0px 16px 6px 16px' }} />
                {!summary || timesheetStatusType.length === 0 ? (
                  <>
                    <Card className={classes.cardCustom}>
                      <Skeleton variant="rectangular" width={260} height={25} />
                    </Card>
                    <Card className={classes.cardCustom}>
                      <Skeleton variant="rectangular" width={260} height={25} />
                    </Card>
                    <Card className={classes.cardCustom}>
                      <Skeleton variant="rectangular" width={650} height={25} />
                    </Card>
                    <Card className={classes.cardCustom}>
                      <Skeleton variant="rectangular" width={260} height={25} />
                    </Card>
                    <Card className={classes.cardCustom}>
                      <Skeleton variant="rectangular" width={260} height={25} />
                    </Card>
                    <Card className={classes.cardCustom}>
                      <Skeleton variant="rectangular" width={260} height={25} />
                    </Card>
                  </>
                ) : (
                  <>
                    {timesheetStatusType.map((value, index) => (
                      <ListItem key={index} style={{ fontSize: '12px', fontFamily: 'Manrope' }}>
                        {value.name ? value.name : ''}
                        <ListItemText> <Chip className={classes.chipcustom} id={`${value.value}-chip`} label={<b>{summary[value.value] ? summary[value.value] : 0}</b>} size="small" style={{ float: 'right', backgroundColor: value.background_color, color: value.text_color }} /></ListItemText>
                      </ListItem>
                    ))}
                  </>
                )}
              </List>
            );
          case 2:
            return <Filters />;
          case 3:
            return <Messages />;
          case 4:
            return <FiltersReporting />;
          case 5:
            return (
              <TimesheetEntry
                session={drawerPageInfo.session}
                timesheets={drawerPageInfo.timesheets}
                page={drawerPageInfo.page}
              />
            );
          case 6:
            return (
              <AddSession
                open={openRef.current}
                configuration={configuration.session_type_default}
                enable_late_sessions={config_ts.enable_late_sessions}
                timesheets={drawerPageInfo.timesheets}
              />
            );
          default:
            return null;
        }
      })()}
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer anchor="left" classes={{ paper: classes.mobileDrawer }} onClose={onMobileClose} open={openMobile} variant="temporary">
          {content}
        </Drawer>
      </Hidden>
      {drawerPageInfo.text !== 0?
      <Hidden mdDown>
        <Drawer anchor="right" classes={{ paper: classes.desktopDrawer }} open={open} variant="persistent">
          {drawerPage}
        </Drawer>
      </Hidden>
      :
      <></>}
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

NavBar.defaultProps = {
  onMobileClose: () => { },
  openMobile: false
};

export default NavBar;
