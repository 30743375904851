import React, { useEffect } from 'react';
import { Box, List, makeStyles, Typography,} from '@material-ui/core';
import directus from '../../../services/directus';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { useSnackbar } from 'notistack';
import ErrorMessage from '../../../views/Components/ErrorMessage';
import linq from "linq";
import moment from 'moment';
import { getConfiguration } from 'src/utils/sessions';
import useStateRef from "react-usestateref";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Chip from '@material-ui/core/Chip';
import Skeleton from '@mui/material/Skeleton';
import EventEmitter from 'src/utils/EventEmitter';
import { DirectusEmployee } from 'src/views/Components/LocalstorageData.js';

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: '300px',
    top: '80px',
    backgroundColor: 'white',
    borderRight: '0px'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  List: {
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '4px'
  },
  box: {
    padding: '0 0 0 26px',
  },
  chipcustom:{
    height: '20px',
    width:'auto',
    marginTop:'3px',
    '& .MuiChip-label':{
      paddingLeft:'6px',
      paddingRight:'6px',
      paddingTop:'1px'
    }
  },
  circularSkeletonCustom:{
    height: '20px',
    width:'20px',
    marginTop:'4px',
    paddingLeft:'6px',
    paddingRight:'6px',
    paddingTop:'1px'
  },
  cardCustom:{
    margin:'0px 16px 10px 16px',
    backgroundColor:'#F2F2F6 !important',
    borderRadius:'8px !important',
    boxShadow: 'none !important',
    '& .MuiCardContent-root':{
      padding:'6px 11px 0px 10px'      
    },
    '& .MuiCardContent-root:last-child':{
      paddingBottom:'8px !important'
    }
  },
  tabstyle:{
    fontSize:'15px',
    fontWeight:'700',
    lineHeight:'20.49px'
  },
  fontstyle1:{
    fontSize:'11px',
    fontWeight:'700',
    lineHeight:'15.03px'
  },
  fontstyle2:{
    fontSize:'10px',
    fontWeight:'700',
    lineHeight:'13.66px'
  },
  fontstyle3:{
    fontSize:'9px',
    fontWeight:'400',
    lineHeight:'12.99px'
  },
  fontstyle4:{
    fontSize:'10px',
    fontWeight:'600',
    lineHeight:'13.66px',
    textAlign:'right'
  },
}));

let startApprovals;
let endApprovals;

const directusEmployeeData = DirectusEmployee();

const Messages = () => {
  /* eslint-disable no-unused-vars*/
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [initialized, setInitialized] = React.useState(false);
  const [timesheets, setTimesheets] =  useStateRef([]);
  const [timesheetLoading, setTimesheetLoading] = React.useState(null);
  const [startDate, setStartDate, startDateRef] = useStateRef('');
  const [endDate, setEndDate, endDateRef] = useStateRef('');
  const [countUnreadTimesheetReview, setCountUnreadTimesheetReview, countUnreadTimesheetReviewRef] = useStateRef('');

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      firstLoad();
    }
    const summaryDate = (eventData) => {
      setStartDate(eventData.startdate);
      setEndDate(eventData.enddate);      
      loadTimeSheets();
    }
    const listener = EventEmitter.addListener('summaryDate', summaryDate);

    return () => {
      listener.remove();
    }
  }, []);


  const firstLoad = async (data = false) => {

    if (window.localStorage.getItem('approvals_date') && data === false) {
      startApprovals = JSON.parse(window.localStorage.getItem('approvals_date')).start;
      endApprovals = JSON.parse(window.localStorage.getItem('approvals_date')).end;
    }
    else {
      var ConfigurationResult = await getConfiguration();
      let config_day_v1 = moment(moment().day(ConfigurationResult.first_day_of_week_v1)._d);
      let config_day_v2 = moment(moment().day(ConfigurationResult.first_day_of_week_v2)._d);
      let expired_date_v1 = moment(ConfigurationResult.expired_date_v1).format('YYYY-MM-DD 23:59:59');

      if (moment(moment().add(-2, 'd').format('YYYY-MM-DD')).isBefore(moment(config_day_v1).format('YYYY-MM-DD'))) {
        startApprovals = (moment(config_day_v1).add(-7, 'd').format('YYYY-MM-DD 00:00:00'));
        endApprovals = (moment(startApprovals).add(6, 'd').format('YYYY-MM-DD 23:59:59'));
      }
      else {
        startApprovals = (moment(config_day_v1).format('YYYY-MM-DD 00:00:00'));
        endApprovals = (moment(startApprovals).add(6, 'd').format('YYYY-MM-DD 23:59:59'));
      }

      if (moment(startApprovals).isAfter(moment(expired_date_v1))) {
        startApprovals = (moment(config_day_v2).format('YYYY-MM-DD 00:00:00'));
        endApprovals = (moment(startApprovals).add(6, 'd').format('YYYY-MM-DD 23:59:59'));
      }
    }

    setStartDate(startApprovals ? startApprovals : moment().add(-2, 'd').format('YYYY-MM-DD 00:00:00'));
    setEndDate(endApprovals ? endApprovals : moment().add(-2, 'd').format('YYYY-MM-DD 23:59:59'));

    loadTimeSheets('null');

  }

  const loadTimeSheets = async () => {
    setTimesheetLoading(true);
    let count=0;
    let filterData;    
    
    filterData = {
      'employee.status': { in: ['published','deleted']},
      start_time: { gte: startDateRef.current },
      end_time: { lte: endDateRef.current },
    };

    let TimesheetsResult = [];

    try {
      let fetchTimesheetResult = await directus.api.get('/custom/approvals/timesheets', {
        fields: 'id,end_time,start_time,status,total_minutes,employee.user.id,employee.user.first_name,employee.user.last_name,employee.id,employee.code,employee.status,employee.type,employee.leave_approver,employee.mobile_number,employee.parent_leave',
        filter: filterData,
        limit: -1,
        show_timesheet_review: true,
        timesheet_review_filter:
        {
          "read_by.employees_id": { in: [directusEmployeeData.id] }
        },
      })

      TimesheetsResult = TimesheetsResult.concat(fetchTimesheetResult.data);

    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }

    let timesheetId = [];

    TimesheetsResult.map((data) => {
      timesheetId = [...timesheetId, data.id];
    })

    try {
      var TimesheetReview = await directus.api.post('/custom/mobile/timesheets/batch-data', {
        "timesheet_review": {
          fields: '*,employee.user.first_name,employee.user.last_name,read_by.employees_id.id',
          filter: {
            'timesheet.id': { in: timesheetId },
            'employee.id' : { nin: [directusEmployeeData.id]},
          },
          sort: "created_on"
        }
      });      
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }

    TimesheetsResult.map((data, index) => {
      let result = 0;
      let info='';
     
      result = linq.from(TimesheetReview.data.timesheet_review)
        .where(x => x.timesheet.id === data.id).count();

      info = linq.from(TimesheetReview.data.timesheet_review).orderByDescending(x=>x.created_on).where(x => x.timesheet.id === data.id).toArray();

      TimesheetsResult[index] = { ...data, createdDate:info[0]?moment(info[0].created_on).format('DD/MM/YYYY'):'',totalCountTimesheet: result, select: false};
    })

    if(TimesheetsResult) 
    { 
      TimesheetsResult.map((data) => {
        count = count + data.timesheet_review_unread_count;        
      })

      setCountUnreadTimesheetReview(count);
      setTimesheets(TimesheetsResult);
    }
    else
    {
      setCountUnreadTimesheetReview(0);
      setTimesheets(null);
    }
    setTimesheetLoading(false);
  };


  return (
    <Box className={classes.List}>
      <List>
        <ListItem>
          <ListItemText className={classes.tabstyle}> <b>Messages</b> 
            {timesheetLoading?
              <Skeleton variant="circular" className={classes.circularSkeletonCustom} style={{ float: 'right' }}/>
            :  
              <Chip className={classes.chipcustom} label={<b>{countUnreadTimesheetReviewRef.current}</b>}  style={{ float: 'right', backgroundColor: '#E85454', color: 'white' }} />
            }
          </ListItemText>
        </ListItem>

        <Divider style={{ margin: '0px 16px 14px 16px' }}/>

        {timesheetLoading?
          <>
            <Card className={classes.cardCustom}>
              <Skeleton variant="rectangular" width={250} height={60} />
            </Card>
            <Card className={classes.cardCustom}>  
              <Skeleton variant="rectangular" width={250} height={60} />
            </Card>
            <Card className={classes.cardCustom}>  
              <Skeleton variant="rectangular" width={250} height={60} />
            </Card>
            <Card className={classes.cardCustom}>  
              <Skeleton variant="rectangular" width={250} height={60} />
            </Card>
            <Card className={classes.cardCustom}>  
              <Skeleton variant="rectangular" width={250} height={60} />
            </Card>
          </>
        : 
          <>        
            {timesheets.map((data, index) => {
              return(
                <>
                  {data.timesheet_review_unread_count > 0?                  
                    <Card className={classes.cardCustom} id={index}>
                      <CardContent>
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="flex-start"
                        >
                          <Grid item xs={9}><Typography className={classes.fontstyle1}>{data.employee.user.first_name} {data.employee.user.last_name}</Typography></Grid>
                          <Grid item xs={3}><Typography className={classes.fontstyle4}>{data.createdDate}</Typography></Grid>
                          <Grid item xs={12}><Typography className={classes.fontstyle2}>Timesheet: {moment(data.start_time).format('YYYY-MM-DD')} - {moment(data.end_time).format('YYYY-MM-DD')}</Typography></Grid>
                          <Grid item xs={12}><Typography className={classes.fontstyle3}>
                            {data.status==='pending'?'Not Yet Submitted':
                            data.status==='rejected'?'Rejected':
                            data.status==='awaiting_approval'?'Awaiting Approvals':
                            data.status==='sent'?'Sent to Payroll':
                            data.status==='paid'?'Paid'
                            :data.status} 
                            </Typography></Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  :''}
                </>
              );
            })
            }          
          </>
        }
      </List>
    </Box>
  );
};


export default Messages;
