import React from 'react';
import {
  List,
  ListItem,
  ListItemText,
  Button,
  Typography,
  Grid,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
} from '@material-ui/core';
import {
  CustomTooltip,
  SubmitButton,
  CancelButton,
  pageOptions,
  CustomAccordion,
  CustomAccordionSummary,
  GridCustom,
  TextFieldCustom,
  AutoCompleteCustom,
} from './style.js';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import { useStylesEmployees, AddButton } from './style.js';
import { IntegrationViewModel } from "../../ViewModel/Settings/IntegrationViewModel";
import SettingsSkeleton from './SettingsSkeleton';

export default function Integration() {
  const [
    //states
    {
      open, title, Integration,
      mode, loading, disable,
      pageNumberRef, totalPage, page,
      pageNumber, text, IntegrationList,
      type, environment, tenant_id, client_id,
      client_secret, loadingProcessRef
    },
     //setStates
    {
      setType,
    },
    //function
    {
      handleClickOpen, handleClose, SaveIntegration,
      handleChange, handleChangeInput, pageChange,
      
    }
  ] = IntegrationViewModel();

  const classes = useStylesEmployees();
  /* eslint-enable array-callback-return*/


  return (
    <>
      {Integration ?
        <div >
          <List component="nav" aria-label="main" className={classes.root} dense={true} style={{ backgroundColor: '#f2f2f6' }}>
            <ListItem>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={2}>
                      <Typography variant="h5">Type</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="h5">Environment</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="h5">Tenant ID</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="h5">Client ID</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="h5">Client Secret</Typography>
                    </Grid>
                    <Grid item xs={2} style={{ textAlign: 'right', paddingRight: '40px' }}>
                      <AddButton id="add_btn" size="small" color="secondary" aria-label="add" onClick={() => handleClickOpen('', 'add')}>
                        <AddIcon />
                      </AddButton>
                    </Grid>
                  </Grid>
                }
              />
            </ListItem>
          </List>
          {loadingProcessRef.current ?
            <SettingsSkeleton status='page_load' />
            :
            <>
              {Integration.map((data, index) => {
                return (
                  <CustomAccordion key={index}>
                    <CustomAccordionSummary
                      id={`integration_list_${index}`}
                      style={{ userSelect: 'auto', cursor: 'auto', alignItems: 'flex-start' }}
                    >
                      <Grid container onClick={(event) => event.stopPropagation()} onFocus={(event) => event.stopPropagation()}>
                        <GridCustom item xs={2}>
                          <Typography noWrap variant="h6" id={`integration_type_${index}`}>{data.type}</Typography>
                        </GridCustom>
                        <GridCustom item xs={2}>
                          <Typography noWrap variant="h6" id={`integration_environment_${index}`}>{data.environment}</Typography>
                        </GridCustom>
                        <GridCustom item xs={2}>
                          <CustomTooltip title={data.tenant_id} style={{ paddingRight: "5px" }}>
                            <Typography noWrap id={`integration_tenant_id_${index}`} variant="h6">{data.tenant_id}</Typography>
                          </CustomTooltip>
                        </GridCustom>
                        <GridCustom item xs={2}>
                          <Typography noWrap variant="h6" id={`integration_client_id_${index}`}>{data.client_id}</Typography>
                        </GridCustom>
                        <GridCustom item xs={2}>
                          <CustomTooltip title={data.client_secret}>
                            <Typography noWrap id={`integration_client_secret_${index}`} variant="h6">{data.client_secret}</Typography>
                          </CustomTooltip>
                        </GridCustom>
                        <Grid item xs={2} style={{ textAlign: 'right' }}>
                          <IconButton id={`edit_btn_${index}`} aria-label="edit" onClick={() => handleClickOpen(data, 'edit')} >
                            <EditIcon fontSize="small" />
                          </IconButton>
                          <IconButton id={`delete_btn_${index}`} aria-label="delete" onClick={() => handleClickOpen(data, 'delete')} >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </CustomAccordionSummary>
                  </CustomAccordion>
                );
              })}
              <Dialog
                id="integration_dialog"
                isopen={`${open}`}
                open={open}
                fullWidth={true}
                onClose={handleClose}
              >
                <DialogTitle disableTypography={true} id="form-dialog-title"><Typography id="integration_dialog_title" component="div" variant="h6"><h2>{title}</h2></Typography></DialogTitle>
                <DialogContent>
                  <Typography variant="h6">{text ? text : ''}</Typography>
                  {mode !== 'delete' ?
                    <div>
                      {IntegrationList?
                        <AutoCompleteCustom
                          size='small'
                          id="combo-box-integrationtype"
                          options={IntegrationList ? IntegrationList : []}
                          getOptionLabel={(option) => option.name ?? ''}
                          getOptionSelected={(option, value) => option.value === value.name}
                          fullWidth={true}
                          renderInput={(params) => <TextFieldCustom {...params} label="Type" variant="outlined" />}
                          onChange={(event, newValue) => {
                            newValue === null ?
                              setType({ id: 0, name: '', value: '' })
                              :
                              setType({ id: newValue.id, name: newValue.name, value: newValue.value })
                          }}
                          value={type}
                        />
                      :''}

                      <TextFieldCustom
                        size='small'
                        id="environment_text"
                        label="Environment"
                        variant="outlined"
                        fullWidth={true}
                        margin="normal"
                        name="environment"
                        value={environment}
                        onChange={handleChangeInput}
                      />
                      <TextFieldCustom
                        size='small'
                        id="tenant_id_text"
                        label="Tenant ID"
                        variant="outlined"
                        fullWidth={true}
                        margin="normal"
                        name="tenant_id"
                        value={tenant_id}
                        onChange={handleChangeInput}
                      />
                      <TextFieldCustom
                        size='small'
                        id="client_id_text"
                        label="Client ID"
                        variant="outlined"
                        fullWidth={true}
                        margin="normal"
                        name="client_id"
                        value={client_id}
                        onChange={handleChangeInput}
                      />
                      <TextFieldCustom
                        size='small'
                        id="client_secret_text"
                        label="Client Secret"
                        variant="outlined"
                        fullWidth={true}
                        margin="normal"
                        name="client_secret"
                        value={client_secret}
                        onChange={handleChangeInput}
                      />
                    </div>
                    : ''}
                </DialogContent>
                <DialogActions style={{ height: "60px" }}>
                  {loading ?
                    <SettingsSkeleton status='component_load' />
                    :
                    <div>
                      <CancelButton
                        id="cancel_btn"
                        size="small"
                        variant="contained"
                        onClick={handleClose}
                        style={{ marginRight: '5px' }}
                      >
                        Cancel
                      </CancelButton>
                      <SubmitButton
                        id="save_btn"
                        disabled={mode === 'delete' ? false : disable}
                        size="small"
                        variant="contained"
                        onClick={() => SaveIntegration()}
                      >
                        {mode === 'delete' ? 'Delete' : 'Save'}
                      </SubmitButton>
                    </div>
                  }

                </DialogActions>
              </Dialog>
            </>
          }
        </div>
        :
        <SettingsSkeleton status='page_load' />
      }

      {Integration ?
        <Grid
          item xs={12}
          style={{ textAlign: 'left', marginBottom: '10px', fontFamily: 'Manrope' }}
        >
          <span style={{ paddingRight: '20px' }}>
            Rows per page:
          </span>
          <span style={{ paddingRight: '20px' }}>
            <TextField
              id="standard-select-currency"
              select
              value={pageNumber}
              onChange={handleChange}
            >
              {pageOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </span>

          <span id="page_number" style={{ paddingRight: '20px' }}>
            {(pageNumberRef.current * (page - 1)) + 1}- {(pageNumberRef.current * page) > totalPage ? totalPage : pageNumberRef.current * page} of {totalPage}
          </span>

          <Button
            id="prev_btn"
            variant="outlined"
            size="small"
            disabled={page === 1}
            className={classes.buttonLeft}
            onClick={() => pageChange(-1)}
          >
            <ArrowBackIosRoundedIcon />
          </Button>

          <Button
            id="next_btn"
            variant="outlined"
            size="small"
            className={classes.buttonRight}
            disabled={page >= Math.ceil(totalPage / pageNumberRef.current)}
            onClick={() => pageChange(1)}
          >
            <ArrowForwardIosRoundedIcon />
          </Button>
        </Grid>
        :
        " "}
    </>
  );
}