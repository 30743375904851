import React, { useState, useEffect } from 'react';
import { 
  Typography, 
  Container, 
  Grid,
  Card,
  CardContent,
  MenuItem,
} from '@material-ui/core';
import Page from 'src/components/Page';
import { enableRipple } from '@syncfusion/ej2-base';
import useStateRef from "react-usestateref";
import ApprovalTimesheetList from './ApprovalTimesheetList'
import { AdminPermission } from 'src/utils/Permission';
import { useStyles, CustomGridApproval} from './style.js';
import { loadSessions } from '../../Controller/LeaveRequests.js';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';
import Skeleton from '@mui/material/Skeleton';

const LeaveRequests = () => {
  /* eslint-disable */
  const classes = useStyles();
  const [initialized, setInitialized] = useState(false);
  const [items, setItems] = useState({ employees: null, sessions: null });
  const [status, setStatus, statusRef] = useStateRef(window.localStorage.getItem('LRfilter')?window.localStorage.getItem('LRfilter'):AdminPermission()?'approved':'pending');
  const [moveLoading, setMoveLoading] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
   /* eslint-enable */

   const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
      marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
      borderRadius: 8,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #E5E5E5',
      fontSize: 16,
      padding: '10px 30px 10px 20px',
      width:'200px',
      height:'35px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      fontFamily: [
        'Manrope',
      ].join(','),
      '&:focus': {
        borderRadius: 8,
        borderColor: '#E5E5E5',
      },
    },
    '& .MuiSvgIcon-root': {
      marginRight: '15px !important',
    },
  }));

  enableRipple(true);

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      callSessions();
    }
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps*/

  const handleClick = (event) => {
    setStatus(event.target.value);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const callSessions = async () => {
    setMoveLoading(true);

    let result = await loadSessions('null', statusRef.current, 2);
    if(result.length > 0)
    {
      setItems(prevItems => ({
        ...prevItems,
        sessions: result[0],
        employees: result[1]
      }));
    }
    else
    {
      setItems(prevItems => ({
        ...prevItems,
        sessions: [],
        employees: []
      }));
    }
    setMoveLoading(false);
  }

  function filterStatus(input) {
    window.localStorage.setItem('LRfilter', input);
    setStatus(input);
    callSessions()
    handleClose();
  }
  

  return (
    <Page className={classes.root} title="Leave Requests">
      <Container maxWidth={false} style={{padding:'0px 20px'}}>
        <div className="control-pane">
          <div className="control-section">
            <Grid container spacing={0}>
              <Grid noWrap item xs={12}>
                <FormControl style={{margin:'0px 0px 30px', width:'auto'}} variant="standard">
                  <Select
                    labelId="demo-customized-select-label"
                    id="demo-customized-select"
                    value={statusRef.current}
                    onChange={handleClick}
                    input={<BootstrapInput />}
                  >
                    <MenuItem id="approved_menu" value={'approved'} onClick={()=>filterStatus('approved')} selected={'approved' === statusRef.current} >Approved by Supervisor</MenuItem>
                    <MenuItem id="approved_hrd_menu" value={'approved_hrd'} onClick={()=>filterStatus('approved_hrd')}  selected={'approved_hrd' === statusRef.current}>Approved by Payroll</MenuItem>
                    <MenuItem id="rejected_menu" value={'rejected'} onClick={()=>filterStatus('rejected')}  selected={'rejected' === statusRef.current}>Rejected</MenuItem>
                    <MenuItem id="pending_menu"  value={'pending'} onClick={()=>filterStatus('pending')}  selected={'pending' === statusRef.current}>Waiting Approval</MenuItem>
                    <MenuItem id="all_menu" value={'all'} onClick={()=>filterStatus('all')}  selected={'all' === statusRef.current} >All</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Card elevation={0} className={classes.card}>
              <CardContent className={classes.cardContent}>
                {moveLoading?
                  <Grid container spacing={0}>
                    <CustomGridApproval noWrap item xs={2}>
                      <Skeleton animation="wave" variant="rounded" height={25} style={{borderRadius:'8px',margin:'2px 2px'}}/>
                    </CustomGridApproval>
                    <CustomGridApproval noWrap item xs={1}>
                      <Skeleton animation="wave" variant="rounded" height={25} style={{borderRadius:'8px',margin:'2px 2px'}}/>
                    </CustomGridApproval>
                    <CustomGridApproval noWrap item xs={2}>
                      <Skeleton animation="wave" variant="rounded" height={25} style={{borderRadius:'8px',margin:'2px 2px'}}/>
                    </CustomGridApproval>
                    <CustomGridApproval noWrap item xs={2}>
                      <Skeleton animation="wave" variant="rounded" height={25} style={{borderRadius:'8px',margin:'2px 2px'}}/>
                    </CustomGridApproval>
                    <CustomGridApproval noWrap item xs={2}>
                      <Skeleton animation="wave" variant="rounded" height={25} style={{borderRadius:'8px',margin:'2px 2px'}}/>
                    </CustomGridApproval>
                    <CustomGridApproval noWrap item xs={1}>
                      <Skeleton animation="wave" variant="rounded" height={25} style={{borderRadius:'8px',margin:'2px 2px'}}/>
                    </CustomGridApproval>
                    <CustomGridApproval noWrap item xs={2} style={{textAlign: 'right'}}>
                      <Skeleton animation="wave" variant="rounded" height={25} style={{borderRadius:'8px',margin:'2px 2px'}}/>
                    </CustomGridApproval>
                  </Grid>
                :
                  <Grid container spacing={0}>
                    <CustomGridApproval noWrap item xs={2}>
                      <Typography variant="h5"><b>Date</b></Typography>
                    </CustomGridApproval>
                    <CustomGridApproval noWrap item xs={1}>
                      <Typography variant="h5"><b>Code</b></Typography>
                    </CustomGridApproval>
                    <CustomGridApproval noWrap item xs={2}>
                      <Typography variant="h5"><b>Name</b></Typography>
                    </CustomGridApproval>
                    <CustomGridApproval noWrap item xs={2}>
                      <Typography variant="h5"><b>Status</b></Typography>
                    </CustomGridApproval>
                    <CustomGridApproval noWrap item xs={2}>
                      <Typography variant="h5"><b>Type</b></Typography>
                    </CustomGridApproval>
                    <CustomGridApproval noWrap item xs={1}>
                      <Typography variant="h5"><b>Total Time</b></Typography>
                    </CustomGridApproval>
                    <CustomGridApproval noWrap item xs={2} style={{textAlign: 'right'}}>
                      <Typography variant="h5"></Typography>
                    </CustomGridApproval>
                  </Grid>
                }
              </CardContent>
            </Card>
            {items.sessions && items.employees?
              moveLoading?
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <Skeleton animation="wave" variant="rounded" height={45} style={{borderRadius:'8px',marginBottom:'10px'}}/>
                  </Grid>
                  <Grid item xs={12}>
                    <Skeleton animation="wave" variant="rounded" height={45} style={{borderRadius:'8px',marginBottom:'10px'}}/>
                  </Grid>
                  <Grid item xs={12}>
                    <Skeleton animation="wave" variant="rounded" height={45} style={{borderRadius:'8px',marginBottom:'10px'}}/>
                  </Grid>
                  <Grid item xs={12}>
                    <Skeleton animation="wave" variant="rounded" height={45} style={{borderRadius:'8px',marginBottom:'10px'}}/>
                  </Grid>
                  <Grid item xs={12}>
                    <Skeleton animation="wave" variant="rounded" height={45} style={{borderRadius:'8px',marginBottom:'10px'}}/>
                  </Grid>
                  <Grid item xs={12}>
                    <Skeleton animation="wave" variant="rounded" height={45} style={{borderRadius:'8px',marginBottom:'10px'}}/>
                  </Grid>
                </Grid>
              :
                items.sessions.length>0?
                  <ApprovalTimesheetList 
                    employees={items.employees}
                    sessions={items.sessions}
                    loadSessions={callSessions}
                    statusRef={statusRef.current}
                  />
              :
              !moveLoading?               
                <Grid 
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  style={{opacity:'0.2',paddingTop:'6pc'}}
                >
                  <Grid item xs={12} sm={12} lg={12}>
                    <img alt="No Data Found" height="230" width= "230" src="/static/images/folder.png?v=0.0.1"/>
                    <Typography variant="h3" style={{textAlign:'end'}}>No Data Found</Typography>
                  </Grid>
                </Grid>
              :''
            :
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <Skeleton animation="wave" variant="rounded" height={45} style={{borderRadius:'8px',marginBottom:'10px'}}/>
                </Grid>
                <Grid item xs={12}>
                  <Skeleton animation="wave" variant="rounded" height={45} style={{borderRadius:'8px',marginBottom:'10px'}}/>
                </Grid>
                <Grid item xs={12}>
                  <Skeleton animation="wave" variant="rounded" height={45} style={{borderRadius:'8px',marginBottom:'10px'}}/>
                </Grid>
                <Grid item xs={12}>
                  <Skeleton animation="wave" variant="rounded" height={45} style={{borderRadius:'8px',marginBottom:'10px'}}/>
                </Grid>
                <Grid item xs={12}>
                  <Skeleton animation="wave" variant="rounded" height={45} style={{borderRadius:'8px',marginBottom:'10px'}}/>
                </Grid>
                <Grid item xs={12}>
                  <Skeleton animation="wave" variant="rounded" height={45} style={{borderRadius:'8px',marginBottom:'10px'}}/>
                </Grid>
              </Grid>
            }
          </div>
        </div>
      </Container>
    </Page>
  );
};

export default LeaveRequests;
