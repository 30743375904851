import React, { useState } from "react";
import { compareAsc, format } from 'date-fns';
import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";

function Map(props) {
  const [activeMarker, setActiveMarker] = useState(null);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const handleOnLoad = (map) => {
    const bounds = new window.google.maps.LatLngBounds();
    props.markerData.forEach(({ position }) => bounds.extend(position));
    map.fitBounds(bounds);
  };

  function durationWords(mins, force_hours = false) {
    mins = Math.abs(mins);
    var hours = Math.floor(mins / 60);
    var minutes = Math.floor(mins) % 60; // remove seconds

    if (hours === 0 && !force_hours) {
      return (<span><b>{minutes}</b> min</span>);
    }
    else {
      return (<span>{hours} hr {minutes} min</span>);
    }
  }


  return (
    <GoogleMap
      onLoad={handleOnLoad}
      onClick={() => setActiveMarker(null)}
      mapContainerStyle={{ width: "100%", height: "70vh" }}
    >
      {props.markerData.map(({ id, name, start_time, end_time, duration, customer, activity, position }) => (
        <Marker
          key={id}
          position={position}
          onClick={() => handleActiveMarker(id)}
        >
          {activeMarker === id ? (
            <InfoWindow onCloseClick={() => setActiveMarker(null)}>
              <span>
                <h2>{format(new Date(name), 'iiii, dd MMMM')}</h2>
                <h4>
                  {start_time !== null && end_time !== null ?
                  format(new Date(name + ' ' + start_time), 'hh:mm a') + ' - ' + format(new Date(name + ' ' + end_time), 'hh:mm a')
                  : durationWords(duration)}
                </h4>
                <h4>{customer!==null?customer:''}</h4>
                <h4>{activity!==null?activity:''}</h4>
              </span>
            </InfoWindow>
          ) : null}
        </Marker>
      ))}
    </GoogleMap>
  );
}

export default Map;