import React, { useState, useEffect } from 'react';
import { 
  TimesheetOptionsController,
  AwardsController,
  CustomersController,
  TimesheetOptionsListController,
} from '../../Controller/Settings/TimesheetOptions';
import directus from '../../services/directus';
import EventEmitter from 'src/utils/EventEmitter';
import useStateRef from "react-usestateref";
import { useSnackbar } from 'notistack';
import ErrorMessage from '../../views/Components/ErrorMessage';
import linq from "linq";

let page = 1;

export const TimesheetOptionsViewModel = () => {
  const [initialized, setInitialized] = useState(false);
  const [searchTimeOp, setSearchTimeOp] = useState('');

  const [timesheetOptions, setTimesheetOptions] = useState(null);
  const [timesheetOptionsData, setTimesheetOptionsData] = useState(null);

  const [sort, setSort] = useState(''); 
  const [code, setCode] = useState('');
  const [name, setName] = useState('');
  const [question, setQuestion] = useState('');

  const [inputType, setInputType] = useState('');
  const [criteria, setCriteria] = useState('');
  const [defaultValue, setDefaultValue] = useState('');

  const [awards, setAwards] = useState('');
  const [awardsFilterChosen, setAwardsFilterChosen, awardsFilterChosenRef] = useStateRef('');
  
  const [customers, setCustomers, customersRef] = useStateRef('');
  const [customersFilterChosen, setCustomersFilterChosen, customersFilterChosenRef] = useStateRef('');

  const [title, setTitle] = useState('');
  const [text, setText] = useState('');

  const [mode, setMode] = useState('');
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingProcess, setLoadingProcess, loadingProcessRef] = useStateRef(false);

  const [totalPage, setTotalPage] = useState("");
  const [pageNumber, setPageNumber,pageNumberRef] = useStateRef(localStorage.getItem("timesheet_options_page")?localStorage.getItem("timesheet_options_page"):50);

  const { enqueueSnackbar } = useSnackbar();

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      loadTimesheetOptions();
    }

    const searchTimesheetOptions = (eventData) => {
      setLoadingProcess(true);
      page = 1;
      setSearchTimeOp(eventData.text);
      loadTimesheetOptions(eventData.text);
    }

    const listener = EventEmitter.addListener('searchTimesheetOptions', searchTimesheetOptions);
    
    return () => {
      listener.remove();
    }
  });
  /* eslint-enable react-hooks/exhaustive-deps*/

  let disable = code === '' || name === '';
  let localconfiguration = JSON.parse(window.localStorage.getItem('configuration'));

  const loadTimesheetOptions = async (data) => {
    var TimesheetOptionResults = await TimesheetOptionsController(data,page);
    setTimesheetOptions(TimesheetOptionResults.data);
    setTotalPage(TimesheetOptionResults.meta.filter_count);

    var Awards = await AwardsController();
    setAwards(Awards.data);

    var Customers = await CustomersController();
    setCustomers(Customers.data);

    var TimesheetOptionList = await TimesheetOptionsListController();
    setLoadingProcess(false);
  };

  const pageChange = (value) => {
    setLoadingProcess(true);
    page = page + value;
    loadTimesheetOptions(searchTimeOp);
  }

  const handleChange = (event) => {
    setLoadingProcess(true);
    setPageNumber(event.target.value);
    localStorage.setItem("timesheet_options_page", JSON.parse(event.target.value));
    page = 1;
    loadTimesheetOptions(searchTimeOp);
  };

  const handleClickOpen = (TimesheetOptions, mode) => {
    setOpen(true);
    if (mode === 'edit') {

      setCode(TimesheetOptions.code);
      setName(TimesheetOptions.name);
      setQuestion(TimesheetOptions.description);
      setCriteria(TimesheetOptions.criteria);
      setInputType(TimesheetOptions.input_type);
      setDefaultValue(TimesheetOptions.default_value);

      if(TimesheetOptions.awards.length > 0)
      {
        let result = [];
        TimesheetOptions.awards.map(data => {
          result = [...result, data.awards_id];
        })
        setAwardsFilterChosen(result);
      }
      else
      { setAwardsFilterChosen([]);}

      if(TimesheetOptions.customers.length > 0)
      {
        let result = [];
        TimesheetOptions.customers.map(data => {
          result = [...result, data.customers_id];
        })
        setCustomersFilterChosen(result);
      }
      else
      { setCustomersFilterChosen([]);}

      setTimesheetOptionsData(TimesheetOptions);
      setMode('edit')
      setTitle('Edit Weekly Allowances');
      setText('');
    }
    else if (mode === 'add') {
      setCode('');
      setName('');
      setQuestion('');
      setCriteria('');
      setInputType('checkbox');
      setDefaultValue('');

      setAwardsFilterChosen([]);
      setCustomersFilterChosen([]);

      setMode('add');
      setTitle('Add Weekly Allowances');
      setText('');
    }
    else {
      setTimesheetOptionsData(TimesheetOptions);
      setMode('delete');
      setTitle('Delete Weekly Allowances');
      setText('Are you Sure?');
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    if (name === 'code') {
      setCode(value);
    }
    else if (name === 'name') {
      setName(value);
    }
    else if (name === 'question') {
      setQuestion(value);
    }
    else if (name === 'criteria') {
      setCriteria(value);
    }
    else if (name === 'input_type') {
      setInputType(value);
    }
    else if (name === 'default_value') {
      setDefaultValue(value);
    }
  }

  const SaveTimesheetOptions = async () => {
    setLoading(true);
    setLoadingProcess(true);

    if (mode === 'edit') 
    {
      try {
        await directus.updateItem('timesheet_options', timesheetOptionsData.id, {
          code:code?code:'',
          name:name?name:'',
          description:question?question:'',
          input_type:inputType?inputType:'',
          criteria:criteria?criteria:'',
          default_value:defaultValue?defaultValue.toLowerCase():'',
        });
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }

      let createAwardsData = [];
      //================================== Add Timesheet Options Awards Relation ===================================//
      awardsFilterChosenRef.current.map(data => {
        const filterInput = linq.from(timesheetOptionsData.awards)
          .where(x=>x.awards_id.id===data.id).toArray();

        if (filterInput.length === 0) {
          createAwardsData = [...createAwardsData, { timesheet_options_id:timesheetOptionsData.id, awards_id:data.id,}];
        }
      })

      if (createAwardsData.length > 0){
        try {
          await directus.createItems('timesheet_options_awards', createAwardsData);
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
        }
      }

      let deleteAwardsData = "";
      //================================== Delete Timesheet Options Awards Relation ===================================//
      timesheetOptionsData.awards.map(data => {
        const filterInput = linq.from(awardsFilterChosenRef.current)
          .where(x=>x.id === data.awards_id.id).toArray();

        if(!!timesheetOptionsData.awards)
        {
          if (filterInput.length === 0) {
            if (deleteAwardsData === '') {
              deleteAwardsData = data.awards_id.id;
            }
            else {
              deleteAwardsData = data.awards_id.id + ',' + deleteAwardsData;
            }
          }
        }
      })
  
      if (deleteAwardsData !== '') {
        var TimesheetOptionsAwardsResult = await directus.getItems('timesheet_options_awards', {
          fields: '*',
          filter: {
            timesheet_options_id: { eq: timesheetOptionsData.id },
            awards_id: { in: deleteAwardsData },
          }
        });

        let deleteAwardsInput = [];

        TimesheetOptionsAwardsResult.data.map(data => {
          deleteAwardsInput = [...deleteAwardsInput, data.id];
        })

        try {
          await directus.deleteItems('timesheet_options_awards', deleteAwardsInput);
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
        }
      }

      let createCustomersData = [];
      //================================== Add Timesheet Options Customers Relation ===================================//
      customersFilterChosenRef.current.map(data => {
        const filterInput = linq.from(timesheetOptionsData.customers)
          .where(x=>x.customers_id.id===data.id).toArray();

        if (filterInput.length === 0) {
          createCustomersData = [...createCustomersData, { timesheet_options_id:timesheetOptionsData.id, customers_id:data.id}];
        }
      })

      if (createCustomersData.length > 0){
        try {
          await directus.createItems('timesheet_options_customers', createCustomersData);
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
        }
      }

      let deleteCustomersData = "";
      //================================== Delete Timesheet Options Customers Relation ===================================//
      timesheetOptionsData.customers.map(data => {
        const filterInput = linq.from(customersFilterChosenRef.current)
          .where(x=>x.id === data.customers_id.id).toArray();

        if(!!timesheetOptionsData.customers)
        {
          if (filterInput.length === 0) {
            if (deleteCustomersData === '') {
              deleteCustomersData = data.customers_id.id;
            }
            else {
              deleteCustomersData = data.customers_id.id + ',' + deleteCustomersData;
            }
          }
        }
      })

      if (deleteCustomersData !== '') {
        var TimesheetOptionsCustomersResult = await directus.getItems('timesheet_options_customers', {
          fields: '*',
          filter: {
            timesheet_options_id: { eq: timesheetOptionsData.id },
            customers_id: { in: deleteCustomersData },
          }
        });

        let deleteCustomersInput = [];

        TimesheetOptionsCustomersResult.data.map(data => {
          deleteCustomersInput = [...deleteCustomersInput, data.id];
        })

        try {
          await directus.deleteItems('timesheet_options_customers', deleteCustomersInput);
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
        }
      }
    }
    else if (mode === 'add') 
    {

      let createAwardsData = [];
      let createCustomersData = [];

      //================================== Add Timesheet Options Awards Relation ===================================//

      if(awardsFilterChosenRef.current.length > 0) {
        awardsFilterChosenRef.current.map(data => {
          createAwardsData = [...createAwardsData, {"awards_id": {"id":data.id}}];
        })
      }

      if(customersFilterChosenRef.current.length > 0) {
        customersFilterChosenRef.current.map(data => {
          createCustomersData = [...createCustomersData, {"customers_id":{"id":data.id}}];
        })
      }

      try {
        await directus.createItems('timesheet_options', {
          code:code?code:'',
          name:name?name:'',
          description:question?question:'',
          input_type:inputType?inputType:'',
          criteria:criteria?criteria:'',
          default_value:defaultValue?defaultValue.toLowerCase():'',
          awards:createAwardsData,
          customers:createCustomersData,
          status:'published'
        });
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }

    }else{

      let deleteAwardsData = "";
      //================================== Delete Timesheet Options Awards Relation ===================================//
      timesheetOptionsData.awards.map(data => {
        const filterInput = linq.from(awardsFilterChosenRef.current)
          .where(x=>x.id === data.awards_id.id).toArray();

        if(!!timesheetOptionsData.awards)
        {
          if (filterInput.length === 0) {
            if (deleteAwardsData === '') {
              deleteAwardsData = data.awards_id.id;
            }
            else {
              deleteAwardsData = data.awards_id.id + ',' + deleteAwardsData;
            }
          }
        }
      })
  
      if (deleteAwardsData !== '') {
        var TimesheetOptionsAwardsResult = await directus.getItems('timesheet_options_awards', {
          fields: '*',
          filter: {
            timesheet_options_id: { eq: timesheetOptionsData.id },
            awards_id: { in: deleteAwardsData },
          }
        });

        let deleteAwardsInput = [];

        TimesheetOptionsAwardsResult.data.map(data => {
          deleteAwardsInput = [...deleteAwardsInput, data.id];
        })

        try {
          await directus.deleteItems('timesheet_options_awards', deleteAwardsInput);
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
        }
      }


      let deleteCustomersData = "";
      //================================== Delete Timesheet Options Customers Relation ===================================//
      timesheetOptionsData.customers.map(data => {
        const filterInput = linq.from(customersFilterChosenRef.current)
          .where(x=>x.id === data.customers_id.id).toArray();

        if(!!timesheetOptionsData.customers)
        {
          if (filterInput.length === 0) {
            if (deleteCustomersData === '') {
              deleteCustomersData = data.customers_id.id;
            }
            else {
              deleteCustomersData = data.customers_id.id + ',' + deleteCustomersData;
            }
          }
        }
      })

      if (deleteCustomersData !== '') {
        var TimesheetOptionsCustomersResult = await directus.getItems('timesheet_options_customers', {
          fields: '*',
          filter: {
            timesheet_options_id: { eq: timesheetOptionsData.id },
            customers_id: { in: deleteCustomersData },
          }
        });

        let deleteCustomersInput = [];

        TimesheetOptionsCustomersResult.data.map(data => {
          deleteCustomersInput = [...deleteCustomersInput, data.id];
        })

        try {
          await directus.deleteItems('timesheet_options_customers', deleteCustomersInput);
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
        }
      }

      try {
        await directus.deleteItem('timesheet_options', timesheetOptionsData.id);
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }
    }

    loadTimesheetOptions();
    setLoading(false);
    handleClose();
  }

  return[
    //states
    {
      open, title, timesheetOptions,
      mode,  loading, disable,
      pageNumberRef, totalPage, page,
      pageNumber, text, localconfiguration,
      code, name, question, inputType, criteria,
      defaultValue, awardsFilterChosenRef, awards, 
      customersFilterChosenRef, customers, loadingProcessRef
    },
    //function
    {
      setAwardsFilterChosen, setCustomersFilterChosen,
      handleClickOpen, handleClose, SaveTimesheetOptions,
      handleChange, handleChangeInput, pageChange
    }
  ];
}

export default TimesheetOptionsViewModel;