import React, { useState, useEffect } from 'react';
import {
  Typography,
  CircularProgress,
  Menu,
  MenuItem,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  ListItemIcon,
} from '@material-ui/core';
import { 
  YesPayrollButton,
  NoPayrollButton,
} from './style.js';
import { useStylesExportFile } from './style.js';
import { enableRipple } from '@syncfusion/ej2-base';
import SendIcon from '@material-ui/icons/Send';
import Icon from "@material-ui/core/Icon";
import directus from '../../../services/directus';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import ErrorMessage from '../../../views/Components/ErrorMessage';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { DirectusEmployee } from 'src/views/Components/LocalstorageData.js';


let startApprovals;
const directusEmployeeData = DirectusEmployee();

const ExportFile = () => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStylesExportFile();
  const [initialized, setInitialized] = React.useState(false);
  const [exportLoading, setExportLoading] = React.useState(false);
  const [anchorElExport, setAnchorElExport] = React.useState(null);
  const [openDialogPayroll, setOpenDialogPayroll] = React.useState(false);
  const [openDialogPayrollMsg, setOpenDialogPayrollMsg] = React.useState(false);
  const [payrollMessage, setPayrollMessage] = React.useState(false);
  const [exportdata, setExportData] = React.useState(null);
  const [selectedStatus, setSelectedStatus] = React.useState(5);
  
    /* eslint-enable */
    enableRipple(true);

    var react_app_url=process.env.REACT_APP_URL;
    if(window.location.hostname.search('interax') !== -1){
      react_app_url=process.env.REACT_APP_URL_INTERAX;
    }
  
  
    useEffect(() => {
      if (!initialized) {
        setInitialized(true);
        ApprovalExport();
      }  

      return () => {
        setInitialized(false);
      }
    }, []);

    async function exportTimesheets(export_name, export_id = "", send_to_email = null) {
      handleCloseExport();
      handleCloseDialogPayroll();
      setExportLoading(true);
  
      startApprovals = moment(JSON.parse(window.localStorage.getItem('approvals_date')).start).format('YYYY-MM-DD');
      
      let dataURL = '';
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + directus.api.auth.config.token },
        body: JSON.stringify({ export_id: export_id, status: selectedStatus !== 5 && selectedStatus !== "all" ? selectedStatus : "" })
      };
  
      if (export_name === 'payroll') {
        dataURL = `${react_app_url}${JSON.parse(window.localStorage.getItem('company'))}/custom/sync/timesheet/send/` + startApprovals + `?email=` + directusEmployeeData.user.email;
      }
      else {
        dataURL = `${react_app_url}${JSON.parse(window.localStorage.getItem('company'))}/custom/export/` + export_name + `/` + startApprovals + `?email=` + directusEmployeeData.user.email;
      }

      if (export_name !== 'payroll') {
        await fetch(dataURL, requestOptions)
          .then(resp => resp.blob())
          .then(async blob => {
            var file = await blob.text();
            setExportLoading(false);
            if (file.length === 0) {
              alert('Connection error. Please try again. Code: 0');
            } else if (file.includes("\"error\"")) {
              try {
                var response = JSON.parse(file);
                alert(response.error);
              } catch {
                console.log(file);
                alert('Connection error. Please try again. Code: 1');
              }
            } else if (file.includes("\"error_message\"")) {
              try {
                var response = JSON.parse(file);
                alert(response.error_message);
              } catch {
                console.log(file);
                alert('Connection error. Please try again. Code: 2');
              }
            } else if (file.includes("\"status_message\"")) {
              try {
                var response = JSON.parse(file);
                alert(response.status_message);
              } catch {
                console.log(file);
                alert('Connection error. Please try again. Code: 3');
              }
            } else {
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.style.display = 'none';
              a.href = url;
              // the filename you want
              a.download = `DigiTime-Export-${startApprovals}.xlsx`;
              document.body.appendChild(a);
              a.click();
              window.URL.revokeObjectURL(url);
  
              setExportLoading(false);
            }
          })
      } else {
        fetch(dataURL, requestOptions)
          .then(response => response.json())
          .then(result => {
            setExportLoading(false);  
            if (result.status === "complete") {
              setPayrollMessage("All timesheets for this period have been processed. There are no more approved timesheets that need to be processed.");
              setOpenDialogPayrollMsg(true);
            }
            else if (result.status === "started") {
              setPayrollMessage("Background processing of Payroll transfer has now started. You will be sent an email with the results.\nThere are " + result.count + " timesheets to process. This could take up to " + Math.ceil(result.count * 0.06) + " minutes to process.");
              setOpenDialogPayrollMsg(true);
            }
            else if (result.status === "processing") {
              setPayrollMessage("Background processing of Payroll transfer is already in progress. Please wait until this is complete and try again.");
              setOpenDialogPayrollMsg(true);
            }
            else if (result.status === "error") {
              setPayrollMessage("Error processing. Please try again later." + result.message);
              setOpenDialogPayrollMsg(true);
            }
          })
          .catch(error => {
            setExportLoading(false);
            alert('Connection error. Please try again.');
          });
      }
    }
  
    const ApprovalExport = async () => {
      try {
        var ExportResult = await directus.getItems('approvals_export', {
          fields: 'id,name,link,icon,send_to_email,number',
          filter: {status: 'published'},
          sort: 'number'
        });
  
        setExportData(ExportResult.data.sort((a, b) => a.number - b.number));
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }
    }

    const handleClickOpenDialogPayroll = () => {
      handleCloseExport();
      setOpenDialogPayroll(true);
    };
  
    const handleCloseDialogPayroll = () => {
      setOpenDialogPayroll(false);
    };

    const handleClickExport = (event) => {
      setAnchorElExport(event.currentTarget);
    };
  
    const handleCloseExport = () => {
      setAnchorElExport(null);
    };
  

  return (
  <div>
    <IconButton id="add_order_menu" 
      className={classes.iconFilter}
      onClick={handleClickExport}
      style={anchorElExport?{backgroundColor:"#5DB6FF",color:"white"}:{}} 
    > 
      {exportLoading? 
        <CircularProgress className={classes.circularLoading} size={17} /> 
      : 
        <CloudDownloadIcon />
      }
    </IconButton>

    <Menu
      id="export_menu"
      anchorEl={anchorElExport}
      keepMounted
      open={Boolean(anchorElExport)}
      isopen={`${Boolean(anchorElExport)}`}
      onClose={handleCloseExport}
      style={{
        top: '43px',
      }}
    >
      {exportdata ?
        exportdata.map((data, index) => {
        return (
          <span key={index}>
            {data.name === 'Send to Payroll' ?
              <MenuItem id={`${data.name.replace(/\s/g, '')}_menu`} onClick={() => handleClickOpenDialogPayroll()}>
                <ListItemIcon className={classes.iconWidth}>
                  <SendIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="h6">Send to Payroll</Typography>
              </MenuItem>
            :
              <MenuItem id={`${data.name.replace(/\s/g, '').replace("&", '')}_menu`} onClick={() => exportTimesheets(data.link,data.id,data.send_to_email)}>
                <ListItemIcon>
                  <Icon fontSize="small" className={classes.icon}>{data.icon}</Icon>
                  <Typography variant="h6" style={{ color: '#333943' }}>{data.name}</Typography>
                </ListItemIcon>
              </MenuItem>
            }
          </span>
        );
        })
      : ""}
    </Menu>
    
    <Dialog id="openDialogPayroll" isopen={`${openDialogPayroll}`} open={openDialogPayroll} onClose={handleCloseDialogPayroll} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Send to Payroll</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure want to transfer all approved timesheets to the Payroll system?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <YesPayrollButton id="yes_payroll_btn" onClick={() => exportTimesheets('payroll')} color="primary">
          Yes
        </YesPayrollButton>
        <NoPayrollButton id="no_payroll_btn" onClick={() => handleCloseDialogPayroll()} color="primary">
          No
        </NoPayrollButton>
      </DialogActions>
    </Dialog>
    <Dialog id="send_to_payroll_status" isopen={`${openDialogPayrollMsg}`} open={openDialogPayrollMsg} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Send to Payroll Status</DialogTitle>
      <DialogContent>
        <DialogContentText id="payroll_message">
          {payrollMessage}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <YesPayrollButton onClick={() => { setOpenDialogPayrollMsg(false); }} color="primary">
          Close
        </YesPayrollButton>
      </DialogActions>
    </Dialog>
  </div>
  );
}


export default ExportFile;