import React, { useState } from 'react';
import { 
  Typography, 
  Card,
  CardContent,
  Grid,
  Dialog,
  DialogContentText,
  DialogActions,
  CircularProgress,
  DialogContent,
} from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import { 
  useStylesList,
  RejectButton,
  ApproveButton,
  UnapproveButton,
  CancelButtonDialog,
  RejectButtonDialog,
  useStylesApprovalTimesheetList,
  TextFieldCustom
} from './style.js';
import { AdminPermission, EmployeeLeavePermission } from 'src/utils/Permission';
import moment from 'moment';
import directus from '../../services/directus';
import linq from "linq";
import EventEmitter from 'src/utils/EventEmitter';
import { useSnackbar } from 'notistack';
import ErrorMessage from '../Components/ErrorMessage';

const ApprovalTimesheetList = (props) => {
  const classes = useStylesList();
  const Styleclasses = useStylesApprovalTimesheetList();

  const [checkBoxLoading, setCheckBoxLoading] = useState(false);
  const [note, setNote] = React.useState('');
  const [open, setOpen] = React.useState(false);

  const [sessionChoosen, setSessionChoosen] = React.useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const handleClickOpen = (data) => {
    setSessionChoosen(data);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setNote('');
  };

  function time_convert(num)
  {
    num = Math.abs(num);
    var hours = Math.floor(num / 60);  
    var minutes = num % 60;
    
    return (<span><b>{hours}</b>hr <b>{minutes}</b>min</span>);         
  }

  function durationTime(started,ended){
    if(ended != null)
    {
      var dif;
      if(started>ended)
      {
        dif=moment.duration(moment('1991-01-02T'+ ended).diff('1991-01-01T'+started));
      }
      else
      {
        dif=moment.duration(moment('1991-01-01T'+ ended).diff('1991-01-01T'+started));
      }

      if(dif.hours() === 0)
      {
        return (<span><b>{dif.minutes()}</b>min</span>);
      }
      else
      {
        return (<span><b>{dif.hours()}</b>hr <b>{dif.minutes()}</b>min</span>);
      }
      
    }
  }

  const handleInputChange = e => {
    const {value} = e.target;
    setNote(value);
  }

  const changeStatus = async (data, status) => {
    handleClose();
    setCheckBoxLoading(true);
    if(status === 'rejected')
    {
      try
      {
        await directus.updateItem('sessions', data.id, { leave_status: status, leave_note: note });
      }
      catch(e)
      {
        enqueueSnackbar(ErrorMessage(e), {anchorOrigin:{vertical: 'top',horizontal: 'right'}, variant:'error',autoHideDuration:3200});
      }
    }
    else
    {
      try
      {
        await directus.updateItem('sessions', data.id, { leave_status: status });
      }
      catch(e)
      {
        enqueueSnackbar(ErrorMessage(e), {anchorOrigin:{vertical: 'top',horizontal: 'right'}, variant:'error',autoHideDuration:3200});
      }
    }
    
    EventEmitter.emit('reloadMenuLeaveRequests', {text: 'reload'});

    props.loadSessions();
    setCheckBoxLoading(false);
  }

  const countData = (employee, sessionData) => {
    let filter = linq.from(sessionData)
      .where(x=>x.employee.id===employee.id).count();
    
    return filter;
  }
  const isInvalid = note === '';
  return (
    <div>
      <div>
        {props.sessions.map((session, index) => {
          let status;
          let statusName;
          if(session.leave_status === 'approved')
          {
            status = {backgroundColor: '#DAF0DC'};
            statusName = 'Approved';
          }
          else if(session.leave_status === 'rejected')
          {
            status = {backgroundColor: '#FFDBD9'};
            statusName = 'Rejected';
          }
          else if(session.leave_status === 'pending')
          {
            status = {backgroundColor: '#D4E3F2'};
            statusName = 'Waiting Approval';
          }
          else if(session.leave_status === 'awaiting_approval')
          {
            status = {backgroundColor: '#FFF0DD'};
            statusName = 'Waiting Approval';
          }
          else if(session.leave_status === 'approved_hrd')
          {
            status = {backgroundColor: '#b8d4ff'};
            statusName = 'Approved by Payroll';
          }
            return(
              <div key={index}>
                <Card elevation={0} className={classes.card} style={status}>
                  <CardContent className={classes.cardContent}>
                    <Grid container spacing={0}>
                      <Grid item noWrap xs={2}>
                        <Typography id={`code_${index}`}>{moment(session.session_date).format('DD MMM YYYY')}</Typography>
                      </Grid>
                      <Grid item noWrap xs={1}>
                        <Typography id={`code_${index}`}>{session.employee.code}</Typography>
                      </Grid>
                      <Grid item noWrap xs={2}>
                        <Typography id={`name_${index}`}>{session.employee.user.first_name} {session.employee.user.last_name}</Typography>
                      </Grid>
                      <Grid item noWrap xs={2}>
                        <Typography id={`type_${index}`}>{session.session_type.name}</Typography>
                      </Grid>
                      <Grid item noWrap xs={2}>
                        <Typography id={`status_${index}`}>  {statusName}  </Typography>
                      </Grid>
                      <Grid item noWrap xs={1}>
                      <Typography id={`duration_${index}`}> {session.duration>0?time_convert(session.duration):durationTime(session.session_start_time,session.session_end_time)}  </Typography>
                      </Grid>
                      <Grid item noWrap xs={2} style={{textAlign: 'right'}}>
                        {AdminPermission() && (session.leave_status === 'pending' || session.leave_status === 'approved')?
                          checkBoxLoading?
                          <CircularProgress className={classes.circularLoading} size={20}/>
                          :
                          <div>
                            <ApproveButton
                              id={`approve_approved_hrd_btn_${index}`}
                              variant="contained"
                              onClick={()=>changeStatus(session, 'approved_hrd')}
                            >
                              <DoneIcon/>
                            </ApproveButton>
                            <RejectButton
                              id={`reject_approved_hrd_btn_${index}`}
                              variant="contained"
                              onClick={()=>handleClickOpen(session)}
                            >
                              <CloseIcon/> 
                            </RejectButton>
                          </div>
                          :
                          ''
                        }
                        {session.leave_status === 'pending' && EmployeeLeavePermission() && !AdminPermission()?
                          checkBoxLoading?
                          <CircularProgress className={classes.circularLoading} size={20}/>
                          :
                          <div>
                            <ApproveButton
                              id={`approve_approved_btn_${index}`}
                              variant="contained"
                              onClick={()=>changeStatus(session, 'approved')}
                            >
                              <DoneIcon/>
                            </ApproveButton>
                            <RejectButton
                              id={`reject_approved_btn_${index}`}
                              variant="contained"
                              onClick={()=>handleClickOpen(session)}
                            >
                              <CloseIcon/> 
                            </RejectButton>
                          </div>
                        :''
                        }
                        {session.leave_status === 'approved_hrd' && AdminPermission()?
                          checkBoxLoading?
                            <CircularProgress className={classes.circularLoading} size={20}/>
                          :
                          <UnapproveButton
                            id={`unapprove_approved_hrd_btn_${index}`}
                            variant="contained"
                            onClick={()=>changeStatus(session, 'pending')}
                          >
                            <Typography variant="h6">Unapprove</Typography>
                          </UnapproveButton>
                        :''}
                        {AdminPermission()?
                        ''
                        :
                          session.leave_status === 'approved' && EmployeeLeavePermission()?
                            checkBoxLoading?
                              <CircularProgress className={classes.circularLoading} size={20}/>
                            :
                            <UnapproveButton
                              id={`unapprove_approved_btn_${index}`}
                              variant="contained"
                              onClick={()=>changeStatus(session, 'pending')}
                            >
                              <Typography variant="h6">Unapprove</Typography>
                            </UnapproveButton>
                          :''
                        }
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
                <div style={{height:10+'px'}}></div>
              </div>
            );
          })}

        <Dialog id="reject_approval_dialog" isopen={`${open}`} open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true}>
          <DialogContent>
            <DialogContentText>
              Please enter the note here.
            </DialogContentText>
            <TextFieldCustom
              size='small'
              name="reject-note"
              id="reject-note"
              label="Notes"
              multiline
              fullWidth={true}
              minRows={4}
              maxRows={4}
              value={note}
              onChange={handleInputChange}
              variant="outlined"
            />
          </DialogContent>
          <DialogActions>
            <CancelButtonDialog 
              size="small" 
              variant="contained"
              onClick={handleClose}
            >
              <Typography variant="h6">Cancel</Typography>  
            </CancelButtonDialog>
            <RejectButtonDialog
              size="small" 
              variant="contained"
              disabled={isInvalid}
              onClick={()=>changeStatus(sessionChoosen, 'rejected')}
            >
              <Typography variant="h6">Reject</Typography>  
            </RejectButtonDialog>
          </DialogActions>
        </Dialog>
      </div>
     </div>
  );
};

export default ApprovalTimesheetList;
