import directus from '../../services/directus';
import linq from "linq";

export const TimesheetOptionsController = async (data,page) => {
  let filterData;

  var timesheetOptions_fields  = 'id,code,name,description,criteria,input_type,default_value,';
      timesheetOptions_fields += 'awards.awards_id.code,awards.awards_id.id,awards.awards_id.name,';
      timesheetOptions_fields += 'customers.customers_id.code,customers.customers_id.id,customers.customers_id.name';

  if (data) {
    filterData = {
      fields: timesheetOptions_fields,
      meta: "filter_count",
      limit: localStorage.getItem("timesheet_options_page")?localStorage.getItem("timesheet_options_page"):50,
      page: page,
      filter: {
        'code': { like: data },
        'name': { 'logical': 'or', like: data },
        status : { eq: 'published' },
      },
      sort: "name"
    }
  }
  else {
    filterData = {
      fields: timesheetOptions_fields,
      meta: "filter_count",
      limit: localStorage.getItem("timesheet_options_page")?localStorage.getItem("timesheet_options_page"):50,
      page: page,
      filter: { 
        status : { eq: 'published' },
      },
      sort: "name"
    };
  }

  var result = await directus.getItems('timesheet_options',filterData);

  // sort award code
  result.data.map((award)=>{
    award.awards = linq.from(award.awards).where(x => x.awards_id !== null).toArray();
    award.awards.sort((a, b) => a.awards_id.code > b.awards_id.code ? 1 : -1);
  })

  // sort customer code
  result.data.map((customers)=>{
    customers.customers = linq.from(customers.customers).where(x => x.customers_id !== null).toArray();
    customers.customers.sort((a, b) => a.customers_id.code > b.customers_id.code ? 1 : -1);
  })


  return result;
};

export const TimesheetOptionsListController = async () => {

  var result = await directus.getItems('timesheet_options', {
    fields: 'id,code,name,description,criteria,input_type,default_value,'
          + 'awards.awards_id.code,awards.awards_id.id,awards.awards_id.name,'
          + 'customers.customers_id.code,customers.customers_id.id,customers.customers_id.name',
    filter: { 
      status : { eq: 'published' },
    },
    sort: "name"
  });
  return result;
  
};


export const CustomersController = async () => {
  var result = await directus.getItems('customers', {
    fields: '*',
    limit: -1,
    sort: "code"
  });
return result;
};

export const AwardsController = async () => {
  var result = await directus.getItems('awards', {
    fields: '*',
    limit: -1,
    sort: "code"
  });
return result;
};
