import { createTheme  } from '@material-ui/core/styles';
import { colors } from '@material-ui/core';
import shadows from './shadows';
import typography from './typography';

const theme = createTheme({
  palette: {
    background: {
      dark: '#F2F2F6',
      default: colors.common.white,
      paper: colors.common.white
    },
    primary: {
      main: colors.blue[900]

    },
    secondary: {
      main: colors.blue[900],

    },
    text: {
      // primary: colors.blueGrey[900],
      primary:'#333942',
      secondary: colors.blueGrey[600],
      // secondary: '#333942'

    }
  },
  shadows,
  typography
});

export default theme;
