import React from 'react';
import {
  makeStyles,
  Button,
  withStyles,
  FormControl,
  Switch,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import LoadingButton from '@mui/lab/LoadingButton';
import Chip from '@material-ui/core/Chip';
import { Autocomplete } from '@material-ui/lab';
import TextField from '@mui/material/TextField';
import { styled } from "@mui/system";
import { MuiPickersUtilsProvider, KeyboardTimePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import IconButton from '@mui/material/IconButton';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const useStyles = makeStyles({
  input: {
    paddingLeft: '10px !important',
  },
  adornedEnd: {
    paddingRight: 0,
  },
});

export const TimePickerCustom = ({ handleChange, name, value }) => {
  const classes = useStyles();
  return (
    <div>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardTimePicker
          name={name}
          format="hh:mm a"
          inputVariant="outlined"
          size="small"
          placeholder="hh:mm aa"
          mask="__:__ _M"
          error={false}
          helperText={null}
          value={value}
          onChange={handleChange}
          keyboardIcon={
            <IconButton style={{ padding: 0 }}>
              <AccessTimeIcon style={{ fontSize: '16px' }} />
            </IconButton>
          }
          InputProps={{
            classes: {
              input: classes.input,
              adornedEnd: classes.adornedEnd,
            },
            style: {
              fontSize: 12,
              fontWeight: 400,
              fontFamily: 'Manrope',
              height: 24,
              borderRadius: 8,
              marginBottom: 10,
            }
          }}
          style={{ width: "100%" }}
        />
      </MuiPickersUtilsProvider>
    </div>
  )
}

export const AutoCompleteCustom = withStyles({
  root: {
    '& .MuiAutocomplete-input': {
      marginTop: '-3px'
    },
    '& input': {
      paddingTop: '0 !important',
      paddingBottom: '0 !important',
    }
  },
})(Autocomplete);

export const TextFieldCustom2 = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
      fontFamily: "Manrope",
    },
    '& .MuiFormLabel-root': {
      top:'-3px',
    },
  },
})(TextField);

export const FileChip = withStyles(() => ({
  root: {
    backgroundColor: '#8bc1e0',
    color: 'white',
    height: '24px',
    borderRadius: '8px',
    marginBottom: '10px',
    width: '-webkit-fill-available',
    '& .MuiSvgIcon-root': {
      color: 'white',
      height: '18px',
    },
    '& .MuiSvgIcon-fontSizeSmall': {
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '16.39px',
      fontFamily: 'Manrope',
    },
    '& .MuiChip-deleteIcon': {
      margin: '0px !important'
    },
    '&:hover': {
      backgroundColor: '#8bc1e0',
    },
    '&:focus': {
      backgroundColor: '#8bc1e0',
    },


  },
}))(Chip);

export const TextFieldCustom = withStyles({
  root: {
    '& .MuiInputBase-input': {
      padding: '5px 10px',
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '16.39px',
      fontFamily: 'Manrope',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px !important',
      marginBottom: '10px !important',
      height: '24px !important'
    }
  },
})(TextField);

export const TextFieldNotesCustom = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      padding: '7px 10px',
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '16.39px',
      fontFamily: 'Manrope',
      borderRadius: '10px',
      marginBottom: '10px',
      minHeight: '24px'
    }
  },
})(TextField);

export const TextFieldSessionOptionCustom = withStyles({
  root: {
    '& .MuiInputBase-root': {
      padding: '5px 10px',
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '16.39px',
      fontFamily: 'Manrope',
      borderRadius: '10px',
    },
    '& .MuiOutlinedInput-root': {
      marginBottom: '2px'
    }
  },
})(TextField);

export const TextFieldSessionOptionValueCustom = withStyles({
  root: {
    '& .MuiInputBase-input': {
      padding: '5px 10px',
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '16.39px',
      fontFamily: 'Manrope',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
    }
  },
})(TextField);

export const TextFieldSessionOptionValueEditCustom = withStyles({
  root: {
    '& .MuiInputBase-input': {
      padding: '5px 5px',
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '16.39px',
      fontFamily: 'Manrope',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
      height: '24px'
    }
  },
})(TextField);

export const AutocompleteMaterialEquipmentCustom = withStyles({
  root: {
    margin: '0px 0px 5px 0px',
    '& .MuiAutocomplete-option': {
      paddingLeft: '10px !important'
    },
    '& .MuiFormLabel-root': {
      fontFamily: "Manrope",
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16.39px',
      left: '-3px',
      top:'-3px',
    },
    '& .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root':{
      top:'5px !important'
    },
    '& ul#autoActivity-popup': {
      fontFamily: "Manrope",
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16.39px',
    }
  },
  inputRoot: {
    padding: '0 !important',
  },
  input: {
    padding: '0 !important',
    paddingRight: '33px !important',
    paddingLeft: '10px !important',
  },
})(Autocomplete);


export const AutocompleteCustom = withStyles({
  root: {
    margin: '11px 0px 10px 0px',

    '& .MuiAutocomplete-inputRoot': {
      padding: '0px',
    },
    '& .MuiAutocomplete-input': {
      padding: '0px !important',
      paddingLeft: '10px !important',
    },
    '& .MuiFormLabel-root': {
      fontFamily: "Manrope",
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16.39px',
      left: '-3px',
      top:'-3px',
    },
    '& .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root':{
      top:'5px !important'
    },
    '& ul#autoActivity-popup': {
      fontFamily: "Manrope",
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16.39px',
    },
  },
  inputRoot: {
    padding: '0 !important',
  },
  input: {
    padding: '0 !important',
    paddingRight: '33px !important',
    paddingLeft: '10px !important',
  },
})(Autocomplete);

export const TextFieldCustomerActivityCustom = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
      height: '24px',
      fontFamily: "Manrope",
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16.39px',
    },
  },
})(TextField);


export const AutocompleteFilterTimesheetCustom = withStyles({
  root: {
    margin: '0px',
    '& .MuiAutocomplete-tag': {
      fontFamily: "Manrope",
      fontWeight: 400,
      fontSize: '10px',
      lineHeight: '16.39px',
      height: '18px',
    },

    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] ': {
      padding: '0px !important',
    },
    '& .MuiAutocomplete-input': {
      padding: '0px !important',
      paddingLeft: '10px !important',
    },
    '& .MuiFormLabel-root': {
      fontFamily: "Manrope",
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16.39px',
      top: '-5px',
      left: '-3px',
    },
    '& ul#filterTimesheet-popup': {
      fontFamily: "Manrope",
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16.39px',
    },
  },
})(Autocomplete);

export const TextFieldFilterTimesheetCustom = withStyles({
  root: {
    '& .MuiAutocomplete-input': {
      padding: '5px 10px !important',
      fontSize: '10px',
      fontWeight: '400',
      lineHeight: '16.39px',
      fontFamily: 'Manrope',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px !important',
      marginBottom: '10px !important',
      minHeight: '24px !important',
      height: 'auto'
    }

  },
})(TextField);

export const TextFieldDateRangeCustom = withStyles({
  root: {
    '& .MuiInputBase-input': {
      padding: '5px 1px',
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '16.39px',
      fontFamily: 'Manrope',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px !important',
      marginBottom: '10px !important',
      height: '24px !important',
      paddingLeft:'8px !important'
    },
  },
})(TextField);

export const CheckboxCustom = withStyles({
  root: {
    padding: '0px 0px 0px 10px ',
    '& .MuiFormControlLabel-root': {
      fontFamily: "Manrope",
      fontWeight: 400,
      fontSize: '10px',
    }
  },
})(Checkbox);

export const GroupHeader = styled("div")({
  position: "sticky",
  padding: "0px 10px",
});

export const GroupItems = styled("ul")({
  padding: 0
});

export const UploadButton = withStyles(() => ({
  root: {
    backgroundColor: '#0aa4c7',
    color: 'white',
    marginRight: '3px',
    height: '24px',
    textTransform: 'capitalize',
    '& .MuiSvgIcon-root': {
      color: 'white',
      fontSize: '18px'
    },
    '&:hover': {
      backgroundColor: '#0aa4c7',
    },
    '&:focus': {
      backgroundColor: '#0aa4c7',
    },
    borderRadius: '8px',
    marginBottom: '10px'

  },
}))(Button);

export const SaveButton = withStyles({
  root: {
    '&.MuiLoadingButton-root': {
      borderRadius: '8px',
      backgroundColor: '#5ABE58',
      color: '#ffffff',
      height: '24px',
      marginTop: '10px',
      textTransform: 'capitalize',
      '&:hover': {
        backgroundColor: '#52a350',
      },
    }
  },
})(LoadingButton);

export const DeleteButton = withStyles({
  root: {
    borderRadius: '8px',
    backgroundColor: '#E5E5E5',
    color: '#374355',
    height: '24px',
    marginTop: '10px',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#bdbdbd',
    },
  },
})(Button);


export const CancelButton = withStyles({
  root: {
    borderRadius: '8px',
    backgroundColor: '#E5E5E5',
    color: '#374355',
    height: '24px',
    marginTop: '10px',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#bdbdbd',
    },
  },
})(Button);


export const AddNewTimesheetButton = withStyles({
  root: {
    borderRadius: '8px',
    backgroundColor: '#FA9917',
    color: '#ffffff',
    height: '30px',
    width: '150px',
    '&:hover': {
      backgroundColor: '#d8820f',
    },
  },
})(Button);


export const CancelNewTimesheetButton = withStyles({
  root: {
    borderRadius: '8px',
    backgroundColor: '#e0e0e0de',
    height: '30px',
    width: '120px',
    marginRight: '8px',
    '&:hover': {
      backgroundColor: '#bdbdbdde',
    },
  },
})(Button);

export const FormControlCustom = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
      margin: '0px 0px 10px 0px',
    },
    '& .MuiOutlinedInput-input': {
      padding: '0px',
      paddingLeft: '10px',
      paddingTop: '3px',
      height: '21px ',
      fontFamily: "Manrope !important",
      fontWeight: 400,
      fontSize: '12px !important',
      lineHeight: '16.39px !important',
    }
  },
})(FormControl);


export const YesPayrollButton = withStyles(() => ({
  root: {
    borderRadius: '8px',
    backgroundColor: '#2AC940',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#22a033',
    },
  },
}))(Button);

export const NoPayrollButton = withStyles(() => ({
  root: {
    borderRadius: '8px',
    backgroundColor: '#FF5F58',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#c34c46',
    },
  },
}))(Button);


export const useStylesExportFile = makeStyles(() => ({
  iconFilter: {
    width: '40px',
    height: '40px',
    borderRadius: '8px',
    backgroundColor: '#F2F2F6',
    margin: '0px 10px',
  },
  filter: {
    float: 'right',
    marginTop: '-3px',
    marginRight: '20px',
  },
  icon: {
    paddingRight: '32px',
  },
  iconWidth: {
    minWidth: '32px !important'
  },
  circularLoading: {
    color: 'rgb(93, 182, 255)',
    width: '17px',
    height: '17px'
  },

}
));

export const FormControlLabelCustom = withStyles(() => ({
  root: {
    margin: '0px 0px 10px 0px'
  }
}))(FormControlLabel);

export const SwitchCustom = withStyles(() => ({
  root: {
    width: 37,
    height: 18,
    padding: 0,
    marginRight: '10px',
    "& .MuiSwitch-switchBase": {
      padding: 0,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",

        "& + .MuiSwitch-track": {
          backgroundColor: "white",
          opacity: 1,
          boxSizing: "border-box",
          border: "1px solid rgba(0, 0, 0, 0.1)"
        }
      }
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 13,
      height: 13,
      margin: "3px 2px 3px 3px",
      color: "#5DB6FF"
    },
    "& .MuiSwitch-track": {
      borderRadius: "10px",
      border: "1px solid rgba(0, 0, 0, 0.1)",
      backgroundColor: "#958d8d",
      padding: 0
    }
  }
}))(Switch);

export const useStylesAddSession = makeStyles(() => ({
  add: {
    width: '40px',
    height: '40px',
    backgroundColor: '#5DB6FF',
    margin: '0px 10px',
  },
  iconAdd: {
    backgroundColor: '#5DB6FF',
    color: '#FFFFFF',
    borderRadius: '19px',
    fontSize: "40px"
  },
  iconFilter: {
    width: '40px',
    height: '40px',
    borderRadius: '8px',
    backgroundColor: '#F2F2F6',
    margin: '0px 10px',
  },

}
));

export const useStylesEditSession = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: '300px',
    top: '80px',
    backgroundColor: 'white',
    borderRight: '0px',
    zIndex: 'auto',
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  List: {
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '4px'
  },
  box: {
    padding: '0 0 0 26px',
  },
  chipcustom: {
    height: '20px',
    marginTop: '3px',
    '& .MuiChip-label': {
      paddingLeft: '6px !important',
      paddingRight: '6px !important',
      paddingTop: '1px',
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '16.39px',
      fontFamily: 'Manrope'
    },
    width: '-webkit-fill-available',
  },
  circularSkeletonCustom: {
    height: '20px',
    width: '20px',
    marginTop: '4px',
    paddingLeft: '6px',
    paddingRight: '6px',
    paddingTop: '1px'
  },
  cardCustom: {
    margin: '0px 10px 10px 16px',
    borderRadius: '8px !important',
    boxShadow: 'none !important',
    '& .MuiCardContent-root': {
      padding: '6px 0px 0px 0px'
    },
    '& .MuiCardContent-root:last-child': {
      paddingBottom: '8px !important'
    }
  },
  readSessionStyle: {
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '16.39px',
    fontFamily: 'Manrope',
    padding:'2px 0px 11px 0px'
  },
  readResourceStyle: {
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '16.39px',
    fontFamily: 'Manrope',
    padding:'2px 0px 0px 0px'
  },
  tabstyle: {
    fontSize: '15px',
    fontWeight: '700',
    lineHeight: '20.49px',
    fontFamily: 'Manrope'
  },
  fontstyle1: {
    fontSize: '11px',
    fontWeight: '700',
    lineHeight: '15.03px',
    fontFamily: 'Manrope'
  },
  fontstyle2: {
    fontSize: '10px',
    fontWeight: '700',
    lineHeight: '13.66px',
    fontFamily: 'Manrope'
  },
  fontstyle3: {
    fontSize: '9px',
    fontWeight: '400',
    lineHeight: '12.99px',
    fontFamily: 'Manrope'
  },
  fontstyle4: {
    fontSize: '10px',
    fontWeight: '600',
    lineHeight: '13.66px',
    textAlign: 'right',
    fontFamily: 'Manrope'
  },
  fontstyle5: {
    fontSize: '12px',
    fontWeight: '700',
    lineHeight: '16.39px',
    fontFamily: 'Manrope'
  },
  fontstyle6: {
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '16.39px',
    fontFamily: 'Manrope'
  },
  fontstyle7: {
    fontSize: '18px',
    fontWeight: '500',
    lineHeight: '20.49px',
    fontFamily: 'Manrope'
  },
  fontstyle8: {
    fontSize: '10px',
    fontWeight: '400',
    lineHeight: '13.66px',
    textAlign: 'right',
    fontFamily: 'Manrope'
  },
  DatePickerCustom: {
    display: 'flex !important',
    '& .MuiInputBase-root': {
      borderRadius: '8px',
      height: '24px',
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '16.39px',
      fontFamily: 'Manrope',
      marginBottom: '10px',
      paddingRight: '8px',
    },
    '& .MuiOutlinedInput-input': {
      padding: '16.5px 10px',
    },
    '& .MuiSvgIcon-root': {
      width: '20px',
      height: '20px',
    },
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: '#c0c0c0',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#0d47a1',
    }
  },
  input: {
    display: 'none',
  },
  MenuItemCustom: {
    fontFamily: "Manrope !important",
    fontWeight: 500,
    fontSize: '12px !important',
    lineHeight: '16.39px',
  },

}));
