import directus from '../../services/directus';
import { TimezoneController } from '../../Controller/Settings/ReuseController.js';

export const ConfigurationController = async () => {
    var result = await directus.getItems('configuration');
    return result;
  };

export const TimezoneListController = async () => {
  let timezoneResult = [];
  var timezone_data = await TimezoneController();
  const timezone_list = Object.entries(timezone_data.data.options.choices);
  timezone_list.map((data, index) => {
    timezoneResult[index] = { id: data[0], name: data[1], value: data[0] };
  })  
  return timezoneResult;
};
