import React, { useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Chip,
  Typography,
  Grid,
  Checkbox,
  Menu,
  MenuItem,
} from '@material-ui/core';
import {
  useStylesEmployees,
  AddButton,
  SubmitButton,
  CancelButton,
  CustomTooltip,
  RosterButton,
  SyncButton,
  pageOptions,
  CustomGridApproval,
  CustomAccordion,
  CustomAccordionSummary,
  GridCustom,
  TextFieldCustom,
  TextFieldCustom2,
  AutoCompleteCustom,
  CustomSwitch,
  FormControlCustom,
  SendResetPWButton,
} from './style.js';
import { useStyles, CustomTableCellTime, CustomTableCell } from './styleemployee.js';
import EditIcon from '@material-ui/icons/Edit';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import TimezoneDate from '../Components/ConvertTimezone';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DoneIcon from "@material-ui/icons/DoneAllTwoTone";
import RevertIcon from "@material-ui/icons/NotInterestedOutlined";
import { EmployeesViewModel } from "../../ViewModel/Settings/EmployeesViewModel";
import IconButton from "@mui/material/IconButton";
import FormGroup from '@mui/material/FormGroup';
import RestoreIcon from '@mui/icons-material/Restore';
import SettingsSkeleton from './SettingsSkeleton';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function Employees(props) {
  const [
    //states
    {
      employees, anchorEl, anchorE2, integration,
      filter, localconfiguration, configuration, check,
      open, title, mode, userLoading, employeeLoading,
      firstNameRef, lastNameRef, codeRef,
      emailRef, mobileNumberRef, timezoneRef, timezoneFilterChosenRef,
      regions, regionsData, customers, employeesFilter,
      employeesFilterChosenRef, managementFeeStatusRef, awardsData, awardsEmployee,
      customerAssignedToRef, role, roleFilterChosen, customersFilterChosenRef,
      employeesLeavesChosenRef, password, emailNotificationStatusRef,
      rdoHoursEnable, bankedHoursEnable, autoAdjustToRoster, loading, disable, openRoster, openRestore,
      employee, rowsRef, loadingRoster, disableRoster,
      pageNumberRef, totalPage, totalPageRef, page, loadingSync,
      employmentTypeRef, employmentTypeFilterChosenRef, loadingProcessRef,
      enableFlexibleRosterRef
    },
    //setStates
    {
      setCustomersFilterChosen, setEmployeesFilterChosen, setEmployeesLeavesChosen,
      setRoleFilterChosen, setTimezoneFilterChosen, setEmploymentTypeFilterChosen,
    },
    //function
    {
      SaveEmployee, handleCloseRoster, onToggleEditMode,
      handleStatusClick, handleStatusClose, handleFilterClick, handleClickOpen,
      handleFilterClose, tickHandleChange, handleClickOpenRoster, handleClose,
      onChange, handleClickShowPassword, handleMouseDownPassword, SaveRoster,
      handleChange, handleChangeInput, diffTime, sendEmail, handleClickRestoreOpen,
      handleRestoreClose, RestoreEmployee,
      filterStatus, pageChange, onRevert, loadSyncEmployees
    }
  ] = EmployeesViewModel();

  const classes = useStylesEmployees();
  const classes2 = useStyles();
  var company = JSON.parse(window.localStorage.getItem('company'));

  return (
    <>
      {employees ?
        <>
          <Grid container
            spacing={0}
            style={{ marginBottom: '5px', marginTop: '10px' }}
            alignItems="center"
          >
            <Grid item xs={1} md={1} lg={1}>
              <Typography variant="h5" style={{ paddingLeft: '20px' }}>Code</Typography>
            </Grid>
            <Grid item xs={2} md={2} lg={2}>
              <Typography variant="h5" style={{ paddingLeft: '15px' }}>Name</Typography>
            </Grid>
            <Grid item xs={2} md={2} lg={3} style={{ marginRight: '-17px' }}>
              <Typography variant="h5">Email</Typography>
            </Grid>
            <Grid item xs={1} md={1} lg={1} style={{ marginRight: '-6px' }}>
              <Typography variant="h5">Phone</Typography>
            </Grid>
            <Grid item xs={1} md={1} lg={1} style={{ marginRight: '-7px' }}>
              <Typography variant="h5">Role</Typography>
            </Grid>
            <Grid item xs={1} md={1} lg={1}>
              <Button
                id="employment_type_filter"
                size="small"
                onClick={handleStatusClick}
                style={{ paddingLeft: '0px' }}
              >
                <Typography variant="h5" style={{ textTransform: 'capitalize' }} noWrap >Employment Type</Typography><i className="fa fa-filter fa-lg"></i>
              </Button>
              <Menu
                id="employement_type_popup"
                isopen={anchorEl ? "true" : "false"}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleStatusClose}
                className={classes.filter}
              >
                <MenuItem id="full_time_menu" onClick={() => filterStatus(1)} selected={1 === filter} >Full Time</MenuItem>
                <MenuItem id="casual_menu" onClick={() => filterStatus(2)} selected={2 === filter} >Casual</MenuItem>
                <MenuItem id="all_menu" onClick={() => filterStatus(3)} selected={3 === filter} >All</MenuItem>
              </Menu>
            </Grid>

            <Grid item xs={4} md={4} lg={3} style={{ textAlign: 'right' }}>
              <Button
                id="employee_filter"
                size="small"
                onClick={handleFilterClick}
              >
                <Typography variant="h5">Filter</Typography><i className="fa fa-filter fa-lg"></i>
              </Button>

              {localconfiguration.employee_settings ?
                <AddButton id="add_btn" size="small" color="secondary" aria-label="add" onClick={() => handleClickOpen('', 'add')}>
                  <AddIcon />
                </AddButton>
                : ''}

              <Menu
                id="employee_popup"
                isopen={anchorE2 ? "true" : "false"}
                anchorEl={anchorE2}
                keepMounted
                open={Boolean(anchorE2)}
                onClose={handleFilterClose}
              >
                {localconfiguration.employee_settings ?
                  <>
                    <MenuItem id="deleted_employee" onClick={handleFilterClose} >
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="missing_ls_cb"
                            color="primary"
                            onChange={tickHandleChange}
                            name="deleted_employee"
                          />
                        }
                        label="Deleted Employee"
                      />
                    </MenuItem>
                  </>
                  : ''}

                <MenuItem id="missing_ls_menu" onClick={handleFilterClose} >
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="missing_ls_cb"
                        color="primary"
                        onChange={tickHandleChange}
                        name="missing_leave_supervisor"
                      />
                    }
                    label="Missing Leave Supervisor"
                  />
                </MenuItem>

              </Menu>
            </Grid>
          </Grid>


          {loadingProcessRef.current ?
            <SettingsSkeleton status='page_load' />
            :
            employees.map((employee, index) => {
              if (employee.user) {
                return (
                  <CustomAccordion key={`employee_${index}`}>
                    <CustomAccordionSummary
                      id={`employee_list_${index}`}
                      expandIcon={<ExpandMoreIcon />}
                      style={{ userSelect: 'auto', alignItems: 'flex-start' }}
                    >
                      <Grid container onFocus={(event) => event.stopPropagation()}>
                        <GridCustom item xs={1} md={1} lg={1} style={{ overflowX: "hidden", textOverflow: "ellipsis", overflowWrap: "break-word", paddingRight: "5px" }}>
                          <Typography noWrap variant="h6" id={`employee_code_${index}`}>{employee.code ? employee.code : ''}</Typography>
                        </GridCustom>
                        <GridCustom item xs={2} md={2} lg={2} style={{ overflowX: "hidden", textOverflow: "ellipsis", overflowWrap: "break-word", paddingRight: "5px" }}>
                          <Typography noWrap variant="h6" id={`employee_name_${index}`}>{employee.user.first_name ? employee.user.first_name : ''} {employee.user.last_name ? employee.user.last_name : ''}</Typography>
                        </GridCustom>
                        <GridCustom item xs={2} md={2} lg={3}>
                          <Typography noWrap variant="h6" id={`employee_email_${index}`}>{employee.user.email}</Typography>
                        </GridCustom>
                        <GridCustom item xs={1} md={1} lg={1}>
                          <Typography noWrap variant="h6" id={`employee_phone_${index}`}>{employee.mobile_number}</Typography>
                        </GridCustom>
                        <GridCustom item xs={1} md={1} lg={1}>
                          <Typography noWrap variant="h6" id={`employee_phone_${index}`}>{employee.user.role.name}</Typography>
                        </GridCustom>
                        <GridCustom item xs={1} md={1} lg={1}>
                          <Typography noWrap variant="h6" id={`employee_type_${index}`}>{employee.type}</Typography>
                        </GridCustom>
                        <Grid item xs={4} md={4} lg={3} style={{ textAlign: 'right' }}>

                          {localconfiguration.employee_settings ?
                            [
                              employee.status === 'deleted' ?
                                <IconButton key={`restore_btn_${index}`} aria-label="edit" onClick={(event) => { event.stopPropagation(); handleClickRestoreOpen(employee); }} >
                                  <RestoreIcon fontSize="small" />
                                </IconButton>
                                :
                                [
                                  configuration && configuration.enable_employee_roster ?
                                    <RosterButton
                                      key={`roster_btn_${index}`}
                                      variant="contained"
                                      className={classes.chipButton}
                                      onClick={(event) => { event.stopPropagation(); handleClickOpenRoster(employee, 'edit'); }}
                                    >
                                      <Typography>ROSTER</Typography>
                                    </RosterButton>
                                    : null,

                                  <IconButton key={`edit_btn_${index}`} aria-label="edit" onClick={(event) => { event.stopPropagation(); handleClickOpen(employee, 'edit'); }} >
                                    <EditIcon fontSize="small" />
                                  </IconButton>,

                                  employee.id !== props.directus_employee ?
                                    <IconButton key={`delete_btn_${index}`} aria-label="delete" disableRipple disableTouchRipple disableFocusRipple onClick={(event) => { event.stopPropagation(); handleClickOpen(employee, 'delete'); }}>
                                      <DeleteIcon fontSize="small" />
                                    </IconButton>
                                    :
                                    <IconButton key={`delete_btn_${index}`} aria-label="delete" disableRipple disableTouchRipple disableFocusRipple onClick={(event) => event.stopPropagation()}>
                                      <DeleteIcon style={{ color: 'transparent' }} />
                                    </IconButton>
                                ]
                            ]
                            :
                            [
                              <IconButton key={`edit_btn_${index}`} aria-label="edit" onClick={(event) => { event.stopPropagation(); handleClickOpen(employee, 'edit'); }} >
                                <EditIcon fontSize="small" />
                              </IconButton>]
                          }

                        </Grid>
                      </Grid>
                    </CustomAccordionSummary>
                    <AccordionDetails>
                      <Grid container key={`employee_customer_${index}`} style={{ paddingBottom: '1px' }}>
                        <Grid item xs={12} style={{ textAlign: 'left' }}>
                          {employee.customers.length > 0 ?
                            <div>
                              <Grid container key={`employee_customer_supervisor_${index}`}>
                                <Grid item xs={2} style={{ maxWidth: '8.333333% !important' }}> <Typography noWrap variant="h6"> Supervisor for customer: &nbsp;</Typography></Grid>
                                <Grid item xs={10}>
                                  {employee.customers.map((data, index) => {
                                    if (data.customer) {
                                      return (
                                        <CustomTooltip key={`employee_customer_name_${index}`} title={<Typography variant="h6">{data.customer.name}</Typography>}>
                                          <Chip key={`employee_customer_data_${index}`} variant="outlined" size="small" label={<Typography variant="h6" id={`sp_cus_${index}`}>
                                            {data.customer.code ? data.customer.code : data.customer.name ? data.customer.name : ""}
                                          </Typography>} color="primary" />
                                        </CustomTooltip>
                                      );
                                    }
                                  })}
                                </Grid>
                              </Grid>
                            </div>
                            : ''}

                          {employee.related.length > 0 ?
                            <Grid container key={`employee_related_${index}`}>
                              <Grid item xs={2} style={{ maxWidth: '8.333333% !important' }}> <Typography noWrap variant="h6"> Supervisor for employee: &nbsp;</Typography></Grid>
                              <Grid item xs={10}>
                                {employee.related.map((data, relatedIndex) => {
                                  if (data.child !== null) {
                                    return (
                                      company === 'mfm' ?
                                        <React.Fragment key={`wrapperEmp-${index}-${relatedIndex}`}>
                                          <CustomTooltip key={relatedIndex} title={<Typography variant="h6">{data.child.user.first_name + ' ' + data.child.user.last_name}</Typography>}>
                                            <Chip key={relatedIndex} variant="outlined" size="small" label={<Typography variant="h6" id={`sp_emp_${relatedIndex}`}>{data.child.code}</Typography>} color="primary" />
                                          </CustomTooltip>
                                        </React.Fragment>
                                        :
                                        <React.Fragment key={`wrapperEmp-${index}-${relatedIndex}`}>
                                          <CustomTooltip key={relatedIndex} title={<Typography variant="h6">{data.child.code}</Typography>}>
                                            <Chip key={relatedIndex} variant="outlined" size="small" label={<Typography variant="h6" id={`sp_emp_${relatedIndex}`}>{data.child.user.first_name + ' ' + data.child.user.last_name}</Typography>} color="primary" />
                                          </CustomTooltip>
                                        </React.Fragment>

                                    );
                                  }
                                })}
                              </Grid>
                            </Grid>
                            : ''}

                          {employee.leave_approver.length > 0 ?
                            check.enable_leave_supervisor ?
                              <Grid container key={`employee_leave_approver_${index}`}>
                                <Grid item xs={2} style={{ maxWidth: '8.333333% !important', paddingRight: '2px' }}>
                                  <CustomTooltip title="Leave supervisor for employee">
                                    <Typography noWrap variant="h6">Leave supervisor for employee:  &nbsp;</Typography>
                                  </CustomTooltip>
                                </Grid>
                                <Grid item xs={10}>
                                  {employee.leave_approver.map((data, index) => {
                                    return (
                                      data.child ?
                                        <CustomTooltip key={`employee_leave_approver_name_${index}`} title={<Typography variant="h6">{data.child.user.first_name} {data.child.user.last_name}</Typography>}>
                                          <Chip key={`employee_leave_approver_data_${index}`} variant="outlined" size="small" label={<Typography variant="h6" id={`lv_sp_emp_${index}`}>{data.child.code}</Typography>} color="primary" />
                                        </CustomTooltip>
                                        : ''
                                    );
                                  })
                                  }
                                </Grid>
                              </Grid>
                              : ''
                            : ''}
                        </Grid>
                        <Grid item xs={12}>
                          {employee.user.last_access_on_mobile === null && employee.user.last_mobile_user_agent === null ?
                            <i style={{ fontSize: '13px' }} id={`last_mobile_${index}`}>last accessed on mobile : - </i>
                            :
                            <i style={{ fontSize: '13px' }}>last accessed on mobile is <span id={`last_mobile_${index}`}>{employee.user.last_access_on_mobile}</span>
                              &nbsp;
                              by
                              &nbsp;
                              <span id={`last_mobile_agent_${index}`}>{employee.user.last_mobile_user_agent}</span>
                            </i>
                          }

                        </Grid>
                        <Grid item xs={12}>
                          {employee.user.last_access_on_desktop === null && employee.user.last_desktop_user_agent === null ?
                            <i style={{ fontSize: '13px' }} id={`last_desktop_${index}`}>last accessed on desktop : - </i>
                            :
                            <i style={{ fontSize: '13px' }}>
                              last accessed on desktop is <span id={`last_desktop_${index}`}>{employee.user.last_access_on_desktop}</span>
                              &nbsp;
                              by
                              &nbsp;
                              <span id={`last_desktop_agent_${index}`}>{employee.user.last_desktop_user_agent}</span>
                            </i>
                          }
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </CustomAccordion>
                );
              }
            })}
          <Dialog
            id="employee_dialog"
            isopen={`${open}`}
            open={open}
            fullWidth={true}
            onClose={handleClose}
          >
            <DialogTitle disableTypography={true} id="form-dialog-title"><Typography id="employee_dialog_title" component="div" variant="h6"><h2>{title}</h2></Typography></DialogTitle>
            <DialogContent style={{ marginBottom: '5px' }}>
              {userLoading && employmentTypeRef.current ?
                <SettingsSkeleton status='dialog_load' />
                :
                mode !== 'delete' ?
                  // edit employee dialog
                  localconfiguration.employee_settings ?
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextFieldCustom
                          size='small'
                          id="firstname_text"
                          label="First Name"
                          variant="outlined"
                          name="firstname"
                          value={firstNameRef.current}
                          fullWidth={true}
                          autoComplete="off"
                          onChange={handleChangeInput}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextFieldCustom
                          size='small'
                          id="lastname_text"
                          label="Last Name"
                          variant="outlined"
                          name="lastname"
                          value={lastNameRef.current}
                          fullWidth={true}
                          autoComplete="off"
                          onChange={handleChangeInput}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextFieldCustom
                          size='small'
                          id="code_text"
                          label="Code"
                          variant="outlined"
                          name="code"
                          value={codeRef.current}
                          fullWidth={true}
                          autoComplete="off"
                          onChange={handleChangeInput}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        {employmentTypeRef.current && employmentTypeFilterChosenRef.current ?
                          <>
                            <AutoCompleteCustom
                              size='small'
                              id="combo-box-employmenttype"
                              options={employmentTypeRef.current ? employmentTypeRef.current : []}
                              getOptionLabel={(option) => option.value ?? ''}
                              getOptionSelected={(option, value) => option.value === value.value}
                              fullWidth={true}
                              renderInput={(params) => <TextFieldCustom {...params} label="Employment Type" variant="outlined" />}
                              onChange={(event, newValue) => {
                                newValue === null ?
                                  setEmploymentTypeFilterChosen({ id: 0, name: '', value: '' })
                                  :
                                  setEmploymentTypeFilterChosen({ id: newValue.id, name: newValue.name, value: newValue.value })
                              }}
                              value={employmentTypeFilterChosenRef.current}
                            />
                          </>
                          : <SettingsSkeleton status='component_load' />
                        }
                      </Grid>
                      <Grid item xs={12}>
                        <TextFieldCustom
                          size='small'
                          required
                          id="email_text"
                          label="E-mail"
                          variant="outlined"
                          name="email"
                          value={emailRef.current}
                          fullWidth={true}
                          type="email"
                          autoComplete="off"
                          onChange={handleChangeInput}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextFieldCustom
                          size='small'
                          id="outlined-basic"
                          label="Mobile Number"
                          variant="outlined"
                          name="mobile_number"
                          value={mobileNumberRef.current ? mobileNumberRef.current : ''}
                          fullWidth={true}
                          autoComplete="off"
                          onChange={handleChangeInput}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {timezoneRef.current && timezoneFilterChosenRef.current ?
                          <AutoCompleteCustom
                            size='small'
                            id="combo-box-timezone"
                            options={timezoneRef.current ? timezoneRef.current : []}
                            getOptionLabel={(option) => option.value ?? ''}
                            getOptionSelected={(option, value) => option.value === value.value}
                            fullWidth={true}
                            renderInput={(params) => <TextFieldCustom {...params} label="Select Timezone" variant="outlined" />}
                            onChange={(event, newValue) => {
                              newValue === null ?
                                setTimezoneFilterChosen({ id: 0, name: '', value: '' })
                                :
                                setTimezoneFilterChosen({ id: newValue.id, name: newValue.name, value: newValue.value })
                            }}
                            value={timezoneFilterChosenRef.current}
                          />
                          : <SettingsSkeleton status='component_load' />
                        }
                      </Grid>
                      <Grid item xs={12}>
                        <AutoCompleteCustom
                          size='small'
                          id="size-small-standard"
                          options={regionsData ? regionsData : []}
                          getOptionLabel={(option) => option.name ?? ''}
                          value={regions ? regions : null}
                          onChange={(event, newValue) => {
                            handleChangeInput(newValue, "region");
                          }}
                          renderInput={(params) =>
                            <TextFieldCustom
                              {...params}
                              variant="outlined"
                              name="region"
                              label="Public Holiday Region"
                            />}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {customers ?
                          <>
                            <AutoCompleteCustom
                              size='small'
                              multiple
                              id="checkboxes-tags-customers"
                              options={customers ? customers : []}
                              disableCloseOnSelect
                              fullWidth={true}
                              getOptionLabel={(option) => option.code ? option.code : option.name}
                              getOptionSelected={(option, value) => option.id === value.id}
                              onChange={(event, newValue) => {
                                setCustomersFilterChosen(newValue)
                              }}
                              value={customersFilterChosenRef.current}
                              renderOption={(option, { selected }) => (
                                <React.Fragment>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option.code} - {option.name}
                                </React.Fragment>
                              )}
                              renderInput={(params) => (
                                <TextFieldCustom2 {...params} variant="outlined" label="Select customers to supervise" placeholder="Select..." />
                              )}
                            />
                          </>
                          : ''
                        }

                      </Grid>
                      <Grid item xs={12}>
                        {employeesFilter ?
                          <>
                            <AutoCompleteCustom
                              size='small'
                              multiple
                              id="checkboxes-tags-employees"
                              options={employeesFilter ? employeesFilter : []}
                              disableCloseOnSelect
                              fullWidth={true}
                              getOptionLabel={(option) =>
                                option.user ? option.user.first_name + ' ' + option.user.last_name : ''}
                              getOptionSelected={(option, value) => option.id === value.id}
                              onChange={(event, newValue) => {
                                setEmployeesFilterChosen(newValue)
                              }}
                              value={employeesFilterChosenRef.current}
                              renderOption={(option, { selected }) => (
                                <React.Fragment>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option.code ? option.code : ''}
                                  {option.code && option.user ? ' - ' : ''}
                                  {option.user ? option.user.first_name ? option.user.first_name + ' ' : '' : ''}
                                  {option.user ? option.user.last_name ? option.user.last_name : '' : ''}
                                </React.Fragment>
                              )}
                              renderInput={(params) => (
                                <TextFieldCustom2 {...params} variant="outlined" label="Select employees to supervise" placeholder="Select..." />
                              )}
                            />
                          </>
                          : <SettingsSkeleton status='component_load' />
                        }
                      </Grid>
                      <>

                        {employeesFilter ?
                          check.enable_leave_supervisor ?
                            <Grid item xs={12}>
                              <AutoCompleteCustom
                                size='small'
                                multiple
                                id="checkboxes-tags-employee-leave"
                                options={employeesFilter ? employeesFilter : []}
                                disableCloseOnSelect
                                fullWidth={true}
                                getOptionLabel={(option) =>
                                  // option.code?option.code:
                                  option.user ? option.user.first_name + ' ' + option.user.last_name : ''}
                                getOptionSelected={(option, value) => option.id === value.id}
                                onChange={(event, newValue) => {
                                  setEmployeesLeavesChosen(newValue)
                                }}
                                value={employeesLeavesChosenRef.current}
                                renderOption={(option, { selected }) => (
                                  <React.Fragment>
                                    <Checkbox
                                      icon={icon}
                                      checkedIcon={checkedIcon}
                                      style={{ marginRight: 8 }}
                                      checked={selected}
                                    />
                                    {option.code ? option.code : ''}
                                    {option.code && option.user ? ' - ' : ''}
                                    {option.user ? option.user.first_name ? option.user.first_name + ' ' : '' : ''}
                                    {option.user ? option.user.last_name ? option.user.last_name : '' : ''}
                                  </React.Fragment>
                                )}
                                renderInput={(params) => (
                                  <TextFieldCustom2 {...params} variant="outlined" label="Select employees to approve leave" placeholder="Select..." />
                                )}
                              />
                            </Grid>
                            : ''
                          : <SettingsSkeleton status='component_load' />
                        }
                      </>
                      <Grid item xs={12}>
                        <div style={{ display: 'flex' }}>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <CustomSwitch name="management_fee" checked={managementFeeStatusRef.current} onChange={handleChangeInput} />
                              }
                              label="Management Fee Enabled"
                            />
                          </FormGroup>
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <AutoCompleteCustom
                          id="size-small-standard"
                          size="small"
                          options={awardsData ? awardsData : []}
                          getOptionLabel={(option) => option.name ?? ''}
                          value={awardsEmployee ? awardsEmployee : null}
                          onChange={(event, newValue) => {
                            handleChangeInput(newValue, "awards");
                          }}
                          renderInput={(params) =>
                            <TextFieldCustom
                              {...params}
                              variant="outlined"
                              name="awards"
                              label="Awards"
                            />}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {customers ?
                          <AutoCompleteCustom
                            id="size-small-standard"
                            size="small"
                            options={customers ? customers : []}
                            getOptionLabel={(option) => option.name ?? ''}
                            value={customerAssignedToRef.current ? customerAssignedToRef.current : null}
                            onChange={(event, newValue) => {
                              handleChangeInput(newValue, "customer_assigned_to");
                            }}
                            renderInput={(params) =>
                              <TextFieldCustom
                                {...params}
                                variant="outlined"
                                name="customer_assigned_to"
                                label="Customer Assigned To"
                              />}
                          />
                          : <SettingsSkeleton status='component_load' />
                        }
                      </Grid>
                      <Grid item xs={12}>
                        <AutoCompleteCustom
                          id="size-small-standard"
                          size="small"
                          options={role ? role : []}
                          getOptionLabel={(option) => option.name ?? ''}
                          value={roleFilterChosen ? roleFilterChosen : null}
                          onChange={(event, newValue) => {
                            setRoleFilterChosen(newValue)
                          }}
                          renderInput={(params) =>
                            <TextFieldCustom
                              {...params}
                              variant="outlined"
                              name="user_role"
                              label="User Role"
                            />}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlCustom size='small' fullWidth={true} variant="outlined">
                          <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-password"
                            type={password.showPassword ? 'text' : 'password'}
                            label="Password"
                            name="password"
                            onChange={handleChangeInput}
                            autoComplete="off"
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {password.showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        </FormControlCustom>
                      </Grid>
                      <Grid item xs={12}>
                        {mode !== 'add' ?
                          <>
                            <SendResetPWButton onClick={() => sendEmail()} color="primary">
                              Send Reset Password Email
                            </SendResetPWButton>
                            <br />
                          </>
                          : ''
                        }
                      </Grid>

                      {company === "gtnt" ?
                        <>
                          <Grid item xs={6}>
                            <div style={{ display: 'flex' }}>
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <CustomSwitch name="Enable Flexible Hours" checked={enableFlexibleRosterRef.current} onChange={handleChangeInput} />
                                  }
                                  label="Enable Flexible Hours"
                                />
                              </FormGroup>
                            </div>
                          </Grid>
                          <Grid item xs={6}>
                            <div style={{ display: 'flex' }}>
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <CustomSwitch name="Auto Adjust to Roster" checked={autoAdjustToRoster} onChange={handleChangeInput} />
                                  }
                                  label="Auto Adjust to Roster"
                                />
                              </FormGroup>
                            </div>
                          </Grid>
                        </>
                        :
                        <>
                          <Grid item xs={6}>
                            <div style={{ display: 'flex' }}>
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <CustomSwitch name="Banked Hours Enable" checked={bankedHoursEnable} onChange={handleChangeInput} />
                                  }
                                  label="Banked Hours Enable"
                                />
                              </FormGroup>
                            </div>
                          </Grid>

                          <Grid item xs={6}>
                            <div style={{ display: 'flex' }}>
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <CustomSwitch name="RDO Hours Enable" checked={rdoHoursEnable} onChange={handleChangeInput} />
                                  }
                                  label="RDO Hours Enable"
                                />
                              </FormGroup>
                            </div>
                          </Grid>
                        </>
                      }
                      <Grid item xs={12}>
                        <div style={{ display: 'flex' }}>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <CustomSwitch name="Email Notifications Enabled" checked={emailNotificationStatusRef.current} onChange={handleChangeInput} />
                              }
                              label="Email Notifications Enabled"
                            />
                          </FormGroup>
                        </div>
                      </Grid>
                    </Grid>
                    :
                    <Grid container spacing={2}>
                      <GridCustom item xs={12}>
                        {customers ?
                          <AutoCompleteCustom
                            size='small'
                            multiple
                            id="checkboxes-tags-customers"
                            options={customers}
                            disableCloseOnSelect
                            fullWidth={true}
                            getOptionLabel={(option) => option.code ? option.code : option.name}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(event, newValue) => {
                              setCustomersFilterChosen(newValue)
                            }}
                            value={customersFilterChosenRef.current}
                            renderOption={(option, { selected }) => (
                              <React.Fragment>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option.code} - {option.name}
                              </React.Fragment>
                            )}
                            renderInput={(params) => (
                              <TextFieldCustom2 {...params} variant="outlined" label="Select customers to supervise" placeholder="Select..." />
                            )}
                            style={{ marginBottom: '10px', marginTop: '8px' }}
                          />
                          :
                          <SettingsSkeleton status='component_load' />
                        }
                      </GridCustom>
                      <GridCustom item xs={12}>
                        {employeesFilter ?
                          <AutoCompleteCustom
                            size='small'
                            multiple
                            id="checkboxes-tags-employees"
                            options={employeesFilter}
                            disableCloseOnSelect
                            fullWidth={true}
                            getOptionLabel={(option) =>
                              option.code ? option.code :
                                option.user ? option.user.first_name + ' ' + option.user.last_name : ''}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(event, newValue) => {
                              setEmployeesFilterChosen(newValue)
                            }}
                            value={employeesFilterChosenRef.current}
                            renderOption={(option, { selected }) => (
                              <React.Fragment>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option.code ? option.code : ''}
                                {option.code && option.user ? ' - ' : ''}
                                {option.user ? option.user.first_name ? option.user.first_name + ' ' : '' : ''}
                                {option.user ? option.user.last_name ? option.user.last_name : '' : ''}
                              </React.Fragment>
                            )}
                            renderInput={(params) => (
                              <TextFieldCustom2 {...params} variant="outlined" label="Select employees to supervise" placeholder="Select..." />
                            )}
                            style={{ marginBottom: '10px' }}
                          />
                          :
                          <SettingsSkeleton status='component_load' />
                        }
                      </GridCustom>
                      <GridCustom item xs={12}>
                        {employeesFilter ?
                          check.enable_leave_supervisor ?
                            <AutoCompleteCustom
                              size='small'
                              multiple
                              id="checkboxes-tags-employee-leave"
                              options={employeesFilter}
                              disableCloseOnSelect
                              fullWidth={true}
                              getOptionLabel={(option) =>
                                option.code ? option.code :
                                  option.user ? option.user.first_name + ' ' + option.user.last_name : ''}
                              getOptionSelected={(option, value) => option.id === value.id}
                              onChange={(event, newValue) => {
                                setEmployeesLeavesChosen(newValue)
                              }}
                              value={employeesLeavesChosenRef.current}
                              renderOption={(option, { selected }) => (
                                <React.Fragment>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option.code ? option.code : ''}
                                  {option.code && option.user ? ' - ' : ''}
                                  {option.user ? option.user.first_name ? option.user.first_name + ' ' : '' : ''}
                                  {option.user ? option.user.last_name ? option.user.last_name : '' : ''}
                                </React.Fragment>
                              )}
                              renderInput={(params) => (
                                <TextFieldCustom2 {...params} variant="outlined" label="Select employees to approve leave" placeholder="Select..." />
                              )}
                              style={{ marginBottom: '10px' }}
                            />
                            : ''
                          : <SettingsSkeleton status='component_load' />
                        }
                      </GridCustom>
                      <GridCustom item xs={12}>
                        {userLoading ?
                          <SettingsSkeleton status='component_load' />
                          :
                          <AutoCompleteCustom
                            id="size-small-standard"
                            size="small"
                            options={role ? role : []}
                            getOptionLabel={(option) => option.name ?? ''}
                            value={roleFilterChosen ? roleFilterChosen : null}
                            onChange={(event, newValue) => {
                              setRoleFilterChosen(newValue)
                            }}
                            renderInput={(params) =>
                              <TextFieldCustom
                                {...params}
                                variant="outlined"
                                name="user_role"
                                label="User Role"
                              />}
                            style={{ marginBottom: '10px' }}
                          />
                        }
                      </GridCustom>
                      <GridCustom item xs={12}>
                        <FormControlCustom size='small' fullWidth={true} variant="outlined">
                          <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-password"
                            type={password.showPassword ? 'text' : 'password'}
                            label="Password"
                            name="password"
                            onChange={handleChangeInput}
                            autoComplete="off"
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {password.showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        </FormControlCustom>
                      </GridCustom>
                      <GridCustom item xs={12} style={{ margin: '10px 0px' }}>
                        {mode !== 'add' ?
                          <>
                            <SendResetPWButton onClick={() => sendEmail()} color="primary">
                              Send Reset Password Email
                            </SendResetPWButton>
                            <br />
                          </>
                          : ''
                        }
                      </GridCustom>

                      <Grid item xs={6}>
                        <div style={{ display: 'flex' }}>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <CustomSwitch name="Banked Hours Enable" checked={bankedHoursEnable} onChange={handleChangeInput} />
                              }
                              label="Banked Hours Enable"
                            />
                          </FormGroup>
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div style={{ display: 'flex' }}>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <CustomSwitch name="RDO Hours Enable" checked={rdoHoursEnable} onChange={handleChangeInput} />
                              }
                              label="RDO Hours Enable"
                            />
                          </FormGroup>
                        </div>
                      </Grid>

                      <Grid item xs={12}>
                        <div style={{ display: 'flex' }}>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <CustomSwitch name="Email Notifications Enabled" checked={emailNotificationStatusRef.current} onChange={handleChangeInput} />
                              }
                              label="Email Notifications Enabled"
                            />
                          </FormGroup>
                        </div>
                      </Grid>
                    </Grid>
                  : 'Are you sure?'
              }
              <br />
            </DialogContent>
            <DialogActions>

              {userLoading ?
                <SettingsSkeleton status='button_load' /> :
                loading ?
                  <SettingsSkeleton status='component_load' />
                  :
                  <>
                    <CancelButton
                      id="cancel_btn"
                      size="small"
                      variant="contained"
                      onClick={handleClose}
                    >
                      Cancel
                    </CancelButton>
                    <SubmitButton
                      id="save_btn"
                      disabled={localconfiguration.employee_settings ? mode === 'delete' ? false : disable : false}
                      size="small"
                      variant="contained"
                      onClick={() => SaveEmployee()}
                    >
                      {mode === 'delete' ? 'Delete' : 'Save'}
                    </SubmitButton>
                    <br />
                  </>
              }
            </DialogActions>
          </Dialog>

          {/* Restore Dialog */}
          <Dialog
            id="employee_dialog"
            isopen={`${openRestore}`}
            open={openRestore}
            fullWidth={true}
            onClose={handleRestoreClose}
          >
            <DialogTitle disableTypography={true} id="form-dialog-title"><Typography id="employee_dialog_title" component="div" variant="h6"><h2>{title}</h2></Typography></DialogTitle>
            <DialogContent style={{ marginBottom: '5px' }}>
              <Typography noWrap variant="h6">Are you sure?</Typography>
            </DialogContent>
            <DialogActions>
              {loading ?
                <SettingsSkeleton status='component_load' />
                :
                <>
                  <CancelButton
                    id="cancel_btn"
                    size="small"
                    variant="contained"
                    onClick={handleRestoreClose}
                  >
                    Cancel
                  </CancelButton>

                  <SubmitButton
                    id="save_btn"
                    size="small"
                    variant="contained"
                    onClick={() => RestoreEmployee()}
                  >
                    Save
                  </SubmitButton>
                  <br />
                </>
              }
            </DialogActions>
          </Dialog>
          <Dialog
            id="employee_roster_dialog"
            isopen={`${openRoster}`}
            open={openRoster}
            fullWidth={true}
            maxWidth='lg'
            onClose={handleCloseRoster}
          >
            <DialogTitle disableTypography={true} id="form-dialog-title"><Typography id="employee_roster_dialog_title" component="div" variant="h6"><h2>{employee ? employee.user ? `${employee.user.first_name}'s Roster` : 'Employee Roster' : 'Employee Roster'} </h2></Typography></DialogTitle>
            <DialogContent>
              <Table className={classes2.table} aria-label="caption table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Day</TableCell>
                    <TableCell align="left">Start</TableCell>
                    <TableCell align="left">Finish</TableCell>
                    <TableCell align="left">Break (mins)</TableCell>
                    <TableCell align="left">Hours</TableCell>
                    <TableCell align="left">Ordinary Hours</TableCell>
                    <TableCell align="left">RDO Accrual</TableCell>
                    <TableCell align="left" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsRef.current.map(row => (
                    <TableRow key={row.id}>
                      <CustomTableCell {...{ row, name: "day", onChange }} />
                      <CustomTableCellTime {...{ row, name: "start_time", onChange }} />
                      <CustomTableCellTime {...{ row, name: "end_time", onChange }} />
                      <CustomTableCell {...{ row, name: "break", onChange }} />
                      <CustomTableCell {...{ row, name: "hours", onChange }} />
                      <CustomTableCell {...{ row, name: "ordinary_hours", onChange }} />
                      <CustomTableCellTime {...{ row, name: "rdo_accrual", onChange }} />
                      <TableCell className={classes2.selectTableCell}>
                        {row.isEditMode ? (
                          <>
                            <IconButton
                              aria-label="done"
                              onClick={() => onToggleEditMode(row.id, "save")}
                            >
                              <DoneIcon />
                            </IconButton>
                            <IconButton
                              aria-label="revert"
                              onClick={() => onRevert(row.id)}
                            >
                              <RevertIcon />
                            </IconButton>
                          </>
                        ) : (
                          <IconButton
                            aria-label="edit"
                            onClick={() => onToggleEditMode(row.id, "edit")}
                          >
                            <EditIcon />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </DialogContent>
            <DialogActions style={{ height: "60px" }}>

              {!loadingRoster ?
                <>
                  <CancelButton
                    id="cancel_btn"
                    size="small"
                    variant="contained"
                    onClick={handleCloseRoster}
                  >
                    Cancel
                  </CancelButton>

                  <SubmitButton
                    id="save_btn"
                    size="small"
                    variant="contained"
                    disabled={disableRoster}
                    onClick={() => SaveRoster()}
                  >
                    Save
                  </SubmitButton>
                </>
                :
                <SettingsSkeleton status='component_load' />
              }
            </DialogActions>
          </Dialog>
        </>
        :
        <SettingsSkeleton status='page_load' />
      }

      {employees ?
        <Grid container>
          <Grid
            item xs={6}
            style={{ textAlign: 'left', marginTop: '10px', marginBottom: '10px', fontFamily: 'Manrope' }}
          >
            <span style={{ paddingRight: '20px' }}>
              Rows per page:
            </span>
            <span style={{ paddingRight: '20px' }}>
              <TextField
                id="standard-select-currency"
                select
                value={pageNumberRef.current}
                onChange={handleChange}
              >
                {pageOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </span>

            <span id="page_number" style={{ paddingRight: '20px' }}>
              {(pageNumberRef.current * (page - 1)) + 1} - {(pageNumberRef.current * page) > totalPageRef.current ? totalPageRef.current : pageNumberRef.current * page} of {totalPageRef.current}
            </span>
            <Button
              id="prev_btn"
              variant="outlined"
              size="small"
              disabled={page === 1}
              className={classes.buttonLeft}
              onClick={() => pageChange(-1)}
            >
              <ArrowBackIosRoundedIcon />
            </Button>

            <Button
              id="next_btn"
              variant="outlined"
              size="small"
              className={classes.buttonRight}
              disabled={page >= Math.ceil(totalPageRef.current / pageNumberRef.current)}
              onClick={() => pageChange(1)}
            >
              <ArrowForwardIosRoundedIcon />
            </Button>

            {company === 'mfm' ?
              loadingSync ?
                <SettingsSkeleton status='component_load' />
                :
                <SyncButton
                  variant="contained"
                  className={classes.chipButton}
                  onClick={() => loadSyncEmployees()}
                >
                  <Typography>Sync Employees</Typography>
                </SyncButton>
              : ''}
          </Grid>

          <Grid item xs={6} style={{ textAlign: 'right', marginTop: '12px', marginBottom: '10px', fontFamily: 'Manrope' }}>
            {integration ?
              <Typography variant="h6" style={diffTime(integration.data[0].employees_last_sync) > 1 ? { color: 'red', textAlign: 'right' } : { color: '#333942', textAlign: 'right' }}>
                {integration.data[0].employees_last_sync === null || integration.data[0].employees_last_sync === '' ? '' : 'Master Data last synced: ' + TimezoneDate(integration.data[0].employees_last_sync)}
              </Typography>
              : ''}
          </Grid>
        </Grid>
        : " "}
    </>
  );
}
